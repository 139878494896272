import { CopyOutlined, SnippetsOutlined } from "@ant-design/icons";
import { Button, Flex, Select, Tooltip } from "antd";
import CapabilitiesRolesTypeInputReadOnly from "./CapabilitiesRolesTypeInputReadOnly";
import "./CapabilitiesRolesTypeMultiSelectInput.scss";
import { findOptionsByInputValue, getValidValue, prepareValueInput } from "../lib";
interface ICapabilitiesRolesTypeMultiSelectInput {
    emptyValue: "-" | "Все";
    isFocused: boolean;
    onFocus?: () => void;
    additionalOptions: { label: string; value: string }[];
    bordered: boolean;
    value: string;
    onChange: (value: string | string[]) => void;
    isDefaultOpen: boolean;
    isLoading: boolean;
    maxCount?: number;
    copyValue: (value: string) => Promise<void>;
    pasteValue: () => Promise<void>;
}

const CapabilitiesRolesTypeMultiSelectInput = ({
    emptyValue,
    isFocused,
    onFocus,
    additionalOptions,
    bordered,
    value,
    onChange: onChangeValue,
    isDefaultOpen,
    isLoading,
    maxCount,
    copyValue,
    pasteValue,
}: ICapabilitiesRolesTypeMultiSelectInput) => {
    return (
        <>
            {!isFocused ? (
                <CapabilitiesRolesTypeInputReadOnly
                    emptyValue={emptyValue}
                    value={value}
                    onFocus={onFocus}
                    additionalOptions={additionalOptions}
                />
            ) : (
                <Flex
                    style={{ padding: 0, margin: 0, width: "100%", overflow: "hidden" }}
                    onCopy={() => copyValue(value)}
                    onPaste={pasteValue}
                >
                    <Tooltip title="Копировать" color="var(--primary)">
                        <Button
                            onClick={() => copyValue(value)}
                            style={{ width: "5%", minWidth: 30 }}
                            icon={<CopyOutlined />}
                            type="link"
                        />
                    </Tooltip>
                    <Select
                        allowClear
                        mode={"multiple"}
                        maxTagCount={maxCount}
                        options={additionalOptions}
                        bordered={bordered || isFocused}
                        value={getValidValue(value, emptyValue)}
                        defaultOpen={isDefaultOpen}
                        filterOption={findOptionsByInputValue}
                        loading={isLoading}
                        maxTagTextLength={maxCount ? 10 : undefined}
                        onChange={(value: string | string[]) => {
                            onChangeValue(prepareValueInput(value, emptyValue));
                        }}
                        style={{ flex: "1 1 75%", height: "100%" }}
                    />
                    <Tooltip color="var(--primary)" title="Вставить">
                        <Button
                            style={{ width: "5%", minWidth: 30 }}
                            onClick={pasteValue}
                            icon={<SnippetsOutlined />}
                            type="link"
                        />
                    </Tooltip>
                </Flex>
            )}
        </>
    );
};

export default CapabilitiesRolesTypeMultiSelectInput;
