import { Button } from "antd";
import { styled } from "styled-components";

export const PrimarySecondButton = styled(Button)`
    padding: 3px 7px;
    min-height: 24px;
    margin-top: 8px;
    width: 100%;
    height: fit-content;
    line-height: initial;
    background: var(--primary-2);
    min-width: 120px;
    &:hover {
        color: var(--primary-5) !important;
    }

    & span {
        display: inline-block;
        width: 100%;
        word-break: break-word;
        text-wrap: wrap;
        white-space: wrap;
    }
`;
