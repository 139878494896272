import { techMapModel } from "@entities/Production/TechMap";
import { TMFilters } from "@features/Production/TechMapFeatures/TMFilters";
import { TMGroupSelect } from "@features/Production/TechMapFeatures/TMGroupSelect";
import { TMTableGrouped } from "@features/Production/TechMapFeatures/TMTable";
import { PrinterModule } from "@shared/StickerPrinter";
import { useDispatch } from "react-redux";

interface IProducationStatsPage {}

const ProducationStatsPage = ({}: IProducationStatsPage) => {
    const dispatch = useDispatch();

    const onChangeConnectedState = (state: boolean) => {
        dispatch(techMapModel.setPrinterIsConnected(state));
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "100%",
            }}
        >
            <div
                style={{
                    display: "flex",
                    width: "100%",
                    marginBottom: 10,
                    alignItems: "flex-start",
                }}
            >
                <TMFilters />
                <TMGroupSelect />
            </div>
            <TMTableGrouped />
        </div>
    );
};

export default ProducationStatsPage;
