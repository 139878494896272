import { techMapModel } from "@entities/Production/TechMap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

export const useTMGroupSelect = () => {
    const groupLevels = useSelector(techMapModel.selectGroupLevels);
    const dispatch = useDispatch();

    const onChange = (values: string[]) => {
        dispatch(techMapModel.setGroupLevels(values));
    };
    return { groupLevels, onChange };
};
