import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import SelectEntityRowReadValueItem from "./SelectEntityRowReadValueItem";

interface ISelectEntityRowReadValue {
    value: any;
    options: TPaginatedSelectOption[];
    entityInfo: string;
    byUuid?: boolean;
}

const SelectEntityRowReadValue = ({
    value,
    options,
    entityInfo,
    byUuid,
}: ISelectEntityRowReadValue) => {
    const arrValue = Array.isArray(value) ? value : [value];

    return (
        <div>
            {arrValue.map((value, index, arr) => {
                return (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                        key={value}
                    >
                        <SelectEntityRowReadValueItem
                            key={value}
                            value={value}
                            entityInfo={entityInfo}
                            byUuid={byUuid}
                            options={options}
                        />
                        {index < arr.length - 1 ? (
                            <IndentContainer type={["marginRight"]}>,</IndentContainer>
                        ) : undefined}
                    </div>
                );
            })}
        </div>
    );
};

export default SelectEntityRowReadValue;
