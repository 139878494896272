import ActionCard from "./ActionCard";
interface IParallelBranchAction {
    onDeleteItem?: () => void;
    templateItem?: TBizProcTemplateAction;
}
const ParallelBranchAction = ({ onDeleteItem, templateItem }: IParallelBranchAction) => {
    return (
        <ActionCard
            templateItem={templateItem}
            borderColor="RoyalBlue"
            onDeleteItem={onDeleteItem}
            title={templateItem?.name ?? "Ветвь Параллельное выполнение"}
        />
    );
};

export default ParallelBranchAction;
