import { FormItem } from "@shared/ui/Form";
import { useTechMapTmplOperationForm } from "../model";
import { Button, Input, Space } from "antd";
import { TMTOperationCard } from "@entities/Production/TechMapTemplate";
import { Bookmark } from "@shared/ui/DrawerManager";
import "./TechMapTmplOperationForm.scss";
import TMTONecessaryItems from "./TMTONecessaryItems/TMTONecessaryItems";
import TMTOCodes from "./TMTOCodes/TMTOCodes";
interface ITechMapTmplOperationForm {
    tmtUuid: string | null;
    tmtId: number;
    parentUuid: string | null;
    operationUuid?: string | null | undefined;
    operationId?: number | null | undefined;
    level?: number;
    onCancel: () => void;
}

const TechMapTmplOperationForm = ({
    operationId,
    tmtUuid,
    parentUuid,
    tmtId,
    operationUuid,
    level,
    onCancel,
}: ITechMapTmplOperationForm) => {
    const {
        operationName,
        operation,
        onlyRead,
        editingMode,
        isCreating,
        deleteLoading,
        updateLoading,
        onSubmit,
        onClickEditing,
        onClickCancelEditing,
        onChangeName,
        onUpdateNecessatyItems,
        onUpdateTemplateCodes,
        onDelete,
    } = useTechMapTmplOperationForm(
        operationId,
        operationUuid,
        tmtUuid,
        parentUuid,
        tmtId,
        onCancel
    );
    const windowWidth = window.innerWidth;
    const shortVariant = windowWidth <= 900;
    const drawerLevel = level ?? 0;

    return (
        <div
            className=""
            style={{
                overflowY: "auto",
                height: "calc(100% - 85px)",
                width: `calc(${
                    !shortVariant ? "600px" : "100vw"
                } + ${drawerLevel * 30}px)`,
            }}
        >
            {!shortVariant && operationName && (
                <Bookmark
                    tooltip={operationName}
                    zIndex={(1 + drawerLevel) * 2000}
                    top={30 + drawerLevel * 20}
                    onClose={onCancel}
                >
                    {operationName}
                </Bookmark>
            )}

            {operation && (
                <div
                    style={{
                        boxSizing: "border-box",
                        padding: 20,
                        width: "100%",
                        display: "flex",
                    }}
                >
                    <div className="item-card">
                        <FormItem label="Название">
                            {editingMode ? (
                                <Input value={operation.name} onChange={onChangeName} />
                            ) : (
                                <span>{operation.name}</span>
                            )}
                        </FormItem>
                        <FormItem label="Генерация кода">
                            <TMTOCodes
                                onChange={onUpdateTemplateCodes}
                                editingMode={editingMode}
                                codes={operation.code_templates ?? []}
                            />
                        </FormItem>

                        <FormItem label="Необходимые компоненты">
                            <TMTONecessaryItems
                                tmtoUuid={operation.uuid}
                                onChange={onUpdateNecessatyItems}
                                items={operation.necessary_items ?? []}
                                editingMode={editingMode}
                            />
                        </FormItem>
                        <FormItem label="Предыдущая операция">
                            {operation.parent_operation ? (
                                <TMTOperationCard
                                    tmtId={tmtId}
                                    operation={operation.parent_operation}
                                />
                            ) : (
                                <>-</>
                            )}
                        </FormItem>
                        <FormItem label="Следующая операция">
                            {operation.child_operations?.[0] ? (
                                <TMTOperationCard
                                    tmtId={tmtId}
                                    operation={operation.child_operations[0]}
                                />
                            ) : (
                                <>-</>
                            )}
                        </FormItem>
                    </div>
                </div>
            )}

            {operation && (
                <div className="tmto-drawer-actions">
                    <div className="tmto-drawer-actions__buttons">
                        <Space>
                            {!editingMode ? (
                                <>
                                    <Button onClick={onCancel}>Закрыть</Button>
                                    {!onlyRead && (
                                        <>
                                            <Button
                                                onClick={onClickEditing}
                                                type="primary"
                                            >
                                                Редактировать
                                            </Button>
                                            {!isCreating && (
                                                <Button
                                                    loading={deleteLoading}
                                                    danger
                                                    type="primary"
                                                    onClick={onDelete}
                                                >
                                                    Удалить
                                                </Button>
                                            )}
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    <Button onClick={onClickCancelEditing}>Отмена</Button>
                                    <Button
                                        type="primary"
                                        onClick={onSubmit}
                                        loading={updateLoading}
                                    >
                                        Сохранить
                                    </Button>
                                </>
                            )}
                        </Space>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TechMapTmplOperationForm;
