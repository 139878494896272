import { Input, Modal } from "antd";
import { useTMActionsQtyModal } from "../model";
import { FormItem } from "@shared/ui/Form";
import { NumberInput } from "@entities/Portal/Datatype";

interface ITMActionsQtyModal {
    isOpen: boolean;
    loading: boolean;
    onApprove: (count: number) => void;
    onCancel: () => void;
}

const TMActionsQtyModal = ({
    isOpen,
    loading,
    onApprove,
    onCancel,
}: ITMActionsQtyModal) => {
    const { qty, onChangeQty, onOk } = useTMActionsQtyModal(onApprove);
    return (
        <Modal
            destroyOnClose
            open={isOpen}
            confirmLoading={loading}
            okText="Завершить"
            onOk={onOk}
            onCancel={onCancel}
        >
            <FormItem label="Количество выполненных результатов">
                <NumberInput value={qty} onChangeValue={onChangeQty} />
            </FormItem>
        </Modal>
    );
};

export default TMActionsQtyModal;
