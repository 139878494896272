import { bizProcLib } from "@entities/Portal/BizProcTemplate";
import { getUnixTimestamp } from "@shared/lib/date";
import { getRandomFiveDigitNumber } from "@shared/lib/num-helper";
import { AnchorPositionType, RelationType } from "react-archer/lib/types";

const getDefaultRelationRule = (targetId: string) => {
    return {
        targetId: targetId,
        targetAnchor: "top" as AnchorPositionType,
        sourceAnchor: "bottom" as AnchorPositionType,
    };
};
export const getSingleDefaultRelationRule = (targetId: string) => {
    return [
        {
            targetId: targetId,
            targetAnchor: "top" as AnchorPositionType,
            sourceAnchor: "bottom" as AnchorPositionType,
        },
    ];
};

export const getParentNextItem = (
    templateItem: TBizProcTemplateAction,
    templateGroupItems: TBizProcTemplateAction[]
) => {
    const index = templateGroupItems.findIndex((item) => item.key === templateItem.key);

    return templateGroupItems?.[index + 1];
};
export const getParentNextRelation = (
    parentNextItem: TBizProcTemplateAction | undefined,
    customEndItemKey?: string
) => {
    if (parentNextItem) {
        return getSingleDefaultRelationRule(parentNextItem.key);
    }

    return getSingleDefaultRelationRule(
        customEndItemKey !== undefined ? customEndItemKey : "end"
    );
};
export const getArcherRelations = (
    templateItem: TBizProcTemplateAction,
    templateGroupItems: TBizProcTemplateAction[],
    parentNextItem: TBizProcTemplateAction | undefined,
    customEndItemKey?: string,
    log?: string
): RelationType[] => {
    if (!templateItem.children[0]) {
        return getParentNextRelation(parentNextItem, customEndItemKey);
    }
    if (bizProcLib.isBizProcWithRowChild(templateItem.action)) {
        return templateItem.children.map((item) => {
            return getDefaultRelationRule(item.key);
        });
    } else {
        return getSingleDefaultRelationRule(templateItem.children[0].key);
    }
};
export const getAgreementRelations = (templateItem: TBizProcTemplateAction) => {
    return templateItem.children.map((item) => {
        return {
            targetId: item.key,
            targetAnchor: "top" as AnchorPositionType,
            sourceAnchor: (item.action === "ApproveBranchAction"
                ? "right"
                : "left") as AnchorPositionType,
        };
    });
};
export const prepareItemChildren = (children: TBizProcTemplateAction[]) => {
    return children.map((item, index, fullArray) => ({
        ...item,
        order: index,
        next_key: fullArray?.[index + 1]?.key ?? null,
    }));
};

export const isValuePartOfBizProcTemplate = (value: any): boolean => {
    if (typeof value === "object") {
        let isValid = true;

        isValid =
            value.key &&
            value.key.includes("key-") &&
            value.action &&
            value.children &&
            value.children
                .map((item: TBizProcTemplateAction) => isValuePartOfBizProcTemplate(item))
                .every((isValid: boolean) => isValid);

        return isValid;
    } else return false;
};

export const rewriteUniqProps = (
    templateItem: TBizProcTemplateAction,
    depth: number,
    parentIndex?: string
) => {
    const newChildren = [...templateItem.children];
    const uniqValueByTimestamp = getUnixTimestamp();
    const newKey = parentIndex
        ? parentIndex
        : `key-${uniqValueByTimestamp}${getRandomFiveDigitNumber()}`;
    return {
        ...templateItem,
        key: newKey,
        id: getUnixTimestamp(),
        children: newChildren.reduce((acc, item, idx, array) => {
            acc[idx] = {
                ...item,
                id: getUnixTimestamp(),
                key: `key-${getUnixTimestamp()}${getRandomFiveDigitNumber()}`,
                parent_key: newKey,
                // parent_key: idx === 0 ? newKey : acc[idx - 1].key,
            };
            if (acc[idx].children.length > 0) {
                acc[idx] = rewriteUniqProps(acc[idx], depth + 1, acc[idx].key);
            }
            if (idx > 0) acc[idx - 1].next_key = acc[idx].key;
            if (idx === array.length - 1) {
                acc[idx].next_key = null;
            }
            return acc;
        }, [] as TBizProcTemplateAction[]),
    };
};

export const checkDraggingItemIsChildOfTargetTemplateItem = (
    draggingItem: TBizProcTemplateAction,
    item: TBizProcTemplateAction
): boolean => {
    let isChild = item.parent_key === draggingItem.key;
    if (isChild) return true;
    if (draggingItem.children.length > 0) {
        const buff = draggingItem.children.map((child) =>
            checkDraggingItemIsChildOfTargetTemplateItem(child, item)
        );
        isChild = buff.some((item) => !!item);
    }
    return isChild;
};
