import { FormItem } from "@shared/ui/Form";
import { ErrorText } from "@shared/ui/Text";
import { Button, Input, Select, Space } from "antd";
import { routeBlankOptions, routeTypeOptions } from "../constants";
import { useNavigationRouteEditUpdate } from "../model";

interface INavigationRouteEditOrUpdateForm {
    navigationRoute?: TNavigationRoute | null;
    onCancel?: () => void;
    onSave?: () => void;
}

const NavigationRouteEditOrUpdateForm = ({
    navigationRoute,
    onCancel,
    onSave,
}: INavigationRouteEditOrUpdateForm) => {
    const {
        isValidUrl,
        editingNavigationRoute,
        loading,
        onChangeInput,
        onChangeType,
        onSubmit,
        onChangeBlank,
    } = useNavigationRouteEditUpdate(navigationRoute, onSave);
    return (
        <div>
            <FormItem label="Имя">
                <Input
                    value={editingNavigationRoute.name}
                    onChange={onChangeInput}
                    name="name"
                />
            </FormItem>
            <FormItem label="Тип">
                <Select
                    style={{ width: "100%" }}
                    value={editingNavigationRoute.type}
                    options={routeTypeOptions}
                    onChange={onChangeType}
                />
            </FormItem>
            <FormItem label="Ссылка">
                <>
                    <Input
                        value={editingNavigationRoute.route}
                        onChange={onChangeInput}
                        name="route"
                    />
                    {!isValidUrl ? <ErrorText text="Формат ссылки некорректен" /> : <></>}
                </>
            </FormItem>
            <FormItem label="Открытие">
                <Select
                    style={{ width: "100%" }}
                    value={editingNavigationRoute.open_type}
                    options={routeBlankOptions}
                    onChange={onChangeBlank}
                />
            </FormItem>
            <FormItem label="Ключ">
                <Input
                    value={editingNavigationRoute.key}
                    onChange={onChangeInput}
                    name="key"
                />
            </FormItem>
            <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
                <Space>
                    {onCancel && <Button onClick={onCancel}>Закрыть</Button>}
                    <Button type="primary" onClick={onSubmit} loading={loading}>
                        Сохранить
                    </Button>
                </Space>
            </div>
        </div>
    );
};

export default NavigationRouteEditOrUpdateForm;
