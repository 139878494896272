import { useEffect, useState } from "react";
import { getBizProcAllTemplates } from "../api";
import { Select } from "antd";

interface IBizProcTemplateSelect {
    value: number | null;
    onChange: (
        value: number | null,
        bizProcTemplate: TBizProcTemplateItem | null
    ) => void;
}

const BizProcTemplateSelect = ({ value, onChange }: IBizProcTemplateSelect) => {
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState<
        TOptionWithItem<TBizProcTemplateItem | null>[]
    >([]);
    const getTemplates = () => {
        setLoading(true);
        getBizProcAllTemplates()
            .then((response) => {
                setOptions(
                    response.data.data.map((bpt) => ({
                        label: bpt.name,
                        value: bpt.id,
                        item: bpt,
                    }))
                );
            })
            .catch(() => {})
            .finally(() => {
                setLoading(false);
            });
    };

    const onChangeSelect = (value: number, option: any) => {
        onChange(value, option.item);
    };

    useEffect(() => {
        getTemplates();
    }, []);

    return (
        <Select
            loading={loading}
            placeholder="Выберите бизнес-процесс"
            style={{
                width: "100%",
            }}
            options={
                loading
                    ? [
                          ...options,
                          {
                              label: "Загрузка...",
                              value: 0,
                              item: null,
                          },
                      ]
                    : options
            }
            onChange={onChangeSelect}
            value={loading ? 0 : value}
            filterOption={(input: any, option) => {
                return option?.item?.name?.includes(input) === true;
            }}
        />
    );
};

export default BizProcTemplateSelect;
