import { maxWidth } from "../constants";
import { calculateScrollTopByChoosenPage, getConditionForScrollingBottom } from "../lib";

export const usePdfViewerContent = (
    scale: number,
    rescale: (type?: "decrease" | "increase", value?: number) => void,
    currentNumPage: number,
    setCurrentNumPage: React.Dispatch<React.SetStateAction<number>>,
    containerRef: React.MutableRefObject<HTMLElement | null>,
    containerWidth: number | undefined
) => {
    const onWheelContainer = (event: React.WheelEvent<HTMLElement>) => {
        if (event.deltaY < 0) {
            if (event.ctrlKey) {
                rescale("increase");
            }
            if (
                containerRef?.current &&
                containerRef?.current?.scrollTop <
                    calculateScrollTopByChoosenPage(
                        currentNumPage,
                        containerWidth,
                        maxWidth,
                        scale,
                        true
                    ) &&
                containerRef?.current?.scrollTop <
                    calculateScrollTopByChoosenPage(
                        currentNumPage + 1,
                        containerWidth,
                        maxWidth,
                        scale
                    )
            )
                setCurrentNumPage((prev: number) => (prev === 1 ? prev : prev - 1));
        } else {
            if (event.ctrlKey) {
                rescale("decrease");
            }
            if (
                containerRef?.current &&
                containerRef?.current?.scrollTop >=
                    getConditionForScrollingBottom(
                        currentNumPage,
                        containerWidth,
                        maxWidth,
                        scale
                    )
            )
                setCurrentNumPage((prev) => prev + 1);
        }
    };

    const onPageListItemClick = (indexOfPageInPageListArray: number) => {
        const newCurrentNumPage = indexOfPageInPageListArray + 1;
        if (containerRef?.current)
            containerRef?.current?.scrollTo({
                top: calculateScrollTopByChoosenPage(
                    newCurrentNumPage,
                    containerWidth,
                    maxWidth,
                    scale
                ),
            });
        setCurrentNumPage(newCurrentNumPage);
    };

    return {
        onWheelContainer,
        onPageListItemClick,
    };
};
