import { options } from "@entities/Portal/CapabilitiesRoles/constants";
import { Flex } from "antd";
import { motion } from "framer-motion";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { pagesListVariants } from "../constants";

const PageList = motion(Flex);

interface IPDFViewerSidebar {
    onDocumentLoadSuccess: ({ numPages }: pdfjs.PDFDocumentProxy) => void;
    link: string;
    isOpen: boolean;
    numPages?: number;
    currentNumPage: number;
    onPageListItemClick: (item: number) => void;
}

const PDFViewerSidebar = ({
    onDocumentLoadSuccess,
    link,
    isOpen,
    numPages,
    currentNumPage,
    onPageListItemClick,
}: IPDFViewerSidebar) => {
    return (
        <PageList
            variants={pagesListVariants}
            vertical
            className="PDF__pages-list-container"
            initial={false}
            animate={isOpen ? "open" : "closed"}
        >
            <Document
                file={`${process.env.REACT_APP_API_URL}/api/file?link=${link}`}
                onLoadSuccess={onDocumentLoadSuccess}
                className="PDF__pages-list"
                options={options}
            >
                {Array.from(new Array(numPages), (_el, index) => (
                    <div
                        key={`page_${index + 1}`}
                        className={`PDF__pages-list-page ${
                            index + 1 === currentNumPage
                                ? "PDF__pages-list-page--active"
                                : ""
                        }`}
                        onClick={() => onPageListItemClick(index)}
                    >
                        <Page
                            pageNumber={index + 1}
                            renderTextLayer={false}
                            width={100}
                        />
                        <Flex
                            justify="center"
                            align="center"
                            style={{
                                width: "100%",
                                padding: 0,
                                margin: 0,
                                color: "white",
                            }}
                        >
                            {index + 1}
                        </Flex>
                    </div>
                ))}
            </Document>
        </PageList>
    );
};

export default PDFViewerSidebar;
