import { entityRowConstants } from "@entities/Portal/EntityRow";
import { userModel } from "@entities/Portal/User";
import { setDocumentTitle } from "@shared/lib/document";
import { openDrawer } from "@shared/ui/DrawerManager";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export const useTablePageErpLoaded = (entity: TEntity) => {
    const userId = useSelector(userModel.selectUserId);
    const openCloseDrawerWithNavigate = (
        isOpenDrawer: boolean,
        entityRowId?: number | null,
        initialValues?: any
    ) => {
        openDrawer({
            id: entityRowId,
            name: entity?.table_name,
            pattern: entityRowConstants.ERP_LOCATION_PATTERN,
            additional: { initialValues },
        });
    };
    useEffect(() => {
        setDocumentTitle(entity.name);
    }, []);
    return {
        userId,
        openCloseDrawerWithNavigate,
    };
};
