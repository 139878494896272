import { techMapModel } from "@entities/Production/TechMap";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useTMActions = () => {
    const startedTm = useSelector(techMapModel.selectStartedTm);
    const startedOperation = useSelector(techMapModel.selectStartedOperation);
    const nextLoading = useSelector(techMapModel.selectNextOperationLoading);
    const pauseResumeLoading = useSelector(techMapModel.selectPauseResumeLoading);
    const stopLoading = useSelector(techMapModel.selectStopLoading);
    const printerIsConnected = useSelector(techMapModel.selectPrinterIsConnected);
    const needPrinter = Boolean(
        startedOperation?.template_operation?.code_templates?.length
    );
    const templateItems = startedOperation?.template_operation.necessary_items ?? [];
    const operationItems = startedOperation?.operation_items ?? [];
    const isLast = startedOperation?.template_operation?.child_operations?.length === 0;
    const isPaused = startedTm?.is_paused;
    const isFilled =
        startedOperation &&
        templateItems.filter((item) => item.type === "filling" && item.is_scannable)
            .length === operationItems.length;
    const [isOpenQtyModal, setIsOpenQtyModal] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const onClickPauseResume = () => {
        if (!startedTm || !startedOperation) return;

        dispatch(
            startedTm.is_paused
                ? techMapModel.resumeOperationThunk({
                      oId: startedOperation.id,
                  })
                : techMapModel.pauseOperationThunk({
                      oId: startedOperation.id,
                  })
        );
    };

    const onClickStop = () => {
        if (!startedTm) return;

        dispatch(
            techMapModel.stopTmThunk({
                tmId: startedTm.id,
            })
        );
    };

    const onClickNext = (skipMultipleChecking?: boolean, count?: number) => {
        if (!isFilled) {
            notificationEmit({
                type: "error",
                title: "Не заполнены необходимые компоненты",
            });
            return;
        }
        if (!startedOperation || !startedTm) {
            notificationEmit({
                type: "warning",
                title: "Не выбран процесс!",
            });
            return;
        }

        if (!skipMultipleChecking && startedTm.template.is_multiple) {
            setIsOpenQtyModal(true);
            return;
        }

        dispatch(techMapModel.goToNextOperation(count));
    };

    const onClickNextWithCheckMultiple = () => {
        onClickNext(false);
    };

    const onClickNextModal = (count: number) => {
        onClickNext(true, count);
    };

    const onCloseQtyModal = () => {
        setIsOpenQtyModal(false);
    };

    return {
        needPrinter,
        printerIsConnected,
        nextLoading,
        isFilled,
        isLast,
        stopLoading,
        pauseResumeLoading,
        isPaused,
        isOpenQtyModal,
        onClickNextWithCheckMultiple,
        onClickNextModal,
        onClickStop,
        onClickPauseResume,
        onCloseQtyModal,
    };
};
