export const tmtoNecessaryItemTypesOptions = [
    {
        label: "Конкретный (Обязателен для учета)",
        value: "filling",
    },
    {
        label: "Доступный (Справочный, не нужно учитывать)",
        value: "available",
    },
];
export const tmtoNItemNecessaryTypesOptions = [
    {
        label: "Компонент (Потратится)",
        value: "components",
    },
    {
        label: "Инструмент (Не потратится)",
        value: "tools",
    },
];

export const tmtoUseActionsOptions = [
    {
        value: "default",
        label: "Значение при завершении операции",
    },
    {
        value: "revert",
        label: "Значение при завершении тех карты",
    },
];
