import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { selectMyAgreements } from "./BizProcSelectors";
import { loadBizProcsAgreementsThunk } from "./BizProcThunk";
import { usePostLoadAgreements } from "./UsePostLoadAgreements";

export const useLoadMyAgreements = (preload?: boolean) => {
    const agreements = useSelector(selectMyAgreements);
    const dispatch = useDispatch<AppDispatch>();
    usePostLoadAgreements(true);
    useEffect(() => {
        if (preload) {
            loadAgreements();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preload]);

    const loadAgreements = () => {
        dispatch(loadBizProcsAgreementsThunk({ isMy: true }));
    };
    return {
        agreements,
        loadAgreements,
    };
};
