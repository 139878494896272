import { techMapModel } from "@entities/Production/TechMap";
import { techMapTemplateLib } from "@entities/Production/TechMapTemplate";
import { useMemo } from "react";
import { useSelector } from "react-redux";

export const useTMOperations = () => {
    const { startedTm, loading } = techMapModel.useLoadStartedTms();
    const loadingTm = useSelector(techMapModel.selectGetStartedTmLoading);

    const templateOperationsWithMapOperation = useMemo(() => {
        return techMapTemplateLib.getTemplateWithMapOperations(startedTm);
    }, [startedTm?.operations, startedTm?.is_paused]);

    return {
        templateOperationsWithMapOperation,
        startedTm,
        loading: loadingTm,
    };
};
