import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLoadBizProcsIds } from "./BizProcSelectors";
import { loadBizProcTemplateThunk } from "./BizProcThunk";

export const usePostLoadBizProcs = () => {
    const loadBizProcsIds = useSelector(selectLoadBizProcsIds);
    const dispatch = useDispatch<AppDispatch>();
    const bizProcTemplateId = loadBizProcsIds?.[0];

    useEffect(() => {
        if (bizProcTemplateId) {
            dispatch(
                loadBizProcTemplateThunk({
                    filter: {
                        key: "id",
                        value: `${bizProcTemplateId}`,
                        condition: "=",
                    },
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, bizProcTemplateId]);
};
