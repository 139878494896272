import { Modal } from "antd";
import TMTONecessaryItemForm from "./TMTONecessaryItemForm";

interface ITMTONecessaryItemModal {
    onClose: () => void;
    onSubmit: (item: TOperationNecessaryItem | TOperationNecessaryItemCreating) => void;
    isOpen: boolean;
    item: TOperationNecessaryItem | TOperationNecessaryItemCreating | null;
}

const TMTONecessaryItemModal = ({
    isOpen,
    item,
    onClose,
    onSubmit,
}: ITMTONecessaryItemModal) => {
    return (
        <Modal
            open={isOpen}
            onCancel={onClose}
            footer={[]}
            destroyOnClose
            closeIcon={null}
            width={"fit-content"}
        >
            {item && (
                <TMTONecessaryItemForm
                    item={item}
                    onSubmit={onSubmit}
                    onCancel={onClose}
                />
            )}
        </Modal>
    );
};

export default TMTONecessaryItemModal;
