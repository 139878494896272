import { Button } from "antd";
import { useTMNecessaryItemDetach } from "../model";

interface ITMNecessaryItemDetach {
    operationItemId: number;
}

const TMNecessaryItemDetach = ({ operationItemId }: ITMNecessaryItemDetach) => {
    const { loading, onDetach } = useTMNecessaryItemDetach(operationItemId);
    return (
        <Button
            size="small"
            style={{ padding: 1, margin: 0, minHeight: 15 }}
            type="link"
            danger
            onClick={onDetach}
            loading={loading}
        >
            Открепить
        </Button>
    );
};

export default TMNecessaryItemDetach;
