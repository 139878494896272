import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import TMTOCodesItem from "./TMTOCodesItem";
import { Button } from "antd";
import { useTMTOCodes } from "../../model";
import { TMTResultSelectOption } from "@entities/Production/TechMapTemplate";
import TMTOCodesReadableItem from "./TMTOCodesReadableItem";

interface ITMTOCodes {
    editingMode: boolean;
    codes: TMTOCodeTemplateItem[];
    onChange: (codes: TMTOCodeTemplateItem[]) => void;
}

const TMTOCodes = ({ editingMode, codes, onChange }: ITMTOCodes) => {
    const { onAddCodeTemplate, onChangeCode, onDeleteCode, getCodeResult } = useTMTOCodes(
        codes,
        onChange
    );
    if (!editingMode)
        return (
            <div>
                {codes.length === 0 ? (
                    <span>QR/Bar коды не будут сгенерированны</span>
                ) : (
                    codes.map((code) => {
                        return (
                            <IndentContainer key={code.technical_map_template_result_id}>
                                <TMTOCodesReadableItem
                                    code={code}
                                    result={getCodeResult(
                                        code.technical_map_template_result_id
                                    )}
                                />
                            </IndentContainer>
                        );
                    })
                )}
            </div>
        );
    return (
        <div>
            <Button type="link" onClick={onAddCodeTemplate}>
                Добавить QR/Bar код
            </Button>
            {codes.map((code) => {
                return (
                    <IndentContainer key={"id" in code ? code.id : code.tmp_id}>
                        <TMTOCodesItem
                            onChange={onChangeCode}
                            onDelete={onDeleteCode}
                            code={code}
                        />
                    </IndentContainer>
                );
            })}
        </div>
    );
};

export default TMTOCodes;
