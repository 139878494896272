export const selectEntityRows = (state: RootState): any[] => state.entityRow.entityRows;
export const selectEntityRowsWithPage = (
    state: RootState,
    key: number | string
): TEntityRowsWithPage | undefined => state.entityRow.entityRowsWithPage[key];
export const selectEntityFields = (state: RootState): TEntityField[] =>
    state.entityRow.entityFields;
export const selectCurrentPage = (state: RootState): number =>
    state.entityRow.currentPage;
export const selectLastPage = (state: RootState): number => state.entityRow.lastPage;
export const selectEntityUserCapabilities = (state: RootState): TUserCapabilities =>
    state.entityRow.entityUserCapabilities;
export const selectIsLoading = (state: RootState): boolean => state.entityRow.isLoading;
export const selectIsDisabledCurrentCategorySelect = (state: RootState): boolean =>
    state.entityRow.isDisabledCurrentCategorySelect;
export const selectCurrentCategory = (state: RootState): number | null =>
    state.entityRow.currentCategory;
export const selectFilters = (state: RootState): TFilterValue[] =>
    state.entityRow.filters;
export const selectGroupingKeys = (state: RootState): string[] =>
    state.entityRow.groupingKeys;
export const selectTextFilterValue = (state: RootState): string | undefined =>
    state.entityRow.textFilterValue;
