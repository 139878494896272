import { parseFormResponse } from "@shared/api/ParseResponse";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    applyAdditionalSumEstimationTemplateThunk,
    selectChoosenEntityFieldsKeys,
    selectIsCalculateAdditional,
    setAdditionalAppliedEstimation,
    setChoosenEntityFieldsKeysForEstimations,
    setChoosenIdsForAdditionalEstimation,
    setIsCalculateAdditional,
} from ".";
import {
    selectAppliedAdditionalEstimation,
    selectChoosenIdsForAdditionalEstimation,
} from "./EstimationSelectors";
import { useDebounceEffect } from "@shared/hooks";
import { getAdditionalCalculationStorageKey, getAdditionalSumStorageKey } from "../lib";

export const useLoadAdditionalEstimation = (entityId: number) => {
    const dispatch = useDispatch<AppDispatch>();
    const choosenEntityFieldsKeys = useSelector(selectChoosenEntityFieldsKeys);
    const choosenRowsIds = useSelector(selectChoosenIdsForAdditionalEstimation);
    const isCalculateAdditional = useSelector(selectIsCalculateAdditional);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);
    const additionalValues = useSelector(selectAppliedAdditionalEstimation);

    useEffect(() => {
        const lastChoosenEntityFieldsKeys = localStorage.getItem(
            getAdditionalSumStorageKey(entityId)
        );
        if (lastChoosenEntityFieldsKeys) {
            const parsed = JSON.parse(lastChoosenEntityFieldsKeys)!;
            dispatch(setChoosenEntityFieldsKeysForEstimations(parsed));
        }

        const isCalculateAdditional = localStorage.getItem(
            getAdditionalCalculationStorageKey(entityId)
        );
        if (isCalculateAdditional) {
            dispatch(setIsCalculateAdditional(true));
        }
        return () => {
            dispatch(setChoosenEntityFieldsKeysForEstimations([]));
            dispatch(setChoosenIdsForAdditionalEstimation([]));
            setError(null);
            setLoading(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useDebounceEffect(
        () => {
            if (
                entityId &&
                choosenEntityFieldsKeys.length > 0 &&
                choosenRowsIds.length > 0 &&
                isCalculateAdditional
            ) {
                applyAdditionalSumByChoosenEntityFields(
                    choosenEntityFieldsKeys,
                    choosenRowsIds,
                    entityId
                );
            } else {
                dispatch(setAdditionalAppliedEstimation([]));
            }
        },
        () => {},
        [choosenEntityFieldsKeys, choosenRowsIds, isCalculateAdditional, entityId]
    );

    const applyAdditionalSumByChoosenEntityFields = (
        entityFieldsKeys: string[],
        selectedRowsIds: number[],
        entityId: number
    ) => {
        setLoading(true);
        dispatch(
            applyAdditionalSumEstimationTemplateThunk({
                entityFieldsKeys,
                selectedRowsIds,
                entityId,
            })
        )
            .then(parseFormResponse)
            .catch((e: any) => setError(e))
            .finally(() => {
                setLoading(false);
            });
    };

    return { additionalValues, loading, error };
};
