import { FormItem } from "@shared/ui/Form";
import "./TMTOCodes.scss";
import { TMTResultSelect } from "@entities/Production/TechMapTemplate";
import { useTMTOCodesItem } from "../../model";
import { EntityFieldSelect } from "@entities/Portal/EntityField";
import { Button, Input } from "antd";
import { FlexContainer } from "@shared/ui/Containers/FlexContainer";
interface ITMTOCodesItem {
    code: TMTOCodeTemplateItem;
    onChange: (code: TMTOCodeTemplateItem) => void;
    onDelete: (code: TMTOCodeTemplateItem) => void;
}

const TMTOCodesItem = ({ code, onChange, onDelete }: ITMTOCodesItem) => {
    const {
        selectedResult,
        onChangeResult,
        onChangeFieldKey,
        onChangeValue,
        onDeleteItem,
    } = useTMTOCodesItem({
        code,
        onChange,
        onDelete,
    });

    return (
        <div className="tmto-code-item">
            <FormItem label={"Результат"}>
                <TMTResultSelect
                    value={code.technical_map_template_result_id}
                    onlyRead={false}
                    onChange={onChangeResult}
                />
            </FormItem>
            <FormItem label={"Куда записать"}>
                {selectedResult ? (
                    <EntityFieldSelect
                        fieldDatatypeTypes={["barCode", "qrCode"]}
                        fieldKey={code.field_key}
                        entityUuid={selectedResult.entity_uuid!}
                        onChange={onChangeFieldKey}
                    />
                ) : (
                    <span>Не выбран результат</span>
                )}
            </FormItem>
            <FormItem label={"Значение"}>
                <>
                    <Input
                        placeholder="Незаполнено (код будет сгенерирован автоматически)"
                        onChange={onChangeValue}
                        value={code.value ?? ""}
                    />
                </>
            </FormItem>
            <FlexContainer type="row-end">
                <Button danger type="link" onClick={onDeleteItem}>
                    Удалить
                </Button>
            </FlexContainer>
        </div>
    );
};

export default TMTOCodesItem;
