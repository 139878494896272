import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { getStartedTms, getTm } from "../../api";
import {
    setGetStartedTmLoading,
    setGetStartedTmsLoading,
    setStartedTm,
    setStartedTms,
} from "../TechMapSlice";
import { TM_STARTED_STORAGE_KEY } from "../../constants";

const getStartedTms_ = async (userId: number) => {
    try {
        const response = await getStartedTms(userId);

        return response.data.data.data;
    } catch (err) {
        throw err;
    }
};

export const loadStartedTm =
    (tmId: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
        try {
            dispatch(setGetStartedTmLoading(true));
            localStorage.setItem(TM_STARTED_STORAGE_KEY, tmId.toString());
            const response = await getTm(tmId);
            dispatch(setStartedTm(response.data.data));
        } catch (err) {
            throw err;
        }
        dispatch(setGetStartedTmLoading(false));
    };

export const loadStartedTms =
    (userId: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(setGetStartedTmsLoading(true));
        try {
            const tms = await getStartedTms_(userId);

            dispatch(setStartedTms(tms));
            if (tms.length > 0) {
                const startedTmId = localStorage.getItem(TM_STARTED_STORAGE_KEY);
                const savedStartedTm = tms.find(
                    (tm) => tm.id === Number(startedTmId)
                )?.id;
                dispatch(loadStartedTm(savedStartedTm ?? tms[0].id));
            }
        } catch (err) {
            notificationEmit({
                type: "error",
                title: "Не удалось загрузить информацию о запущенных процессах",
            });
        }
        dispatch(setGetStartedTmsLoading(false));
    };
