import { actionModel } from "@entities/Portal/Action";
import { bizProcModel } from "@entities/Portal/BizProc";
import { bizProcTemplateModel } from "@entities/Portal/BizProcTemplate";
import { calculatedValueModel } from "@entities/Portal/CalculatedValues";
import { categoryModel } from "@entities/Portal/Category";
import { datatypeModel } from "@entities/Portal/Datatype";
import { entityModel } from "@entities/Portal/Entity";
import { entityFieldModel } from "@entities/Portal/EntityField";
import { entityRowModel } from "@entities/Portal/EntityRow";
import { estimationModel } from "@entities/Portal/Estimations";
import { fieldOptionModel } from "@entities/Portal/FieldOption";
import { navigationRouteModel } from "@entities/Portal/NavigationRoute";
import { portalSettingModel } from "@entities/Portal/PortalSetting";
import { capabilitiesRolesModel } from "@entities/Portal/CapabilitiesRoles";
import { stageModel } from "@entities/Portal/Stage";
import { userModel } from "@entities/Portal/User";
import { variableModel } from "@entities/Portal/Variables";
import { viewPatternModel } from "@entities/Portal/ViewPattern";
import { viewPatternItemModel } from "@entities/Portal/ViewPatternItem";
import { configureStore } from "@reduxjs/toolkit";
import { techMapTemplateModel } from "@entities/Production/TechMapTemplate";
import { techMapModel } from "@entities/Production/TechMap";

export const store = configureStore({
    reducer: {
        user: userModel.userSlice.reducer,
        category: categoryModel.categorySlice.reducer,
        datatype: datatypeModel.datatypeSlice.reducer,
        action: actionModel.actionSlice.reducer,
        stage: stageModel.stageSlice.reducer,
        entity: entityModel.entitySlice.reducer,
        bizProcTemplate: bizProcTemplateModel.bizProcTemplateSlice.reducer,
        bizProc: bizProcModel.bizProcSlice.reducer,
        variable: variableModel.variableSlice.reducer,
        entityField: entityFieldModel.entityFieldSlice.reducer,
        fieldOption: fieldOptionModel.fieldOptionSlice.reducer,
        calculatedValue: calculatedValueModel.calculatedValueSlice.reducer,
        portalSetting: portalSettingModel.portalSettingSlice.reducer,
        navigationRoute: navigationRouteModel.navigationRouteSlice.reducer,
        entityRow: entityRowModel.entityRowSlice.reducer,
        viewPattern: viewPatternModel.viewPatternSlice.reducer,
        viewPatternItem: viewPatternItemModel.viewPatternItemSlice.reducer,
        estimations: estimationModel.estimationSlice.reducer,
        capabilitiesRoles: capabilitiesRolesModel.capabilitiesRolesSlice.reducer,
        techMapTemplate: techMapTemplateModel.techMapTemplateSlice.reducer,
        techMap: techMapModel.techMapSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: { ignoredPaths: ["techMap.techMapsPaginated"] },
        }),
});
