import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IStage } from "./StageSlice";
import {
    createStageThunk,
    deleteStageThunk,
    loadStagesThunk,
    updateStagePrevStageUuidThunk,
    updateStageThunk,
} from "./StageThunk";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { updateArrByItemId } from "@shared/lib/object-helper";
import { reorderStages } from "../lib";

const stageLoadBuilder = (builder: ActionReducerMapBuilder<IStage>) => {
    builder.addCase(loadStagesThunk.fulfilled, (state, action) => {
        const categoryId = action.meta.arg.categoryId;
        if (categoryId) {
            state.stages[categoryId] = reorderStages(action.payload.data);
            state.getLoading = state.getLoading.filter(
                (item) => item !== action.meta.arg.categoryId
            );
        } else {
            state.allStagesFlatArray = action.payload.data;
            state.gettingAllStagesLoading = false;
        }
    });
    builder.addCase(loadStagesThunk.pending, (state, action) => {
        const categoryId = action.meta.arg.categoryId;
        if (categoryId) state.getLoading.push(categoryId);
        else state.gettingAllStagesLoading = true;
    });
    builder.addCase(loadStagesThunk.rejected, (state, rejectedValue) => {
        const categoryId = rejectedValue.meta.arg.categoryId;
        state.getLoading = state.getLoading.filter((item) => item !== categoryId);
        state.gettingAllStagesLoading = false;
        notificationEmit({
            title: "Не удалось загрузить стадии",
            type: "error",
        });
    });
};

const stageUpdatePrevStageUuidBuilder = (builder: ActionReducerMapBuilder<IStage>) => {
    builder.addCase(updateStagePrevStageUuidThunk.fulfilled, (state, action) => {
        const categoryId = action.meta.arg.categoryId;
        state.stages[categoryId] = reorderStages(action.payload.data as TStage[]);
        state.getLoading = state.getLoading.filter(
            (item) => item !== action.meta.arg.categoryId
        );
    });
    builder.addCase(updateStagePrevStageUuidThunk.pending, (state, action) => {
        state.getLoading.push(action.meta.arg.categoryId);
    });
    builder.addCase(updateStagePrevStageUuidThunk.rejected, (state, rejectedValue) => {
        state.getLoading = state.getLoading.filter(
            (item) => item !== rejectedValue.meta.arg.categoryId
        );
        notificationEmit({
            title: "Не удалось загрузить стадии",
            type: "error",
        });
    });
};

const stageCreateBuilder = (builder: ActionReducerMapBuilder<IStage>) => {
    builder.addCase(createStageThunk.fulfilled, (state, action) => {
        const categoryId = action.meta.arg.categoryId;
        state.stages[categoryId] = reorderStages([
            action.payload.data,
            ...state.stages[categoryId],
        ]);
        state.createLoading = state.createLoading.filter(
            (item) => item !== action.meta.arg.categoryId
        );
    });
    builder.addCase(createStageThunk.pending, (state, action) => {
        state.createLoading.push(action.meta.arg.categoryId);
    });
    builder.addCase(createStageThunk.rejected, (state, rejectedValue) => {
        state.createLoading = state.createLoading.filter(
            (item) => item !== rejectedValue.meta.arg.categoryId
        );
        notificationEmit({
            title: "Не удалось создать стадию",
            type: "error",
        });
    });
};

const stageUpdateBuilder = (builder: ActionReducerMapBuilder<IStage>) => {
    builder.addCase(updateStageThunk.fulfilled, (state, action) => {
        const categoryId = action.meta.arg.categoryId;
        state.stages[categoryId] = reorderStages(
            updateArrByItemId(state.stages[categoryId], action.payload.data)
        );
        state.updateLoading = state.updateLoading.filter(
            (item) => item !== action.meta.arg.stage.id
        );
    });
    builder.addCase(updateStageThunk.pending, (state, action) => {
        state.updateLoading.push(action.meta.arg.stage.id);
    });
    builder.addCase(updateStageThunk.rejected, (state, rejectedValue) => {
        state.updateLoading = state.updateLoading.filter(
            (item) => item !== rejectedValue.meta.arg.stage.id
        );
        notificationEmit({
            title: "Не удалось обновить стадию",
            type: "error",
        });
    });
};

const stageDeleteBuilder = (builder: ActionReducerMapBuilder<IStage>) => {
    builder.addCase(deleteStageThunk.fulfilled, (state, action) => {
        const categoryId = action.meta.arg.categoryId;
        state.stages[categoryId] = reorderStages(action.payload.data);
        state.deleteLoading = state.deleteLoading.filter(
            (item) => item !== action.meta.arg.stageId
        );
    });
    builder.addCase(deleteStageThunk.pending, (state, action) => {
        state.deleteLoading.push(action.meta.arg.stageId);
    });
    builder.addCase(deleteStageThunk.rejected, (state, rejectedValue) => {
        state.deleteLoading = state.deleteLoading.filter(
            (item) => item !== rejectedValue.meta.arg.stageId
        );
        notificationEmit({
            title: "Не удалось удалить стадию",
            type: "error",
        });
    });
};

export const stageBuilder = (builder: ActionReducerMapBuilder<IStage>) => {
    stageLoadBuilder(builder);
    stageCreateBuilder(builder);
    stageUpdateBuilder(builder);
    stageDeleteBuilder(builder);
    stageUpdatePrevStageUuidBuilder(builder);
};
