import { techMapModel } from "@entities/Production/TechMap";

export const useTMOCodes = (operationId: number, tm: TTechMapStarted | null) => {
    const { operation, loading } = techMapModel.useLoadFullOperation({ operationId });

    const results = tm?.template.results ?? [];

    const codes = operation?.codes ?? [];
    const codeTemplates = (operation?.template_operation.code_templates ??
        []) as TMTOCodeTemplate[];
    return {
        codes,
        codeTemplates,
        results,
        loading,
    };
};
