import { EditOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
interface INavigateToEditingEntity {
    entityId: number;
}
const NavigateToEditingEntity = ({ entityId }: INavigateToEditingEntity) => {
    const navigate = useNavigate();
    //delete
    const onNavigateToEditingEntity = () => {
        navigate(`entity/${entityId}`);
    };

    return (
        <Button type="link" onClick={onNavigateToEditingEntity} icon={<EditOutlined />} />
    );
};

export default NavigateToEditingEntity;
