import { entityRowModel } from "@entities/Portal/EntityRow";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

export const useEntityRowsFiltersForm = (
    visibleInputs: (TBizProcFormField & {
        condition?: any;
    })[],
    setCurrentFilters: (filters: TFilterValue[]) => void
) => {
    const currentCategory = useSelector(entityRowModel.selectCurrentCategory);
    const isDisabledCurrentCategorySelect = useSelector(
        entityRowModel.selectIsDisabledCurrentCategorySelect
    );
    const dispatch = useDispatch();
    const onChangeFormFieldValue = (
        newValue: any,
        inputId?: string | number | undefined
    ) => {
        const newFilters = visibleInputs.map((field) => {
            const value = field.unique_id === inputId ? newValue : field.value;
            if (
                ((!Array.isArray(value) && !value) ||
                    (Array.isArray(value) && value.length === 0)) &&
                field.key === "category"
            ) {
                dispatch(entityRowModel.setIsDisabledCurrentCategorySelect(false));
            }
            if (
                inputId === "entity-category" &&
                ((!Array.isArray(value) && value) ||
                    (Array.isArray(value) && value.length > 0)) &&
                field.key === "category"
            ) {
                dispatch(entityRowModel.setIsDisabledCurrentCategorySelect(true));
            }
            if (!value) {
                return {
                    key: field.key,
                    value: value,
                    condition: !field.condition && value ? "=" : field.condition ?? null,
                };
            }
            return {
                key: field.key,
                value:
                    field.key === "category" &&
                    currentCategory &&
                    !isDisabledCurrentCategorySelect &&
                    (!value || value.length === 0)
                        ? currentCategory.toString()
                        : value,
                condition: !field.condition && value ? "=" : field.condition ?? null,
            };
        });
        setCurrentFilters(newFilters);
    };

    const onChangeFormFieldCondition = (
        newCondition: any,
        inputId?: string | number | undefined
    ) => {
        const newFilters = visibleInputs.map((field) => {
            const condition =
                field.unique_id === inputId ? newCondition : field.condition;

            const value =
                field.unique_id === inputId
                    ? condition === "null"
                        ? ""
                        : condition === "in" ||
                            field.condition === "in" ||
                            field.condition === "between"
                          ? condition === "in"
                              ? []
                              : null
                          : field.value
                    : field.value;
            return {
                key: field.key,
                value: value,
                condition: condition,
            };
        });
        setCurrentFilters(newFilters);
    };
    return {
        isDisabledCurrentCategorySelect,
        currentCategory,
        onChangeFormFieldCondition,
        onChangeFormFieldValue,
    };
};
