import { FlexContainer } from "@shared/ui/Containers/FlexContainer";
import { Collapse, CollapseProps, Flex, Spin } from "antd";
import DropdownGroup from "./DropdownGroup";
import "./InputWithVariable.scss";

interface IInputDropdown {
    isOpen: boolean;
    loading: boolean;
    onSelectItem: (item: TDropdownItem) => void;
    items: TDropdownVariablesGroup[];
    width: number | null;
    // searchValue: string;
}

const InputDropdown = ({
    isOpen,
    loading,
    // searchValue,
    onSelectItem,
    items,
    width,
}: IInputDropdown) => {
    const collapseItems: CollapseProps["items"] = items.map((group) => {
        return {
            key: group.key,
            label: group.name,
            children: (
                <DropdownGroup
                    group={group}
                    key={group.key}
                    onSelectItem={onSelectItem}
                />
            ),
        };
    });
    return (
        <div
            className="input-dropdown"
            style={{
                width: width ?? "auto",
                height: isOpen ? 200 : 0,
            }}
        >
            {isOpen && (
                <>
                    {loading ? (
                        <Flex align="center" justify="center">
                            <Spin style={{ marginTop: 24 }} />
                        </Flex>
                    ) : collapseItems.length > 0 ? (
                        <Collapse
                            className="input-dropdown__collapse"
                            items={collapseItems}
                        />
                    ) : (
                        <FlexContainer>
                            <>Переменные не найдены</>
                        </FlexContainer>
                    )}
                </>
            )}
        </div>
    );
};

export default InputDropdown;
