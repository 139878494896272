import { useResizeObserver } from "@wojtekmaj/react-hooks";
import { useState } from "react";

export const useWindowResize = (limits: number[] = []) => {
    const [flags, setFlags] = useState<boolean[]>(
        limits.map((limit) => window.innerWidth > limit)
    );
    const onResize = (entries: ResizeObserverEntry[]) => {
        const [entry] = entries;

        if (entry) {
            let needUpdate = false;
            const newFlagsValue: boolean[] = [];

            limits.forEach((limit, index) => {
                const newValue = entry.contentRect.width > limit;
                newFlagsValue.push(newValue);
                if (newValue != flags[index]) needUpdate = true;
            });

            if (needUpdate) setFlags(newFlagsValue);
        }
    };
    useResizeObserver(document.body, {}, onResize);

    return flags;
};
