import { TMOperationsList } from "@entities/Production/TechMap";
import { FormItem } from "@shared/ui/Form";
import { memo } from "react";
import { useTMFullInfo } from "../model";
import "./TMFullInfo.scss";
import TMFullInfoMain from "./TMFullInfoMain";
import TMFullInfoMainResults from "./TMFullInfoMainResults";
import TMFullInfoTime from "./TMFullInfoTime";

interface ITMFullInfo {
    tmId: number;
}

const TMFullInfo = ({ tmId }: ITMFullInfo) => {
    const { techMap, templateOperationsWithMapOperation, loading } = useTMFullInfo({
        tmId,
    });

    return (
        <div className="tm-info">
            <div className="tm-info__operations">
                <TMOperationsList
                    templateOperationsWithMapOperation={
                        templateOperationsWithMapOperation
                    }
                    loading={loading}
                />
            </div>
            {techMap && (
                <>
                    <div className="tm-info__main">
                        <div className="tm-info__main__info">
                            <TMFullInfoMain techMap={techMap} />
                            <FormItem label="Результат">
                                <TMFullInfoMainResults techMap={techMap} />
                            </FormItem>
                        </div>
                        <div className="tm-info__main__info">
                            <TMFullInfoTime techMap={techMap} />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default memo(TMFullInfo);
