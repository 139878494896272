import { CreateEditEntityRow } from "@features/Portal/CreateEditEntityRow";
import {
    StagesEntityRow,
    StagesEntityRowSkeleton,
} from "@features/Portal/StageFeatures/StagesEntityRow";
import { Bookmark, DrawerDefault } from "@shared/ui/DrawerManager";
import { memo } from "react";
import "./CreateEditEntityRowDrawer.scss";
import { useCreateEditEntityRowDrawer } from "./UseCreateEditEntityRowDrawer";
interface ICreateEditEntityRowDrawer {
    isOpenDrawer: boolean;
    entityId?: number;
    level?: number;
    hideMask?: boolean;
    entityTableName?: string;
    entityRowId?: number | null;
    initialValues?: { [key: string]: any } | null;
    hideHistory?: boolean;
    history?: (props: {
        entityRowId: number;
        entityId?: number;
        entityTableName?: string;
    }) => React.ReactNode | null;
    openCloseDrawerWithNavigate: () => void;
}

const CreateEditEntityRowDrawer = ({
    isOpenDrawer,
    level,
    entityId,
    entityTableName,
    entityRowId,
    initialValues,
    hideMask,
    hideHistory,
    history,
    openCloseDrawerWithNavigate,
}: ICreateEditEntityRowDrawer) => {
    const { onlyReadMode, setOnlyReadMode, onCloseDrawer, afterOpenChange } =
        useCreateEditEntityRowDrawer({
            openCloseDrawerWithNavigate,
        });
    const windowWidth = window.innerWidth;
    const shortVariant = windowWidth <= 900;
    const drawerLevel = level ?? 0;
    return (
        <DrawerDefault
            maskClosable={onlyReadMode}
            open={isOpenDrawer}
            onClose={openCloseDrawerWithNavigate}
        >
            <div
                className="drawer-default"
                style={{
                    width: `calc(${
                        hideHistory
                            ? "560px"
                            : !shortVariant
                              ? entityRowId
                                  ? "60vw"
                                  : "526px"
                              : "100vw"
                    } + ${drawerLevel * 30}px)`,
                }}
            >
                <CreateEditEntityRow
                    title={({
                        entityRow,
                        entity,
                        isLoading,
                        entityRowCapabilities,
                        editableEntityFieldsWithValues,
                        onChangeEntityRow,
                    }) => {
                        const canUpdateStage =
                            entityRowCapabilities?.update &&
                            editableEntityFieldsWithValues.some(
                                (field) => field.key === "stage"
                            );
                        return (
                            <>
                                <>
                                    {!shortVariant && entity && (
                                        <Bookmark
                                            tooltip={entity.name}
                                            zIndex={(1 + drawerLevel) * 2000}
                                            top={30 + drawerLevel * 20}
                                            onClose={onCloseDrawer}
                                        >
                                            {entity.name}
                                        </Bookmark>
                                    )}
                                </>
                                {isLoading ? (
                                    <StagesEntityRowSkeleton withMargins />
                                ) : (
                                    entityRowId &&
                                    entity?.id &&
                                    entityRow?.["category"] && (
                                        <StagesEntityRow
                                            onlyRead={!canUpdateStage}
                                            entityRow={entityRow}
                                            categoryId={entityRow?.["category"]}
                                            withMargins
                                            entityId={entity.id}
                                            onChangeEntityRow={onChangeEntityRow}
                                        />
                                    )
                                )}
                            </>
                        );
                    }}
                    entityId={entityId}
                    //setEntity={setEntity}
                    entityTableName={entityTableName}
                    entityRowId={entityRowId}
                    onlyReadMode={entityRowId ? onlyReadMode : false}
                    setOnlyReadMode={setOnlyReadMode}
                    additionalActions={onCloseDrawer}
                    history={
                        entityRowId &&
                        history &&
                        history({ entityRowId, entityId, entityTableName })
                    }
                    initialValues={initialValues}
                    hideHistory={hideHistory}
                />
            </div>
        </DrawerDefault>
    );
};

export default memo(CreateEditEntityRowDrawer);
