import { Bar } from "@shared/ui/Bar";
import { useTMTBar } from "../model";

interface ITMTBar {}

const TMTBar = ({}: ITMTBar) => {
    const {
        options,
        loading,
        startingLoadingItemsIds,
        activeItemsIds,
        searchValue,
        onSearch,
        onClickItem,
    } = useTMTBar();

    return (
        <Bar
            searchValue={searchValue}
            label="Доступные процессы"
            onSearch={onSearch}
            onClick={onClickItem}
            loading={loading}
            loadingValues={startingLoadingItemsIds}
            activeValues={activeItemsIds}
            options={options}
        />
    );
};

export default TMTBar;
