import { UserOutlined } from "@ant-design/icons";
import "./UserAssygnment.scss";
import { useQueryParams } from "@shared/hooks/UseQueryParams";
interface IUserAssygnment {
    user: TUser | null;
    status?: TBizProcStatus;
    onUserClick?: (user: TUser) => void;
}

const UserAssygnment = ({ user, status, onUserClick }: IUserAssygnment) => {
    const { setParam } = useQueryParams("user");
    const onUserAssygnmentClick = () => {
        if (!user) return;
        onUserClick ? onUserClick(user) : setParam(user.id);
    };

    return (
        <>
            <div className="assigned-user">
                <span
                    className="assigned-user__user"
                    style={{ color: "#4F4F4F" }}
                    onClick={onUserAssygnmentClick}
                >
                    <UserOutlined
                        onClick={onUserAssygnmentClick}
                        style={{ marginRight: 4 }}
                    />
                    {user ? user.name : "Не найден"}
                </span>
            </div>
        </>
    );
};

export default UserAssygnment;
