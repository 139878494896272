import { bizProcTemplateApi } from "@entities/Portal/BizProcTemplate";
import { entityApi, entityModel } from "@entities/Portal/Entity";
import { entityFieldApi } from "@entities/Portal/EntityField";
import { SelectProps } from "antd";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { generateQrCodes } from "../api";
import {
    chunkArray,
    getChunkSize,
    getHorizontalCountsOfQrs,
    getVerticalCountsOfQrs,
} from "../lib";
import { minimalQrCodeSize } from "../constants";

type TQRCodeWithName = {
    link: string;
    name: string;
};

export const useGenerateQRCode = () => {
    const dispatch = useDispatch();
    const [withGeneratePdf, setWithGeneratePdf] = useState(false);
    const [choosenBizProcTemplateId, setChoosenBizProcTemplateId] = useState<
        number | null
    >(null);
    const [selectedDepartmentsIds, setSelectedDepartmentsIds] = useState<number[]>([]);
    const [selectedEntityFieldId, setSelectedEntityFieldId] = useState<number | null>(
        null
    );
    const [bizProcTemplates, setBizProcTemplates] = useState<TBizProcTemplateItem[]>([]);
    const [departments, setDepartments] = useState<TDepartment[]>([]);
    const [entityFields, setEntityFields] = useState<TEntityField[]>([]);
    const [chunkedPdfPages, setChunkedPdfPages] = useState<TQRCodeWithName[][]>([]);
    const [needGeneratePdf, setNeedGeneratePdf] = useState(false);
    const [loading, setLoading] = useState(false);
    const [qrCodeSize, setQrCodeSize] = useState<number>(minimalQrCodeSize);
    const [pdfOrientation, setPdfOrientation] = useState<"p" | "l">("p");
    const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
    const [conditionToGeneratePDF, setConditionToGeneratePDF] = useState<
        "download" | "preview"
    >("download");

    const clickedEntityForQRCode = useSelector(entityModel.selectClickedEntityForQRCode);
    const bizProcTemplatesOptions: SelectProps["options"] = bizProcTemplates.map(
        (item: TBizProcTemplateItem) => {
            return { value: item.id, label: item.name };
        }
    );
    const entityFieldsOptions: SelectProps["options"] = entityFields.map(
        (entityField: TEntityField) => ({
            value: entityField.id,
            label: entityField.name,
        })
    );
    const departmentsOptions: SelectProps["options"] = departments.map((department) => ({
        value: department.id,
        label: department.name,
    }));
    const horizontalCountOfQrs = getHorizontalCountsOfQrs(pdfOrientation, qrCodeSize);
    const vericalCountOfQrs = getVerticalCountsOfQrs(pdfOrientation, qrCodeSize);
    const CHUNK_SIZE = getChunkSize(horizontalCountOfQrs, vericalCountOfQrs);

    useEffect(() => {
        if (clickedEntityForQRCode) {
            bizProcTemplateApi
                .getBizProcEntityTemplates(clickedEntityForQRCode.id)
                .then((response) => {
                    const bizProcTemplates = response.data.data;
                    setBizProcTemplates(bizProcTemplates);
                })
                .catch((e: any) => {
                    notificationEmit({
                        type: "error",
                        title: "Ошибка загрузки бизнесс-процессов",
                    });
                });
        }
    }, [clickedEntityForQRCode]);
    useEffect(() => {
        if (clickedEntityForQRCode)
            entityApi
                .getDepartments()
                .then((response) => {
                    const departments = response.data.data;
                    setDepartments(departments);
                })
                .catch((e: any) => console.log(e));
    }, [clickedEntityForQRCode]);
    useEffect(() => {
        if (clickedEntityForQRCode)
            entityFieldApi
                .getEntityFieldsByEntityUuid(clickedEntityForQRCode?.uuid)
                .then((response) => {
                    const entityFields = response.data.data;
                    setEntityFields(entityFields);
                })
                .catch((e: any) => console.log(e));
    }, [clickedEntityForQRCode]);
    useEffect(() => {
        if (needGeneratePdf) {
            generatePDF(chunkedPdfPages, conditionToGeneratePDF, pdfOrientation)
                .then(() => {
                    setNeedGeneratePdf(false);
                    setConditionToGeneratePDF("download");
                    if (conditionToGeneratePDF === "download") {
                        onCancel();
                        setLoading(false);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [needGeneratePdf]);

    const onSelectBizProcTemplateId = (value: number) => {
        setChoosenBizProcTemplateId(value);
    };
    const onSelectDepartmentsIds = (value: number[]) => {
        setSelectedDepartmentsIds(value);
    };

    const onSelectEntityField = (value: number) => {
        setSelectedEntityFieldId(value);
    };

    const onOpenDrawer = () => {
        setIsOpenDrawer(true);
    };

    const onPreload = () => {
        const qrCodesLinks = Array.from({ length: CHUNK_SIZE }).map((item) => {
            return {
                name: "Рыбацкий Анатолий Анатолиевич",
                link: "Василий Иван Петрович",
            };
        });
        const chunked = chunkArray(qrCodesLinks, CHUNK_SIZE) as TQRCodeWithName[][];
        setConditionToGeneratePDF("preview");
        setNeedGeneratePdf(true);
        setChunkedPdfPages(chunked);
    };

    const onOk = () => {
        if (
            choosenBizProcTemplateId &&
            selectedDepartmentsIds?.length > 0 &&
            clickedEntityForQRCode
        ) {
            setLoading(true);
            generateQrCodes(
                clickedEntityForQRCode?.id,
                selectedEntityFieldId ?? 0,
                choosenBizProcTemplateId,
                selectedDepartmentsIds
            )
                .then((response) => {
                    const qrCodesLinks = response.data.data as TQRCodeWithName[];
                    setChunkedPdfPages([...chunkArray(qrCodesLinks, CHUNK_SIZE)]);
                    if (withGeneratePdf) setNeedGeneratePdf(true);
                    else setLoading(false);
                    setConditionToGeneratePDF("download");
                    notificationEmit({
                        type: "success",
                        title: "QR коды сгенерированы",
                    });
                })
                .catch((e: any) => {
                    setLoading(false);
                    notificationEmit({
                        type: "error",
                        title: "Не удалось создать PDF файл",
                        description: e.message,
                    });
                });
        } else {
            setLoading(false);
            notificationEmit({
                type: "error",
                title: "Ошибка",
                description: "Заполните все поля",
            });
        }
    };

    const generatePDF = async (
        chunked: TQRCodeWithName[][],
        resultAction: "download" | "preview" = "download",
        orientation: "p" | "l" = "p"
    ) => {
        const pdf = new jsPDF({
            orientation: orientation ?? "p",
            unit: "px",
            format: "a4",
            compress: true,
        });
        const images = chunked.map(async (chunk, index) => {
            const groupEl = document.getElementById(`key_${index}`)!;
            return new Promise((resolve, reject) => {
                groupEl.style.display = "flex";
                const result = html2canvas(groupEl)
                    .then((canvas) => {
                        const imgData = canvas.toDataURL("image/png");
                        /* @ts-ignore */
                        pdf.addImage(imgData, "PNG", 0, 0);
                        if (chunked[index + 1]) {
                            pdf.addPage();
                        }
                    })
                    .catch((e) => console.log(e));
                groupEl.style.display = "none";
                resolve(result);
            });
        });
        Promise.all(images).then(() => {
            if (resultAction === "preview") {
                const blob = pdf.output("blob");
                window.open(URL.createObjectURL(blob));
            } else {
                const name = `Бизнес-процесс:${choosenBizProcTemplateId}_Департаменты:${selectedDepartmentsIds}.pdf`;
                pdf.save(name);
            }
        });
    };

    const onCancel = () => {
        setQrCodeSize(minimalQrCodeSize);
        setPdfOrientation("p");
        setChunkedPdfPages([]);
        setSelectedDepartmentsIds([]);
        setSelectedEntityFieldId(null);
        setChoosenBizProcTemplateId(null);
        dispatch(entityModel.setClickedEntityForQRCode(null));
    };

    const onChangeWithGeneratePdf = () => {
        setWithGeneratePdf(!withGeneratePdf);
    };
    return {
        choosenBizProcTemplateId,
        bizProcTemplatesOptions,
        entityFieldsOptions,
        chunkedPdfPages,
        departmentsOptions,
        selectedDepartmentsIds,
        selectedEntityFieldId,
        loading,
        clickedEntityForQRCode,
        qrCodeSize,
        pdfOrientation,
        isOpenDrawer,
        withGeneratePdf,
        onChangeWithGeneratePdf,
        setQrCodeSize,
        setPdfOrientation,
        setIsOpenDrawer,
        onSelectBizProcTemplateId,
        onPreload,
        onOpenDrawer,
        onOk,
        onCancel,
        onSelectEntityField,
        onSelectDepartmentsIds,
    };
};
