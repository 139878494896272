import { emitter } from "@shared/emitter";
import { CHECK_AND_PRINT_EMIT, PRINTER_EMIT } from "./constants";

export const printCode = (code: string, type: TPrinterCodeItemType) => {
    emitter.emit(PRINTER_EMIT, code, type);
};

export const checkConnectionAndPrintCode = (code: string, type: TPrinterCodeItemType) => {
    emitter.emit(CHECK_AND_PRINT_EMIT, code, type);
};
