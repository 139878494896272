import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { Button } from "antd";
import { useTMTONecessaryItems } from "../../model";
import TMTONecessaryItem from "./TMTONecessaryItem";
import TMTONecessaryItemModal from "./TMTONecessaryItemModal";
import "./TMTONecessaryItems.scss";

interface ITMTONecessaryItems {
    tmtoUuid: string;
    items: (TOperationNecessaryItem | TOperationNecessaryItemCreating)[];
    editingMode?: boolean;
    onChange: (
        items: (TOperationNecessaryItem | TOperationNecessaryItemCreating)[]
    ) => void;
}

const TMTONecessaryItems = ({
    tmtoUuid,
    items,
    editingMode,
    onChange,
}: ITMTONecessaryItems) => {
    const { isOpen, editingItem, onClose, onUpdateItem, onDelete, onCreate, onOpen } =
        useTMTONecessaryItems(tmtoUuid, items, onChange);

    return (
        <div className="tmton-items">
            <TMTONecessaryItemModal
                item={editingItem}
                onClose={onClose}
                isOpen={isOpen}
                onSubmit={onUpdateItem}
            />
            {editingMode && (
                <Button type="link" onClick={onCreate}>
                    Добавить
                </Button>
            )}
            {items?.length > 0 ? (
                items.map((item) => {
                    return (
                        <IndentContainer key={"id" in item ? item.id : item.tmp_id}>
                            <TMTONecessaryItem
                                item={item}
                                editingMode={editingMode}
                                onEdit={onOpen}
                                onDelete={onDelete}
                            />
                        </IndentContainer>
                    );
                })
            ) : (
                <span>Компоненты не указаны</span>
            )}
        </div>
    );
};

export default TMTONecessaryItems;
