import { LoadingOutlined } from "@ant-design/icons";
import { SelectEntityRow } from "@entities/Portal/EntityRow";
import { techMapModel } from "@entities/Production/TechMap";
import { CardWithAvatar } from "@shared/ui/Cards/CardWithAvatar";
import { Space, Spin } from "antd";
import { useSelector } from "react-redux";
import TMNecessaryItemAttach from "./TMNecessaryItemAttach";
import TMNecessaryItemDetach from "./TMNecessaryItemDetach";

interface ITMNecessaryItem {
    templateItem: TOperationNecessaryItem;
    operationItem?: TTechMapOperationItem;
}

const TMNecessaryItem = ({ templateItem, operationItem }: ITMNecessaryItem) => {
    const loading = useSelector(techMapModel.selectAttachItemLoading);
    const isAdded = !!operationItem;
    const needFill =
        templateItem.type === "filling" && Boolean(templateItem.is_scannable);
    const isPreventFilledItem = Boolean(
        !Boolean(templateItem.is_scannable) &&
            templateItem.type === "filling" &&
            templateItem.related_entity_row_id &&
            templateItem.related_entity_uuid
    );

    return (
        <CardWithAvatar
            customStyle={
                isAdded || !needFill
                    ? {
                          backgroundColor: "#c5efb0",
                          borderColor: "#c5efb0",
                      }
                    : {
                          backgroundColor: "#f5ecafaa",
                          borderColor: "#f5ecaf",
                      }
            }
            hideAvatar
            header={
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Space>
                        <div>
                            {!isPreventFilledItem && <div>{templateItem.label}</div>}
                            {isPreventFilledItem ? (
                                <SelectEntityRow
                                    onlyRead
                                    byUuid
                                    value={templateItem.related_entity_row_id!}
                                    entityInfo={templateItem.related_entity_uuid!}
                                    onChange={() => {}}
                                />
                            ) : operationItem != undefined &&
                              operationItem.related_entity_row_id &&
                              templateItem.related_entity_uuid ? (
                                <SelectEntityRow
                                    onlyRead
                                    byUuid
                                    value={operationItem.related_entity_row_id}
                                    entityInfo={templateItem.related_entity_uuid}
                                    onChange={() => {}}
                                />
                            ) : (
                                <></>
                            )}
                        </div>
                        {!isAdded && needFill && (
                            <div
                                style={{
                                    fontSize: 12,
                                    color: "var(--primary)",
                                }}
                            >
                                {loading ? (
                                    <div>
                                        <Spin indicator={<LoadingOutlined />} />
                                    </div>
                                ) : (
                                    "Ожидает заполнения"
                                )}
                            </div>
                        )}
                        {isAdded && !isPreventFilledItem && (
                            <TMNecessaryItemDetach operationItemId={operationItem.id} />
                        )}
                    </Space>
                </div>
            }
            rightNode={!isAdded && needFill && <TMNecessaryItemAttach />}
            description={
                <div style={{ display: "flex" }}>
                    <span>Количество:</span>
                    <span style={{ marginLeft: 5 }}>{templateItem.count ?? 1}</span>
                </div>
            }
        />
    );
};

export default TMNecessaryItem;
