import { Flex } from "antd";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { usePdfViewer } from "../model";
import "./PDFViewer.scss";
import PDFViewerContent from "./PDFViewerContent";
import PDFViewerHeader from "./PDFViewerHeader";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@4.4.168/build/pdf.worker.min.mjs`;

interface IPDFViewer {
    linkToPdfFile: string;
}

const PDFViewer = ({ linkToPdfFile }: IPDFViewer) => {
    const {
        error,
        loaded,
        isLoading,
        isOpen,
        currentNumPage,
        containerWidth,
        numPages,
        scale,
        fileName,
        containerRef,
        setLoaded,
        onDocumentLoadSuccess,
        setCurrentNumPage,
        openCloseMenuSidebar,
        rescale,
        onLoadStart,
        onLoadError,
    } = usePdfViewer(linkToPdfFile);

    return (
        <Flex vertical className="PDF">
            <PDFViewerHeader
                error={error}
                loaded={loaded}
                link={linkToPdfFile}
                isLoading={isLoading}
                openCloseMenuSidebar={openCloseMenuSidebar}
                rescale={rescale}
                scale={scale}
                currentNumPage={currentNumPage}
                numPages={numPages}
                fileName={fileName}
                setCurrentNumPage={setCurrentNumPage}
                containerRef={containerRef}
                containerWidth={containerWidth}
            />
            <PDFViewerContent
                setLoaded={setLoaded}
                link={linkToPdfFile}
                isLoading={isLoading}
                scale={scale}
                isOpen={isOpen}
                rescale={rescale}
                numPages={numPages}
                currentNumPage={currentNumPage}
                onDocumentLoadSuccess={onDocumentLoadSuccess}
                containerRef={containerRef}
                containerWidth={containerWidth}
                setCurrentNumPage={setCurrentNumPage}
                onLoadStart={onLoadStart}
                onLoadError={onLoadError}
            />
        </Flex>
    );
};
export default PDFViewer;
