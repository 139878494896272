import { useState } from "react";

interface UseTMTONItemUseActionI {
    onChange: (action: TONItemUseAction | TONItemUseActionCreating) => void;
    onDelete: (id: string | number) => void;
    action: TONItemUseAction | TONItemUseActionCreating;
}
export const useTMTONItemUseAction = ({
    action,
    onDelete,
    onChange,
}: UseTMTONItemUseActionI) => {
    const [entityField, setEntityField] = useState<TEntityField | null>(null);

    const onChangeAction = (key: string, value: any) => {
        onChange({
            ...action,
            [key]: value,
        });
    };

    const onClickDelete = () => {
        onDelete("id" in action ? action.id : action.tmp_id);
    };
    const onChangeType = (type: string) => {
        onChangeAction("type", type);
    };
    const onChangeEntityRowId = (value: number, entityRow: any) => {
        onChangeAction("entity_row_id", value);
    };

    const onChangeEntityUuid = (value: string | null) => {
        onChangeAction("entity_uuid", value);
    };

    const onChangeEntityFieldKey = (value: string, entityField: TEntityField) => {
        setEntityField(entityField);
        onChangeAction("entity_field_key", value);
    };
    const onChangeValue = (value: any) => {
        onChangeAction("value", value);
    };

    const toggleIsCalculation = () => {
        onChangeAction("is_calculation", !action.is_calculation);
    };
    return {
        entityField,
        onClickDelete,
        onChangeType,
        onChangeEntityRowId,
        onChangeEntityFieldKey,
        onChangeValue,
        toggleIsCalculation,
        onChangeAction,
        onChangeEntityUuid,
    };
};
