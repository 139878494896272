import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

const PageNotFound = ({ title }: { title: string }) => {
    const titleArray = title.split("/");
    const decodedSegments = titleArray.map((item) => decodeURIComponent(item));
    const result = decodedSegments.join("/");
    const navigate = useNavigate();
    const backHomeClick = () => navigate("/");
    return (
        <Result
            status="404"
            title="404"
            style={{ padding: 8 }}
            subTitle={`Страница ${result} не найдена.`}
            extra={
                <Button type="primary" onClick={backHomeClick}>
                    Вернуться на главную
                </Button>
            }
        />
    );
};

export default PageNotFound;
