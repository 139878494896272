import * as ALLICONS from "@ant-design/icons";
import AntIcon from "@ant-design/icons";
import { get, upperFirst } from "lodash";

const AntdIcon = (props: any) => {
    const theme = get(props, "theme", "outlined");
    const component = get(
        ALLICONS,
        `${(props.type || "")
            .split("-")
            .map((s: any) => upperFirst(s))
            .join("")}${upperFirst(theme)}`
    );
    if (!component) return <></>;
    return <AntIcon {...props} theme={theme} component={component} />;
};

export default AntdIcon;
