import { entityRowModel } from "@entities/Portal/EntityRow";
import { userModel } from "@entities/Portal/User";
import { useDispatch, useSelector } from "react-redux";

export const useEntityRowsTableWs = (entityId: number) => {
    const user = useSelector(userModel.selectUser);
    const dispatch = useDispatch();

    const onCreate = (entityRow: any) => {
        dispatch(
            entityRowModel.addEntityRowByKey({
                entityRow,
                key: 0,
            })
        );
    };
    const onUpdate = (entityRow: any) => {
        dispatch(
            entityRowModel.updateEntityRowByKey({
                entityRow,
                key: 0,
            })
        );
    };
    const onDelete = (entityRowId: number) => {
        dispatch(
            entityRowModel.deleteEntityRowByKey({
                entityRowId,
                key: 0,
            })
        );
    };

    entityRowModel.useEntityRowsWS(entityId, user, onCreate, onUpdate, onDelete);
};
