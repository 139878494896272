import { useEffect, useState } from "react";
import { getDevices } from "../api";

export const useDevicesList = () => {
    const [devices, setDevices] = useState<TDevice[]>([]);
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [openedDevice, setOpenedDevice] = useState<TDevice | null>(null);

    useEffect(() => {
        window.Echo.channel(`Device`)
            .listen(".create", ({ device }: { device: TDevice }) => {
                setDevices((devices) => [device, ...devices]);
            })
            .listen(".delete", ({ deviceId }: { deviceId: number }) => {
                setDevices((devices) =>
                    devices.filter((device) => device.id !== deviceId)
                );
            })
            .listen(".update", ({ device }: { device: TDevice }) => {
                setDevices((devices) =>
                    devices.map((device_) =>
                        device_.id === device.id ? device : device_
                    )
                );
            });

        return () => {
            window.Echo.leave(`Device`);
        };
    }, []);

    const onCloseOpenedDevice = () => {
        setTimeout(() => {
            setOpenedDevice(null);
        }, 100);
        setIsOpen(false);
    };

    const onRowClick = (device: TDevice) => {
        setOpenedDevice(device);
        setIsOpen(true);
    };

    const loadDevices = () => {
        setLoading(true);
        getDevices()
            .then((response) => {
                setDevices(response.data.data);
            })
            .catch(() => {})
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => {
        loadDevices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return {
        openedDevice,
        devices,
        isOpen,
        loading,
        onRowClick,
        onCloseOpenedDevice,
    };
};
