import { isNumber } from "lodash";
import { useState } from "react";
import { reorder } from "./lib";

interface IUseDragAndDropList<T> {
    items: T[];
    onChangeItems: (items: T[], moveIndex: number, targetIndex: number) => void;
}

export const useDragAndDropList = <T extends object>({
    items,
    onChangeItems,
}: IUseDragAndDropList<T>) => {
    const [currentIndex, setCurrentIndex] = useState<number | null>(null);
    const [sourceIndex, setSourceIndex] = useState<number | null>(null);

    const onDragEnd = (result: any) => {
        const moveIndex = result.source.index;
        if (!result.destination) return;
        const targetIndex = result.destination.index;
        const newItems = [...items];
        onChangeItems(reorder(newItems, moveIndex, targetIndex), moveIndex, targetIndex);
    };
    const _setCurrentIndex = (currentIndex: number | null | undefined) => {
        isNumber(currentIndex) && setCurrentIndex(currentIndex);
    };
    const _setSourceIndex = (sourceIndex: number) => {
        setSourceIndex(sourceIndex);
    };

    const defineLocationPropsOfDraggingItem = (update: any) => {
        _setCurrentIndex(update.destination?.index);
        _setSourceIndex(update.source.index);
    };

    return {
        currentIndex,
        sourceIndex,
        onDragEnd,
        defineLocationPropsOfDraggingItem,
    };
};
