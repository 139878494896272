import { ArrowUpOutlined } from "@ant-design/icons";
import { estimationModel } from "@entities/Portal/Estimations";
import { Button, Flex } from "antd";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { arrowVariants, collapseVariants, textVariants, variants } from "../constants";
import AdditionalEstimationsList from "./AdditionalEstimationsList";

const MotionArrow = motion(ArrowUpOutlined);

const AdditionalEstimations = ({ entityId }: { entityId: number }) => {
    const isCollapsible = useSelector(estimationModel.selectIsCalculateAdditional);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const listRef = useRef<HTMLDivElement | null>(null);
    const onOpenClose = () => {
        listRef.current &&
            listRef.current.scrollTo({
                top: 0,
                //@ts-ignore
                behavior: "instant",
            });
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        if (!isCollapsible) {
            setIsOpen(false);
        }
    }, [isCollapsible]);

    return (
        <motion.div
            variants={collapseVariants}
            initial={false}
            animate={isOpen ? "open" : "closed"}
            style={{ padding: 0, margin: 0 }}
        >
            <motion.div
                style={{
                    width: "100%",
                    display: "flex",
                    background: "white",
                    padding: 0,
                    borderRadius: 8,
                    overflow: "auto",
                    cursor: isCollapsible
                        ? isOpen
                            ? "default"
                            : "pointer"
                        : "not-allowed",
                    scrollbarGutter: "stable both-edges",
                }}
                ref={listRef}
                variants={variants}
                onClick={isCollapsible ? (!isOpen ? onOpenClose : undefined) : undefined}
                animate={isOpen ? "open" : "closed"}
            >
                <Button
                    icon={
                        <MotionArrow
                            variants={arrowVariants}
                            animate={isOpen ? "open" : "closed"}
                        />
                    }
                    style={{
                        height: "100%",
                        position: "sticky",
                        left: 0,
                        top: 0,
                        margin: 0,
                        padding: 0,
                        minHeight: 0,
                        border: 0,
                    }}
                    disabled={!isCollapsible}
                    onClick={onOpenClose}
                    type="text"
                />
                <Flex
                    vertical
                    gap={8}
                    style={{
                        width: "100%",
                        paddingLeft: 8,
                        height: "100%",
                    }}
                >
                    <motion.div
                        style={{
                            height: "fit-content",
                            paddingTop: isOpen ? 4 : 5,
                        }}
                        variants={textVariants}
                        animate={isOpen ? "open" : "closed"}
                        custom={isCollapsible}
                    >
                        Суммирование по полям
                    </motion.div>
                    <AdditionalEstimationsList entityId={entityId} />
                </Flex>
            </motion.div>
        </motion.div>
    );
};

export default AdditionalEstimations;
