import { TMOperations } from "@features/Production/TechMapFeatures/TMOperations";
import { TMTBar } from "@features/Production/TechMapFeatures/TMTBar";
import { StartedTMBar } from "@features/Production/TechMapFeatures/TMTBar";
import { TMOperationProccess } from "@widgets/Production/TMOperationProccess";
import "./ProductionPage.scss";
import { PrinterModule } from "@shared/StickerPrinter";
import { useDispatch, useSelector } from "react-redux";
import { techMapModel } from "@entities/Production/TechMap";
import { TMOCodes } from "@features/Production/TechMapFeatures/TMOCodes";
interface IProductionPage {}

const ProductionPage = ({}: IProductionPage) => {
    const dispatch = useDispatch();

    const tm = useSelector(techMapModel.selectStartedTm);

    const onChangeConnectedState = (state: boolean) => {
        dispatch(techMapModel.setPrinterIsConnected(state));
    };
    return (
        <div className="production-page">
            <div className="production-page__bar">
                <div className="production-page__bar__printer">
                    <PrinterModule onChangeConnectedState={onChangeConnectedState} />
                </div>
                <div className="production-page__bar__executable">
                    <TMTBar />
                </div>
                <div className="production-page__bar__started">
                    <StartedTMBar />
                </div>
            </div>
            <div className="production-page__main">
                <div className="production-page__main__operations">
                    <TMOperations
                        operationPopoverNode={(toWithMo) =>
                            toWithMo.operation &&
                            toWithMo.operation.status === "successful" &&
                            toWithMo.templateOperation?.code_templates?.length && (
                                <TMOCodes operationId={toWithMo.operation.id} tm={tm} />
                            )
                        }
                    />
                </div>
                <TMOperationProccess />
            </div>
        </div>
    );
};

export default ProductionPage;
