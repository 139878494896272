import { ApproveAgreement } from "@features/Portal/BizProc/ApproveAgreement";
import { useBizProcUserActionWS } from "@features/Portal/BizProc/BizProcWebsocket";
import { FillActionForm } from "@features/Portal/BizProc/FillActionForm";
import { BizProcTemplateStartMenu } from "@features/Portal/BizProcTemplate/BizProcTemplateStartMenu";
import { useHeaderLayout } from "@shared/ui/Header";
import {
    ActionsListMobileWidgets,
    ActionsListWidgets,
} from "@widgets/Portal/DashboardWidgets/ActionsListWidget";
import { UserAgreementsList } from "@widgets/Portal/DashboardWidgets/UserAgreementsList";
import "./DashboardPage.scss";
import { useSearchParams } from "react-router-dom";
import { useEntityRowCodeParse } from "@features/Portal/EntityRow/EntityRowCodeParse";

const DashboardPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const bizProcTemplateId = searchParams.get("start-biz-proc-template-id");
    useHeaderLayout({
        title: "Дашбоард",
    });
    useBizProcUserActionWS(true);
    useEntityRowCodeParse();
    const windowWidth = window.innerWidth;
    return (
        <div
            className="dashboard"
            style={windowWidth < 1024 ? { flexDirection: "column" } : {}}
        >
            {windowWidth > 1024 &&
            window.matchMedia("(orientation: landscape)").matches ? (
                <div className="dashboard__actions-lists">
                    <ActionsListWidgets
                        containerStyle={{
                            marginBottom: 10,
                            minHeight: 100,
                            flex: "1 1 50%",
                        }}
                    />

                    <UserAgreementsList
                        containerStyle={{
                            marginBottom: 10,
                            minHeight: 100,
                            flex: "1 1 50%",
                        }}
                    />
                </div>
            ) : (
                <div className="dashboard__actions-lists dashboard__actions-lists--mobile">
                    <ActionsListMobileWidgets />
                </div>
            )}

            <BizProcTemplateStartMenu bizProcTemplateId={bizProcTemplateId} />
            <FillActionForm />
            <ApproveAgreement />
        </div>
    );
};

export default DashboardPage;
