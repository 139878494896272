import { SelectEntityRow } from "@entities/Portal/EntityRow";
import { useTMFilters } from "../model";
import { Select } from "antd";
import { FormItem } from "@shared/ui/Form";

interface ITMFilters {}

const TMFilters = ({}: ITMFilters) => {
    const {
        templates,
        status,
        usersIds,
        statusOptions,
        onChangeUsersIds,
        onChangeStatuses,
    } = useTMFilters();
    return (
        <div style={{ width: "100%", display: "flex" }}>
            <FormItem
                style={{ width: "100%", maxWidth: 500, marginRight: 10 }}
                label="Исполнитель"
            >
                <SelectEntityRow
                    placeholder="Выберите исполнителя"
                    multiple
                    entityInfo={"users"}
                    value={usersIds ?? []}
                    onChange={onChangeUsersIds}
                />
            </FormItem>
            <FormItem style={{ width: "100%", maxWidth: 350 }} label="Статус">
                <Select
                    mode="multiple"
                    style={{ width: "100%" }}
                    placeholder="Статус"
                    options={statusOptions}
                    value={status ?? []}
                    onChange={onChangeStatuses}
                />
            </FormItem>
        </div>
    );
};

export default TMFilters;
