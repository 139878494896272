import { InputWithVariables } from "@entities/Portal/BizProcTemplate";
import { FormInputItem } from "@entities/Portal/Datatype";
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { FormItem } from "@shared/ui/Form";
import { Button, Checkbox, Result, Select, Spin } from "antd";
import { useEditEntityRowActions } from "./UseEditEntityRowActions";

import { ClearOutlined, PlusOutlined } from "@ant-design/icons";

type TParametersValueItem = {
    key: string;
    is_calculation: boolean;
    value: any;
};

interface ICommonCreateEntityRowForm {
    disabled?: boolean;
    templateItem: TBizProcTemplateAction;
    bizProcTemplateId: number;
    entityUuid: string;
    setItem: (item: TBizProcTemplateAction) => void;
}

const CommonCreateEntityRowForm = ({
    disabled,
    templateItem,
    bizProcTemplateId,
    entityUuid,
    setItem,
}: ICommonCreateEntityRowForm) => {
    const {
        error,
        entityFields,
        formLoading,
        choosenChildEntityFieldsKeys,
        childEntityFieldsOptions,
        allFieldsIsSelected,
        onToggleFields,
        onChangeField,
        onChangeEntityFields,
        onChangeCalculation,
        onChangeCalculationInput,
    } = useEditEntityRowActions(entityUuid, templateItem, setItem);

    if (error)
        return (
            <Result
                status="error"
                title="Ошибка загрузки полей сущности"
                subTitle={`Не удалось загрузить поля сущности`}
            />
        );

    if (formLoading)
        return (
            <Spin>
                <div style={{ width: "100%", height: 300 }}></div>
            </Spin>
        );

    return (
        <>
            <FormItem label="Выберите поля дочерней сущности">
                <div style={{ display: "flex", alignContent: "flex-start" }}>
                    <Select
                        disabled={disabled}
                        style={{ width: "100%", marginRight: 10 }}
                        onChange={onChangeEntityFields}
                        mode="multiple"
                        value={choosenChildEntityFieldsKeys}
                        options={childEntityFieldsOptions}
                    />
                    {!formLoading && (
                        <Button
                            type="link"
                            onClick={onToggleFields}
                            icon={
                                allFieldsIsSelected ? <ClearOutlined /> : <PlusOutlined />
                            }
                        />
                    )}
                </div>
            </FormItem>
            {entityFields.length > 0 &&
                templateItem.parameters.values &&
                templateItem.parameters.values?.map(
                    (parametersValueItem: TParametersValueItem) => {
                        const field = entityFields.find((field) => {
                            return field.key === parametersValueItem.key;
                        })!;
                        return (
                            <IndentContainer key={field.key}>
                                <FormItem label={field?.name}>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        {parametersValueItem.is_calculation ? (
                                            <InputWithVariables
                                                disabled={
                                                    disabled ||
                                                    (field.variable_type === "entity" &&
                                                        field.is_unwritable)
                                                }
                                                itemId={bizProcTemplateId}
                                                isTextArea={true}
                                                onChange={(name, value) =>
                                                    onChangeCalculationInput(
                                                        value,
                                                        parametersValueItem.key
                                                    )
                                                }
                                                name="input"
                                                value={parametersValueItem.value}
                                            />
                                        ) : (
                                            <FormInputItem
                                                field={field}
                                                inputId={field.key}
                                                additionalParameters={{
                                                    params: { withCalcValues: 1 },
                                                }}
                                                datatype={
                                                    field.datatype as unknown as string
                                                }
                                                value={parametersValueItem.value}
                                                onlyRead={
                                                    disabled ||
                                                    (field.variable_type === "entity" &&
                                                        field.is_unwritable)
                                                }
                                                onChange={(value) =>
                                                    onChangeField(value, field.key)
                                                }
                                            />
                                        )}
                                        <Checkbox
                                            disabled={
                                                disabled ||
                                                (field.variable_type === "entity" &&
                                                    field.is_unwritable)
                                            }
                                            checked={parametersValueItem.is_calculation}
                                            onChange={() =>
                                                onChangeCalculation(
                                                    !parametersValueItem.is_calculation,
                                                    field.key
                                                )
                                            }
                                        >
                                            Вычисляемое значение по формуле
                                        </Checkbox>
                                    </div>
                                </FormItem>
                            </IndentContainer>
                        );
                    }
                )}
        </>
    );
};

export default CommonCreateEntityRowForm;
