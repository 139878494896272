import { Button } from "antd";
import { useViewPatternCreate } from "../model";
import { PlusOutlined } from "@ant-design/icons";

interface IViewPatternCreateButton {
    entityId: number;
}

const ViewPatternCreateButton = ({ entityId }: IViewPatternCreateButton) => {
    const { onClick, loading } = useViewPatternCreate(entityId);
    return (
        <Button
            style={{
                position: "sticky",
                bottom: 0,
                left: 0,
                width: "100%",
                background: "white",
            }}
            type="link"
            onClick={onClick}
            icon={<PlusOutlined />}
            loading={loading}
        >
            Создать
        </Button>
    );
};

export default ViewPatternCreateButton;
