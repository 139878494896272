import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { useTMTONItemUseActions } from "../../model";
import TMTONItemUseAction from "./TMTONItemUseAction";
import { Button } from "antd";

interface ITMTONItemUseActions {
    onChange: (actions: (TONItemUseAction | TONItemUseActionCreating)[]) => void;
    actions: (TONItemUseAction | TONItemUseActionCreating)[];
    entityUuid?: string | null;
    entityRowId?: number | null;
}

const TMTONItemUseActions = ({
    actions,
    entityUuid,
    entityRowId,
    onChange,
}: ITMTONItemUseActions) => {
    const { createNew, onDelete, onChangeAction } = useTMTONItemUseActions({
        actions,
        entityUuid,
        entityRowId,
        onChange,
    });
    return (
        <div style={{ display: "flex", width: "100%", flexDirection: "column" }}>
            <span style={{ textWrap: "nowrap" }}>
                Действия при завершении тех. карты /<br /> операции
            </span>
            <Button style={{ marginBottom: 10 }} onClick={createNew}>
                Создать
            </Button>
            {actions.map((action) => {
                return (
                    <IndentContainer>
                        <TMTONItemUseAction
                            action={action}
                            onDelete={onDelete}
                            onChange={onChangeAction}
                        />
                    </IndentContainer>
                );
            })}
        </div>
    );
};

export default TMTONItemUseActions;
