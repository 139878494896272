import { EntityRowItem } from "@entities/Portal/EntityRow";
import { TechMapSpentTime } from "@features/Production/TechMapFeatures/TMOperations";
import { TMTableStatus } from "@features/Production/TechMapFeatures/TMTable";
import { FormItem } from "@shared/ui/Form";

interface ITMFullInfoMain {
    techMap: TTechMapStarted;
}

const TMFullInfoMain = ({ techMap }: ITMFullInfoMain) => {
    return (
        <div>
            <FormItem label="Спецификация">
                <>{techMap?.template?.name ?? "Не найдено"}</>
            </FormItem>
            <FormItem label="Статус">
                <TMTableStatus status={techMap?.status} />
            </FormItem>
            <FormItem label="Затраченное время">
                {techMap ? (
                    <TechMapSpentTime
                        isPaused={techMap.status !== "in_progress" || techMap.is_paused}
                        justifyContent="flex-start"
                        spentTime={techMap.spent_time}
                    />
                ) : (
                    <>-</>
                )}
            </FormItem>
            <FormItem label="Исполнитель">
                {techMap.user ? (
                    <EntityRowItem
                        table="users"
                        name={techMap.user?.name}
                        id={techMap.user?.id}
                    />
                ) : (
                    <>Не найден id={techMap.user_id}</>
                )}
            </FormItem>
            <FormItem label="Согласующий">
                {techMap.reviewers?.[0] ? (
                    <EntityRowItem
                        table="users"
                        name={techMap.reviewers?.[0]?.name}
                        id={techMap.reviewers?.[0]?.id}
                    />
                ) : (
                    <>-</>
                )}
            </FormItem>
        </div>
    );
};

export default TMFullInfoMain;
