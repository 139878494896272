import { getEntityFieldsByParams } from "@entities/Portal/EntityField/api";
import { getParametersForRequest } from "@entities/Portal/EntityRow";

export const getCodeType = (entity_uuid: string, field_key: string) =>
    new Promise<TPrinterCodeItemType>((resolve, reject) => {
        const parameters = getParametersForRequest([
            {
                key: "entity_uuid",
                value: entity_uuid,
                condition: "=",
            },
            {
                key: "key",
                value: field_key,
                condition: "=",
            },
        ]);
        getEntityFieldsByParams({ filter: parameters })
            .then((response) => {
                const strokeCodeType = response.data?.data[0]
                    ?.datatype as TPrinterCodeItemType;
                if (strokeCodeType) resolve(strokeCodeType);
                else throw Error();
            })
            .catch((e) => reject(e));
    });
