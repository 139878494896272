import html2canvas from "html2canvas";

export const useCode = (type: "barcode" | "qrcode", value: string | null | undefined) => {
    const download = () => {
        if (!value) return;
        const element = document.getElementById(`${type}-${value}`);
        if (!element) return;
        html2canvas(element).then(function (canvas) {
            const url = canvas.toDataURL();
            const a = document.createElement("a");
            a.download = `${type}-${value}.jpg`;
            a.href = url;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        });

        // const canvas = document
        //     .getElementById(`${type}-${value}`)
        //     ?.querySelector<HTMLCanvasElement>("canvas");

        // if (canvas) {
        //     const url = canvas.toDataURL();
        //     const a = document.createElement("a");
        //     a.download = `${type}-${value}.jpg`;
        //     a.href = url;
        //     document.body.appendChild(a);
        //     a.click();
        //     document.body.removeChild(a);
        // }
    };
    return {
        download,
    };
};
