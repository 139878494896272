import { bizProcTemplateModel } from "@entities/Portal/BizProcTemplate";
import React from "react";
import { useSelector } from "react-redux";
import { BizProcActionAddButton } from "../BizProcActionAddButton";
import BizProcTemplateItemCopyPasteButtons from "./BizProcTemplateItemCopyPasteButtons";

interface IBizProcTemplateActionAddButtonContainer {
    isBizProcNotAllowAddAfter?: boolean;
    isAllowSelfChild: boolean;
    templateItem: TBizProcTemplateAction;
    parentItem: TBizProcTemplateAction;
    onAdd: (item: TBizProcTemplateAction) => void;
    disabled?: boolean;
    passParentKey?: boolean;
    isButtonForCreateOnlyParallelBranch: boolean;
    pasteCopiedBlockOfActions: (
        value: TBizProcTemplateAction,
        passParentKey?: boolean
    ) => void;
    isFixedAction?: boolean;
    onDrop: (
        event: React.DragEvent<HTMLDivElement>,
        templateItem: TBizProcTemplateAction,
        pasteCopiedBlockOfActions: (
            item: TBizProcTemplateAction,
            passParentKey?: boolean
        ) => void,
        isCopyingMovingMode: boolean,
        passParentKey?: boolean
    ) => void;
}

const BizProcTemplateActionAddButtonContainer = ({
    isBizProcNotAllowAddAfter,
    isAllowSelfChild,
    templateItem,
    parentItem,
    onAdd,
    passParentKey,
    disabled,
    isButtonForCreateOnlyParallelBranch,
    pasteCopiedBlockOfActions,
    isFixedAction,
    onDrop,
}: IBizProcTemplateActionAddButtonContainer) => {
    const isCopyingMovingMode = useSelector(
        bizProcTemplateModel.selectIsCopyingMovingMode
    );
    const onDrop_ = (event: React.DragEvent<HTMLDivElement>) => {
        onDrop(
            event,
            templateItem,
            pasteCopiedBlockOfActions,
            isCopyingMovingMode,
            isAllowSelfChild ? passParentKey : true
        );
    };

    const onDragOver_ = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = isButtonForCreateOnlyParallelBranch
            ? "none"
            : isCopyingMovingMode
              ? "copy"
              : "move";
    };

    return (
        <div
            style={{ position: "relative" }}
            className="biz-proc-action-add-button"
            onDrop={onDrop_}
            onDragOver={onDragOver_}
        >
            {!isBizProcNotAllowAddAfter && (
                <div>
                    {!isButtonForCreateOnlyParallelBranch && (
                        <BizProcTemplateItemCopyPasteButtons
                            type="paste"
                            passParentKey={isAllowSelfChild ? passParentKey : true}
                            templateItem={templateItem}
                            pasteCopiedBlockOfActions={pasteCopiedBlockOfActions}
                        />
                    )}
                    <BizProcActionAddButton
                        disabled={disabled}
                        parentItem={isAllowSelfChild ? templateItem : parentItem}
                        isFixedAction={isFixedAction}
                        templateItemAction={templateItem.action}
                        onAdd={onAdd}
                    />
                </div>
            )}
        </div>
    );
};

export default BizProcTemplateActionAddButtonContainer;
