import { UnorderedListOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

interface INavigateToEntityRows {
    entityTableName: string;
}

const NavigateToEntityRows = ({ entityTableName }: INavigateToEntityRows) => {
    const navigate = useNavigate();
    const onClickToTemplates = () => {
        navigate(`erp/${entityTableName}`);
    };
    //delete
    return (
        <Button
            onClick={onClickToTemplates}
            type="link"
            icon={<UnorderedListOutlined />}
        />
    );
};

export default NavigateToEntityRows;
