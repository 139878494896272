import { useDispatch, useSelector } from "react-redux";
import { selectLoadBizProcFormsIds } from "./BizProcSelectors";
import { useEffect } from "react";
import { loadBizProcsFormsThunk } from "./BizProcThunk";

export const usePostLoadForms = () => {
    const loadBizProcFormsIds = useSelector(selectLoadBizProcFormsIds);
    const dispatch = useDispatch<AppDispatch>();
    const bizProcTemplateId = loadBizProcFormsIds?.[0];

    useEffect(() => {
        if (bizProcTemplateId) {
            dispatch(loadBizProcsFormsThunk({ add: true, bizProcTemplateId }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bizProcTemplateId]);
};
