import {
    capabilitiesRolesConstants,
    CapabilitiesRolesTypeMultiSelectInput,
} from "@entities/Portal/CapabilitiesRoles";
import { FormItem } from "@shared/ui/Form";
import { Select } from "antd";
import React from "react";
import { getOptionsByType } from "../lib";
import { useCreateCapabilitiesRolesInput } from "../model";

interface ICreateCapabilitiesRolesInput {
    newCapabilityEntityRowByRole: TCapabilitiesRolesRow;
    extraFormItem: (
        entityRow: TCapabilitiesRolesRow,
        value: string | null,
        onChangeRow: (capType: TCapType, value: any) => void
    ) => React.ReactNode;
    typeLabel: string;
    type: TCapType;
    isMultiSelect: boolean | undefined;
    onChangeCapability: (
        value: any,
        type: TCapType,
        isMultiSelect: boolean | undefined
    ) => void;
    entityFieldsOptions: {
        label: string;
        value: string;
    }[];
    isLoading: boolean;
}

const CreateCapabilitiesRolesInput = ({
    newCapabilityEntityRowByRole,
    extraFormItem,
    typeLabel,
    type,
    isMultiSelect,
    onChangeCapability,
    entityFieldsOptions,
    isLoading,
}: ICreateCapabilitiesRolesInput) => {
    const { copyValue, pasteValue } = useCreateCapabilitiesRolesInput(
        newCapabilityEntityRowByRole,
        type,
        isMultiSelect,
        onChangeCapability
    );

    return type !== "constraints" ? (
        <FormItem label={typeLabel}>
            {isMultiSelect ? (
                <CapabilitiesRolesTypeMultiSelectInput
                    emptyValue={type === "hidden_fields" ? "-" : "Все"}
                    isFocused={true}
                    additionalOptions={getOptionsByType(type, entityFieldsOptions)}
                    bordered={false}
                    value={newCapabilityEntityRowByRole[type] as string}
                    onChange={(value) => onChangeCapability(value, type, isMultiSelect)}
                    isDefaultOpen={false}
                    isLoading={isLoading}
                    copyValue={copyValue}
                    pasteValue={pasteValue}
                />
            ) : (
                <Select
                    style={{ width: "100%" }}
                    options={capabilitiesRolesConstants.options}
                    bordered={true}
                    value={newCapabilityEntityRowByRole[type]}
                    loading={isLoading}
                    onChange={(value) => onChangeCapability(value, type, isMultiSelect)}
                />
            )}
        </FormItem>
    ) : (
        <FormItem label="Ограничения">
            <>
                {extraFormItem(
                    newCapabilityEntityRowByRole,
                    newCapabilityEntityRowByRole.constraints,
                    (capType, value) => onChangeCapability(value, capType, false)
                )}
            </>
        </FormItem>
    );
};

export default CreateCapabilitiesRolesInput;
