import {
    capabilitiesRolesConstants,
    capabilitiesRolesModel,
} from "@entities/Portal/CapabilitiesRoles";
import { useLoadEntities } from "@entities/Portal/Entity/model";
import { UserRoles } from "@features/Portal/UserRoles";
import { CapabilitiesRolesTableWithCreating } from "@widgets/Portal/CapabilitiesRoles";
import { Flex, Typography } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./CapabilitiesEditorPage.scss";

const CapabilitiesEditorPage = () => {
    const [role, setRole] = useState<TUserRole>();
    const userRoles = useSelector(capabilitiesRolesModel.selectUserRoles);
    const { entities } = useLoadEntities(true);
    const entityUserRoles = entities.find(
        (entity) => entity.uuid === capabilitiesRolesConstants.entityRolesUuid
    );
    const entityCapabilitiesRoles = entities.find(
        (entity) => entity.uuid === capabilitiesRolesConstants.entityCapabilitiesRolesUuid
    );

    useEffect(() => {
        if (userRoles.length > 0) {
            if (role) {
                const foundRole = userRoles.find((userRole) => userRole.id === role.id);
                if (!foundRole) {
                    setRole(undefined);
                    return;
                }
                setRole(foundRole);
            } else {
                const lastUsedUserRoleId = localStorage.getItem(
                    "capabilities_editor_role_id"
                );
                if (lastUsedUserRoleId) {
                    const foundRole = userRoles.find(
                        (userRole) => userRole.id === +lastUsedUserRoleId
                    );
                    if (foundRole) setRole(foundRole);
                    if (!foundRole)
                        localStorage.removeItem("capabilities_editor_role_id");
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userRoles]);

    return (
        <>
            <Flex vertical className="capabilities-editor-page">
                <Flex className="capabilities-editor-page__container">
                    <UserRoles
                        userRolesEntityId={entityUserRoles?.id}
                        setRole={setRole}
                    />
                    {entityCapabilitiesRoles ? (
                        <CapabilitiesRolesTableWithCreating
                            entityCapabilitiesRolesId={entityCapabilitiesRoles.id}
                            role={role}
                        />
                    ) : (
                        <Typography.Text>
                            Нет доступа к сущности "Права ролей"
                        </Typography.Text>
                    )}
                </Flex>
            </Flex>
        </>
    );
};

export default CapabilitiesEditorPage;
