import { $api } from "@shared/api/api";
const route = "/api/entity";

export const getUserRoles = (id: number) => {
    return $api.get<TResponse<{ data: TUserRole[] }>>(`${route}/${id}/values`);
};

export const getCapabilitiesRoles = ({
    id,
    page,
    perPage,
    filter,
}: TGetCapabilitiesRolesParams) => {
    return $api.post<
        TResponse<{ data: TCapabilitiesRolesTableData; [key: string]: any }>
    >(`${route}/${id}/values`, {
        page,
        per_page: perPage,
        filter,
    });
};

export const saveCapabilitiesRoles = ({ entityId, items }: TSaveEntityRowsParams) => {
    return $api.post<TResponse<any>>(`${route}/${entityId}/values/add`, {
        data: items,
    });
};

export const deleteCapabilitiesRoles = (entityId: number, entityRowId: number) => {
    return $api.delete<TResponse<any>>(`${route}/${entityId}/row/${entityRowId}`);
};
export const deleteUserRole = (entityId: number, entityRowId: number) => {
    return $api.delete<TResponse<any>>(`${route}/${entityId}/row/${entityRowId}`);
};
export const updateUserRoles = (entityId: number, updatedUserRoles: TUserRole[]) => {
    return $api.post<TResponse<TUserRole[]>>(`${route}/${entityId}/values/add`, {
        data: updatedUserRoles,
    });
};
