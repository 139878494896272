import { techMapModel } from "@entities/Production/TechMap";
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

export const useTMTBar = () => {
    const { executableTmts, loading } = techMapModel.useLoadExecutableTmts();
    const startingLoading = useSelector(techMapModel.selectStartingLoading);
    const startedTm = useSelector(techMapModel.selectStartedTm);
    const dispatch = useDispatch<AppDispatch>();
    const startingLoadingItemsIds = startingLoading ? [startingLoading] : [];
    const activeItemsIds = startedTm?.template?.id ? [startedTm.template.id] : [];
    const [searchValue, setSearchValue] = useState<string>("");
    const options = useMemo(() => {
        return executableTmts.map((item) => ({
            value: item.id,
            label: item.name,
            item,
        }));
    }, [executableTmts]);
    const onSearch = (value: string) => {
        setSearchValue(value ?? "");
    };
    const onClickItem = (item: TTechMapTemplateExecuble) => {
        if (startingLoading) return;
        dispatch(techMapModel.startTmtThunk({ tmtId: item.id }));
    };

    return {
        options: options.filter((option) =>
            option.label.toLowerCase().includes(searchValue.toLowerCase())
        ),
        loading,
        startingLoadingItemsIds,
        activeItemsIds,
        searchValue,
        onClickItem,
        onSearch,
    };
};
