import { useWindowResize } from "@shared/lib/UseWindowResize";
import { Flex, Popover } from "antd";
import { useStagesEntityRow } from "../model";
import StageEntityRowFinalStageModal from "./StageEntityRowFinalStageModal";
import "./StagesEntityRow.scss";
import StagesEntityRowSkeleton from "./StagesEntityRowSkeleton";
import StagesListEntityRow from "./StagesListEntityRow";
interface IStagesEntityRow {
    categoryId: number;
    entityId: number;
    withMargins?: boolean;
    onlyRead?: boolean;
    entityRow: any;
    onChangeEntityRow?: (entityRow: any) => void;
}

const StagesEntityRow = ({
    categoryId,
    entityId,
    withMargins,
    entityRow,
    onlyRead,
    onChangeEntityRow,
}: IStagesEntityRow) => {
    const {
        loading,
        stages,
        finalStages,
        notAllowed,
        currentStage,
        hoveredIndex,
        hoveredStage,
        submitLoading,
        currentStageIndex,
        isOpen,
        onEnter,
        onClickStage,
        onClickEndStage,
        onLeave,
        onClose,
    } = useStagesEntityRow({
        categoryId,
        entityId,
        entityRow,
        onlyRead,
        onChangeEntityRow,
    });
    const [isCollapseStages] = useWindowResize([900]);
    if (notAllowed) return <></>;
    return (
        <div>
            <StageEntityRowFinalStageModal
                isOpen={isOpen}
                onClose={onClose}
                finalStages={finalStages}
                onClickStage={onClickStage}
            />
            <Flex
                className="stages-entity-row"
                style={{
                    margin: withMargins ? "15px 0px" : undefined,
                }}
            >
                {loading ? (
                    <StagesEntityRowSkeleton />
                ) : (
                    <Popover
                        overlayClassName="stages-popover"
                        overlayInnerStyle={{
                            width: "90%",
                        }}
                        overlayStyle={{
                            width: "90%",
                            display: isCollapseStages ? "none" : undefined,
                        }}
                        placement="bottomLeft"
                        content={
                            !isCollapseStages && (
                                <StagesListEntityRow
                                    listDirection="column"
                                    stages={stages}
                                    onlyRead={onlyRead}
                                    onEnter={onEnter}
                                    onLeave={onLeave}
                                    submitLoading={submitLoading}
                                    onClickEndStage={onClickEndStage}
                                    onClickStage={onClickStage}
                                    hoveredIndex={hoveredIndex}
                                    hoveredStage={hoveredStage}
                                    currentStage={currentStage}
                                    currentStageIndex={currentStageIndex}
                                    showOnlCurrentStage={false}
                                />
                            )
                        }
                    >
                        <>
                            <StagesListEntityRow
                                listDirection="row"
                                stages={stages}
                                onlyRead={onlyRead}
                                onEnter={onEnter}
                                onLeave={onLeave}
                                submitLoading={submitLoading}
                                onClickEndStage={onClickEndStage}
                                onClickStage={onClickStage}
                                hoveredIndex={hoveredIndex}
                                hoveredStage={hoveredStage}
                                currentStage={currentStage}
                                currentStageIndex={currentStageIndex}
                                showOnlCurrentStage={!isCollapseStages}
                            />
                        </>
                    </Popover>
                )}
            </Flex>
        </div>
    );
};

export default StagesEntityRow;
