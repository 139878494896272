export const operationBgColor = {
    in_progress: "#d9d3ff",
    failed: "#ff4d4f",
    successful: "rgb(171 230 142)",
    stopped: "#fde910",
    paused: "#e6e6fa",
};
export const tmStatusNames = {
    in_progress: "В процессе",
    partly_rejected: "Частично согласован",
    failed: "Ошибка",
    approved: "Согласован",
    rejected: "Отклонён",
    waiting: "Ожидает согласования",
    stopped: "Остановлен",
    paused: "Пауза",
};
export const tmStatusTagColor = {
    paused: "magenta",
    partly_rejected: "green",
    in_progress: "processing",
    failed: "error",
    approved: "success",
    rejected: "warning",
    waiting: "default",
    stopped: "default",
};

export const groupOptions = [
    {
        label: "Исполнитель",
        value: "user",
    },
    {
        label: "Маршрутный лист",
        value: "template",
    },
    {
        label: "Статус",
        value: "status",
    },
];

export const LOCATION_TM_PATTERN = "/production-stats";
export const LOCATION_TM_ID_INDEX = 1;
export const LOCATION_TM_NAME_INDEX = 0;

export const TM_STARTED_STORAGE_KEY = "started_tm_id";
