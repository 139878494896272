import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectGetOneLoading, selectTechMapTemplate } from "./TechMapTemplateSelectors";
import { setTechMapTemplate } from "../TechMapTemplateSlice";
import { loadTechMapTemplateThunk } from "./TechMapTemplateThunk";
import { techMapTemplateMock } from "../../constants";

export const useTechMapTemplateItemLoadorMock = (techMapTemplateId: number | null) => {
    const loading = useSelector((state: RootState) =>
        selectGetOneLoading(state, techMapTemplateId)
    );

    const techMapTemplate = useSelector(selectTechMapTemplate);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (techMapTemplateId) {
            dispatch(loadTechMapTemplateThunk({ techMapTemplateId }));
        } else {
            dispatch(setTechMapTemplate(techMapTemplateMock));
        }
        return () => {
            dispatch(setTechMapTemplate(null));
        };
    }, [dispatch, techMapTemplateId]);

    return {
        techMapTemplate,
        loading,
    };
};
