import { $api } from "@shared/api/api";
const route = "/api/entity";

export const getEntityRowsByEntityId = (entityId: number) => {
    return $api.get(`${route}/${entityId}/values`);
};

export const getEntityRowsWithPage = ({
    id,
    page,
    perPage,
    groupKey,
    filter,
    textFilter,
}: TGetEntityRowsParamsWithPage) => {
    const params: object = {
        page,
        per_page: perPage,
        filter,
        textFilter,
        groupBy: groupKey,
    };

    if (groupKey)
        return $api.get(`${route}/${id}/values/grouped`, {
            params,
        });
    return $api.get(`${route}/${id}/values`, {
        params,
    });
};

export const getEntityRowsByDates = ({ id, filter }: TGetEntityRowsParamsByDates) => {
    return $api.get(`${route}/${id}/values`, {
        params: { filter },
    });
};
export const getEntityRow = (id: number, entityId: number) => {
    return $api.get<TResponse<any>>(`${route}/${entityId}/values/${id}`);
};

export const getEntityRowsFiltered = (
    id: number,
    params?: TParams & { textFilter?: string | null }
) => {
    return $api.post<TResponse<any>>(`${route}/${id}/values`, params);
};

export const saveEntityRow = ({ entityId, entityRow }: TSaveEntityRowParams) => {
    return $api.post<TResponse<any>>(`${route}/${entityId}/values/add`, {
        data: [entityRow],
    });
};
export const saveEntityRows = ({ entityId, items }: TSaveEntityRowsParams) => {
    return $api.post<TResponse<any>>(`${route}/${entityId}/values/add`, {
        data: items,
    });
};
export const deleteEntityRow = (entityId: number, entityRowId: number) => {
    return $api.delete<TResponse<any>>(`${route}/${entityId}/row/${entityRowId}`);
};
export const deleteEntityRows = (entityId: number, entityRowIds: number[]) => {
    return $api.delete<TResponse<any>>(`${route}/${entityId}/row`, {
        data: { rowIds: entityRowIds },
    });
};

export const getEntityRowsByEntityField = (
    id: number,
    entityFieldId: number | undefined,
    filter?: any,
    paginationConfig?: TPaginationConfig,
    textFilterValue?: string | undefined
) => {
    return $api.post(`${route}/${id}/kanban/${entityFieldId}`, {
        lastIds: {
            ...paginationConfig,
        },
        filter,
        perPage: 20,
        textFilter: textFilterValue,
    });
};

export const getEntityRowByCode = (code: string) => {
    return $api.get<TResponse<TEntityRowCodeRes>>(`/api/code`, {
        params: { value: code },
    });
};
