const XLSIcon = () => {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 303.188 303.188"
            xmlSpace="preserve"
            width="40px"
            style={{ marginTop: 12 }}
            height="40px"
            fill="#000000"
        >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"/>
            <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <g id="SVGRepo_iconCarrier">
                <g>
                    <polygon
                        style={{ fill: "#E8E8E8" }}
                        points="219.821,0 32.842,0 32.842,303.188 270.346,303.188 270.346,50.525"
                    />
                    <g>
                        <rect
                            x="90.902"
                            y="61.704"
                            style={{ fill: "#007934" }}
                            width="119.89"
                            height="119.89"
                        />
                        <rect
                            x="101.303"
                            y="72.105"
                            style={{ fill: "#FFFFFF" }}
                            width="99.088"
                            height="99.088"
                        />
                        <polygon
                            style={{ fill: "#007934" }}
                            points="192.62,137.423 162.041,137.423 171.073,148.703 162.041,159.982 192.62,159.982 183.588,148.703"
                        />
                        <polygon
                            style={{ fill: "#007934" }}
                            points="183.875,97.886 154.609,97.886 148.122,105.987 141.635,97.886 112.369,97.886 133.489,124.262 112.369,150.638 183.875,150.638 162.755,124.262"
                        />
                        <polygon
                            style={{ fill: "#FFFFFF" }}
                            points="124.911,101.616 120.676,101.616 156.944,146.908 161.178,146.908"
                        />
                    </g>
                    <polygon
                        style={{ fill: "#007934" }}
                        points="227.64,25.263 32.842,25.263 32.842,0 219.821,0"
                    />
                    <g>
                        <path
                            style={{ fill: "#A4A9AD" }}
                            d="M135.998,273.871H121l-9.353-14.997l-9.254,14.997h-14.67l15.917-24.547l-14.965-23.432h14.374 l8.664,14.834l8.336-14.834h14.801l-15.194,24.449L135.998,273.871z"
                        />
                        <path
                            style={{ fill: "#A4A9AD" }}
                            d="M141.38,273.871v-47.979h12.963v37.511h18.477v10.469h-31.44V273.871z"
                        />
                        <path
                            style={{ fill: "#A4A9AD" }}
                            d="M211.872,259.3c0,2.976-0.755,5.617-2.265,7.925c-1.509,2.309-3.687,4.102-6.53,5.382 c-2.845,1.28-6.181,1.92-10.01,1.92c-3.194,0-5.874-0.225-8.04-0.673s-4.42-1.23-6.761-2.346v-11.552 c2.473,1.269,5.043,2.259,7.713,2.97c2.669,0.711,5.119,1.067,7.351,1.067c1.925,0,3.336-0.333,4.233-1.001 c0.897-0.667,1.346-1.526,1.346-2.576c0-0.656-0.181-1.231-0.541-1.723c-0.361-0.492-0.941-0.99-1.739-1.493 c-0.8-0.503-2.927-1.531-6.384-3.085c-3.129-1.422-5.475-2.8-7.039-4.135c-1.564-1.334-2.724-2.866-3.479-4.595 c-0.755-1.728-1.132-3.774-1.132-6.137c0-4.419,1.607-7.865,4.823-10.337c3.217-2.472,7.636-3.708,13.259-3.708 c4.966,0,10.031,1.148,15.194,3.446l-3.971,10.009c-4.485-2.056-8.357-3.085-11.617-3.085c-1.686,0-2.91,0.295-3.676,0.886 c-0.767,0.591-1.148,1.324-1.148,2.199c0,0.941,0.486,1.784,1.46,2.527c0.974,0.744,3.615,2.101,7.926,4.07 c4.135,1.859,7.007,3.856,8.614,5.989C211.068,253.376,211.872,256.063,211.872,259.3z"
                        />
                    </g>
                    <polygon
                        style={{ fill: "#D1D3D3" }}
                        points="219.821,50.525 270.346,50.525 219.821,0"
                    />
                </g>
            </g>
        </svg>
    );
};

export default XLSIcon;
