import { ChangeEvent, useState } from "react";
import { updateDevice, writeCard } from "../api";

export const useDeviceEditForm = (initialDevice: TDevice) => {
    const [device, setDevice] = useState(initialDevice);
    const [userId, setUserId] = useState<number | null>(null);
    const [saveLoading, setSaveLoading] = useState(false);
    const [writeLoading, setWriteLoading] = useState(false);
    const onSaveDevice = () => {
        setSaveLoading(true);
        updateDevice(device)
            .then((response) => {})
            .catch((e) => {})
            .finally(() => {
                setSaveLoading(false);
            });
    };

    const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
        setDevice({
            ...device,
            name: e.target.value,
        });
    };
    const onChangeBizProcTemplate = (
        value: number | null,
        item: TBizProcTemplateItem | null
    ) => {
        setDevice({
            ...device,
            biz_proc_template_id: value,
            biz_proc_template_name: item?.name,
        });
    };

    const writeToCard = () => {
        if (userId) {
            setWriteLoading(true);
            writeCard(device.id, userId)
                .then((response) => {})
                .catch((e) => {})
                .finally(() => {
                    setWriteLoading(false);
                });
        }
    };

    const onChangeUserId = (value: number) => {
        setUserId(value);
    };
    return {
        device,
        saveLoading,
        userId,
        writeLoading,
        onChangeBizProcTemplate,
        onSaveDevice,
        writeToCard,
        onChangeName,
        onChangeUserId,
    };
};
