import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { UploadFile } from "antd/lib";
import React, { useState } from "react";
import { pdfAndOfficeExtensions } from "./constants";
import "./FileItemRender.scss";
import { getFileExtenstion } from "./lib";

interface IFileItemRender {
    onlyRead?: boolean;
    smallFormat?: boolean;
    originNode: React.ReactNode;
    isDisabledTableListHoverActions?: boolean;
    actions: { remove: () => void; download: () => void; preview: () => void };
    file: UploadFile<any>;
    onPreviewFile: (file: UploadFile<any>) => void;
    handlePreview: (file: UploadFile<any>) => void;
}

const FileItemRender = ({
    onlyRead,
    smallFormat,
    originNode,
    isDisabledTableListHoverActions,
    actions,
    file,
    onPreviewFile,
    handlePreview,
}: IFileItemRender) => {
    const [hovered, setHovered] = useState(false);
    if (!onlyRead) return originNode;

    if (smallFormat)
        return (
            <div
                style={{
                    display: "flex",
                    height: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                    width: "100%",
                    ...(isDisabledTableListHoverActions === true
                        ? { pointerEvents: "none" }
                        : {}),
                }}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <>
                    <span
                        className="link"
                        style={{
                            marginRight: 10,
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            textWrap: "nowrap",
                        }}
                        onClick={actions.preview}
                    >
                        {file.name}
                    </span>
                    <Button
                        type="text"
                        size="small"
                        style={{
                            padding: 3,
                            color: "#919090",
                            margin: 0,
                            minHeight: 20,
                        }}
                    >
                        <DownloadOutlined onClick={actions.download} />
                    </Button>
                </>
            </div>
        );
    return (
        <>
            <div
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                style={{
                    height: "100%",
                    cursor: "pointer",
                    width: "100%",
                    textOverflow: "ellipsis",
                    position: "relative",
                    overflow: "hidden",
                    ...(isDisabledTableListHoverActions ? { pointerEvents: "none" } : {}),
                }}
                onClick={(e) => {
                    e.stopPropagation();
                    const fileExt = getFileExtenstion(file.name);
                    return pdfAndOfficeExtensions.includes(fileExt)
                        ? onPreviewFile(file)
                        : handlePreview(file);
                }}
            >
                {originNode}
                {hovered && (
                    <div
                        style={{
                            position: "absolute",
                            top: 10,
                            right: 0,
                            zIndex: 100,
                            padding: "0px 10px",
                        }}
                    >
                        <span
                            onClick={(e) => {
                                e.stopPropagation();
                                actions.download();
                            }}
                            className="file-download link"
                            style={{
                                padding: 5,
                                backgroundColor: "white",
                            }}
                        >
                            <DownloadOutlined />
                        </span>
                    </div>
                )}
            </div>
        </>
    );
};
export default FileItemRender;
