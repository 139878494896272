import { datatypeModel } from "@entities/Portal/Datatype";
import { entityModel } from "@entities/Portal/Entity";
import { setDocumentTitle } from "@shared/lib/document";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

export const useEntityEditor = (entityId: number) => {
    datatypeModel.useLoadDatatypes();
    const { entity, loading } = entityModel.useLoadEntity(entityId, (entity: TEntity) =>
        setDocumentTitle(`${entity.name} (Настройка)`)
    );

    const datatypes = useSelector(datatypeModel.selectDatatypes);
    const [searchParams, setSearchParams] = useSearchParams();
    const activeTab = searchParams.get("tab");
    const onTabClick = (tabKey: string) =>
        setSearchParams((params) => {
            params.set("tab", tabKey);
            return params;
        });

    return { entity, loading, datatypes, onTabClick, activeTab };
};
