import {
    AdmonitionDirectiveDescriptor,
    directivesPlugin,
    linkDialogPlugin,
    linkPlugin,
    listsPlugin,
    quotePlugin,
    tablePlugin,
    thematicBreakPlugin
} from "@mdxeditor/editor";

export const plugins = [
    directivesPlugin({
        directiveDescriptors: [AdmonitionDirectiveDescriptor],
    }),
    listsPlugin(),
    tablePlugin(),
    linkPlugin(),
    linkDialogPlugin(),
    thematicBreakPlugin(),
    quotePlugin(),
];
