import { techMapConstant } from "@entities/Production/TechMap";
import { Tag } from "antd";

interface ITMTableStatus {
    status: TTechMapStatus;
}

const TMTableStatus = ({ status }: ITMTableStatus) => {
    return (
        <Tag
            style={{ fontWeight: 600 }}
            color={techMapConstant.tmStatusTagColor[status ?? "failed"]}
        >
            {techMapConstant.tmStatusNames[status ?? "failed"]}
        </Tag>
    );
};

export default TMTableStatus;
