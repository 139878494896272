import { entityRowModel } from "@entities/Portal/EntityRow";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

export const useEntityRowCategorySelect = (entityId: number) => {
    const currentCategory = useSelector(entityRowModel.selectCurrentCategory);
    const dispatch = useDispatch();
    useEffect(() => {
        const filters = localStorage.getItem(`${entityId}_filters`);
        if (
            JSON.parse(filters ?? "[]").find(
                (item: TFilterValue) => item.key === "category"
            )
        ) {
            dispatch(entityRowModel.setIsDisabledCurrentCategorySelect(true));
        }
        const category = localStorage.getItem(`${entityId}_rows_category`);
        dispatch(
            entityRowModel.setCurrentCategory({
                categoryId: category ? Number(category) : null,
                entityId,
            })
        );
    }, []);
    const onChangeCategory = (value: number) => {
        if (value) {
            localStorage.setItem(`${entityId}_rows_category`, value.toString());
            const oldFields = localStorage.getItem(`${entityId}__saved-fields`);
            const parsedOldFields = JSON.parse(oldFields ?? "[]");
            const newSavedFields = parsedOldFields.map((item: TFilterValue) =>
                item.key === "stage" ? { ...item, value: null } : item
            );
            localStorage.setItem(
                `${entityId}__saved-fields`,
                JSON.stringify(newSavedFields)
            );
            const oldFilters = localStorage.getItem(`${entityId}_filters`);
            const parsedOldFIlters = JSON.parse(oldFilters ?? "[]");
            const newFilters = parsedOldFIlters.filter(
                (item: TFilterValue) => item.key !== "stage"
            );
            localStorage.setItem(`${entityId}_filters`, JSON.stringify(newFilters));
            localStorage.setItem(`${entityId}_rows_category`, value.toString());
        } else localStorage.removeItem(`${entityId}_rows_category`);
        dispatch(
            entityRowModel.setCurrentCategory({
                categoryId: value,
                entityId,
            })
        );
    };

    return { currentCategory, onChangeCategory };
};
