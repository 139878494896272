import { useDispatch, useSelector } from "react-redux";
import { selectVariables } from "./VariableSelectors";
import { useEffect } from "react";
import { loadVariablesThunk } from "./VariableThunk";

export const useLoadVariables = (preload?: boolean) => {
    const variables = useSelector(selectVariables);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (preload) {
            loadVariables();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preload]);

    const loadVariables = () => {
        dispatch(loadVariablesThunk());
    };
    return {
        variables,
        loadVariables,
    };
};
