interface IUseEntityFieldItem {
    entityField: TEntityField;
    onEdit: (item: TEntityField) => void;
    onDelete: (item: TEntityField) => void;
}

export const useEntityFieldItem = ({
    entityField,
    onEdit,
    onDelete,
}: IUseEntityFieldItem) => {
    const key = entityField.key;
    const name = entityField.name;
    const datatype = entityField.datatype;
    console.log("entityField", entityField);
    const ruleOnCreate = !!entityField.on_create_default;
    const ruleOnUpdate = !!entityField.on_create_default;
    const rules: string[] = [];
    if (ruleOnCreate) {
        rules.push("При создании");
    }
    if (ruleOnUpdate) {
        rules.push("При обновлении");
    }
    const isName = !!entityField.is_name;
    const isDescription = !!entityField.is_description;
    const onClickEdit = () => {
        onEdit(entityField);
    };

    const onDeleteClick = () => {
        onDelete(entityField);
    };

    return {
        name,
        key,
        datatype,
        isDescription,
        isName,
        rules,
        onDeleteClick,
        onClickEdit,
    };
};
