import {
    InfoCircleOutlined,
    MailOutlined,
    PhoneOutlined,
    UserOutlined,
} from "@ant-design/icons";
import { Divider, theme } from "antd";
import { CSSProperties, Fragment, ReactNode } from "react";
import "./UserProfile.scss";

interface IUserProfileInfo {
    actions?: ReactNode;
    user: TUser;
    containerStyle?: any;
    noAvatar?: boolean;
    additionalUserData?: {
        label: string;
        value: ReactNode;
        icon?: (style: CSSProperties) => ReactNode;
    }[];
}

const UserProfileInfo = ({
    actions,
    user,
    containerStyle,
    additionalUserData,
    noAvatar,
}: IUserProfileInfo) => {
    const { token } = theme.useToken();

    const { name, phone, email } = user;
    const userData = [
        { label: "Имя", value: name },
        { label: "Телефон", value: phone },
        { label: "Почта", value: email },

        ...(additionalUserData ?? []),
    ];

    const getInfoTypeIcon = (type: string, style: CSSProperties) => {
        switch (type) {
            case "Почта":
                return <MailOutlined style={style} />;
            case "Телефон":
                return <PhoneOutlined style={style} />;
            case "Имя":
                return <UserOutlined style={style} />;
            default:
                return <InfoCircleOutlined style={style} />;
        }
    };

    const iconStyle = {
        background: token.colorPrimary,
        width: 32,
        height: 32,
        marginRight: 15,
        borderRadius: "50%",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: 18,
    };
    const avatarName = name
        ? name
              .toUpperCase()
              .split(" ")
              .map((el: string): string => el[0])
              .join("")
        : "";

    return (
        <div className="user-profile" style={containerStyle}>
            <div className="user-profile-card">
                <div className="user-profile-card__container">
                    {!noAvatar && (
                        <div className="user-profile-card__header">
                            <div className="user-profile-card__avatar">{avatarName}</div>
                            <Divider style={{ marginTop: 16 }} />
                        </div>
                    )}
                    <div className="user-profile-card__body">
                        {userData.map((userDataItem, index) => {
                            if (userDataItem.value) {
                                return (
                                    <div
                                        key={index}
                                        className="user-profile-card__info-item"
                                    >
                                        <div className="user-profile-card__info-item-container">
                                            <div className="user-profile-card__item user-profile-card__item--label">
                                                {userDataItem.icon
                                                    ? userDataItem.icon(iconStyle)
                                                    : getInfoTypeIcon(
                                                          userDataItem.label,
                                                          iconStyle
                                                      )}
                                                {userDataItem.label}
                                            </div>
                                            <div className="user-profile-card__item user-profile-card__item--value">
                                                {userDataItem.value}
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                            return <Fragment key={index}></Fragment>;
                        })}
                    </div>
                    <div className="user-profile-card__actions">{actions}</div>
                </div>
            </div>
        </div>
    );
};

export default UserProfileInfo;
