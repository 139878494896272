import { Typography } from "antd";

interface ILabelText {
    text: string;
    fontSize?: number;
}
const LabelText = ({ text, fontSize }: ILabelText) => {
    return (
        <Typography.Text
            style={{
                fontSize: fontSize ?? 15,
                fontWeight: 600,
            }}
        >
            {text}
        </Typography.Text>
    );
};

export default LabelText;
