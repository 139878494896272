import { isNumber } from "lodash";
import { useState } from "react";
import { prepareItemChildren, rewriteUniqProps } from "../lib";

export const useBizProcUpdateAction = (
    isAllowSelfChild: boolean,
    templateItem: TBizProcTemplateAction,
    onChangeTemplateItem: (templateItem: TBizProcTemplateAction) => void,
    onAddTemplateItem: (
        templateItem: TBizProcTemplateAction,
        newTemplateItem: TBizProcTemplateAction
    ) => void,
    onDeleteTemplateItem: (templateItem: TBizProcTemplateAction) => void,
    parentItem?: TBizProcTemplateAction
) => {
    const [isEditing, setIsEditing] = useState(false);
    const onChangeTemplateItem_ = (updatedTemplateItem: TBizProcTemplateAction) => {
        onChangeTemplateItem({
            ...templateItem,
            children: prepareItemChildren(
                templateItem.children.map((item) =>
                    item.key === updatedTemplateItem.key ? updatedTemplateItem : item
                )
            ),
        });
    };
    const onUpdateTemplateItem = (updatedTemplateItem: TBizProcTemplateAction) => {
        onChangeTemplateItem({
            ...updatedTemplateItem,
            children: prepareItemChildren(templateItem.children),
        });
        setIsEditing(false);
    };
    const onAddTemplateItem_ = (
        templateItem: TBizProcTemplateAction,
        newTemplateItem: TBizProcTemplateAction
    ) => {
        onAddTemplateItem(templateItem, { ...newTemplateItem });
    };
    const onAddTemplateItemParent = (
        prevTemplateItem: TBizProcTemplateAction,
        newTemplateItem: TBizProcTemplateAction
    ) => {
        const index = templateItem.children.findIndex(
            (item) => item.key === prevTemplateItem.key
        );
        const oldChildren = [...templateItem.children];
        oldChildren.splice(index + 1, 0, { ...newTemplateItem });
        templateItem.children = prepareItemChildren(oldChildren);
        onChangeTemplateItem_({
            ...templateItem,
        });
    };
    const onDeleteTemplateItem_ = (deletedTemplateItem: TBizProcTemplateAction) => {
        onDeleteTemplateItem(deletedTemplateItem);
    };
    const onDeleteTemplateItemParent = (deletedTemplateItem: TBizProcTemplateAction) => {
        onChangeTemplateItem({
            ...templateItem,
            children: prepareItemChildren(
                templateItem.children.filter(
                    (item) => item.key !== deletedTemplateItem.key
                )
            ),
        });
    };

    const addTemplateItemAfter = (newTemplateItem: TBizProcTemplateAction) => {
        isAllowSelfChild
            ? onAddTemplateItemParent(templateItem, newTemplateItem)
            : onAddTemplateItem_(templateItem, newTemplateItem);
    };

    const editAction = () => {
        setIsEditing(true);
    };

    const onAddCopiedBranchInTemplateItem = (
        pasteIndex: "start" | "end" | number,
        newTemplateItem: TBizProcTemplateAction,
        index: number
    ) => {
        if (isNumber(pasteIndex)) {
            const isPasteLeft = pasteIndex > index;
            templateItem.children.splice(isPasteLeft ? pasteIndex : pasteIndex + 1, 0, {
                ...newTemplateItem,
            });
        } else {
            const lengthOfChildren = templateItem.children.length;
            templateItem.children.splice(
                pasteIndex === "start" ? 0 : lengthOfChildren,
                0,
                {
                    ...newTemplateItem,
                }
            );
        }
        onChangeTemplateItem_({
            ...templateItem,
            children: prepareItemChildren(templateItem.children),
        });
    };

    const copyBranchValue = (
        templateItem: TBizProcTemplateAction,
        index: number,
        indexMoveStep: number
    ) => {
        const newTemplate = { ...templateItem };
        if (newTemplate.children[index + indexMoveStep]) {
            onAddCopiedBranchInTemplateItem(
                index + indexMoveStep,
                rewriteUniqProps(
                    {
                        ...newTemplate.children[index],
                        parent_key: newTemplate.key,
                    },
                    0
                ),
                index
            );
        } else {
            if (indexMoveStep < 0) {
                onAddCopiedBranchInTemplateItem(
                    "start",
                    rewriteUniqProps(
                        {
                            ...newTemplate.children[index],
                            next_key: newTemplate.children[index].key,
                            parent_key: newTemplate.key,
                        },
                        0
                    ),
                    index
                );
            } else
                onAddCopiedBranchInTemplateItem(
                    "end",
                    rewriteUniqProps(
                        {
                            ...newTemplate.children[index],
                            parent_key: newTemplate.key,
                        },
                        0
                    ),
                    index
                );
        }
    };

    const pasteCopiedBlockOfActions = (
        value: TBizProcTemplateAction,
        passParentKey?: boolean
    ) => {
        const newValue = rewriteUniqProps(
            {
                ...value,
                next_key: null,
            },
            0
        );
        if (passParentKey) {
            onAddTemplateItem(templateItem, {
                ...newValue,
                parent_key: templateItem.parent_key,
            });
        } else {
            addTemplateItemAfter({ ...newValue, parent_key: templateItem.key });
        }
    };

    return {
        onDeleteTemplateItem_,
        editAction,
        onDeleteTemplateItemParent,
        addTemplateItemAfter,
        onAddTemplateItemParent,
        setIsEditing,
        onUpdateTemplateItem,
        isEditing,
        onChangeTemplateItem_,
        onAddCopiedBranchInTemplateItem,
        copyBranchValue,
        pasteCopiedBlockOfActions,
    };
};
