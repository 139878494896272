import { useHeaderLayout } from "@shared/ui/Header";
import EntitiesPageList from "./EntitiesPageList";

const EntitiesPage = () => {
    useHeaderLayout({
        title: "Сущности",
    });

    return <EntitiesPageList />;
};

export default EntitiesPage;
