import { useRef } from "react";

export const useScroll = () => {
    const elRef = useRef<any>(null);
    const executeScroll = () => {
        elRef.current?.scrollIntoView({ block: "center" });
    };

    return {
        executeScroll,
        elRef,
    };
};
