import { techMapModel } from "@entities/Production/TechMap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

export const useTMNecessaryItemDetach = (operationItemId: number) => {
    const dispatch = useDispatch<AppDispatch>();
    const loading = useSelector((state: RootState) =>
        techMapModel.selectDetachItemLoading(state, operationItemId)
    );
    const onDetach = () => {
        dispatch(
            techMapModel.detachItemThunk({
                id: operationItemId,
            })
        );
    };
    return { loading, onDetach };
};
