import {
    techMapTemplateConstant,
    techMapTemplateModel,
} from "@entities/Production/TechMapTemplate";
import { openDrawer } from "@shared/ui/DrawerManager";
import { MouseEvent } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useTechMapTmplOperation = (
    tmtId: number | null,
    operation: TTechMapTemplateOperation
) => {
    const dispatch = useDispatch<AppDispatch>();
    const deleteLoading = useSelector((state: RootState) =>
        techMapTemplateModel.selectDeleteOperationLoading(state, operation.uuid)
    );
    const onClick = () => {
        openDrawer({
            pattern:
                techMapTemplateConstant.LOCATION_TMT_OPERATION_PATTERN +
                "/" +
                (tmtId ?? "create"),
            id: operation.id,
            name: "operation",
            additional: {
                tmtId: tmtId ?? "create",
            },
        });
    };

    const onClickDelete = (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        if (tmtId)
            dispatch(
                techMapTemplateModel.deleteTechMapTemplateOperationThunk(operation, tmtId)
            );
    };
    return { deleteLoading, onClick, onClickDelete };
};
