import { CardWithActions } from "@shared/ui/Cards/CardWithActions";
import { TextWithLabel } from "@shared/ui/Text";
import { useCalculatedValueItem } from "../model/UseCalculatedValuesItem";

interface ICalculatedValueItem {
    calculatedValue: TCalculatedValue;
    deleteLoading: boolean;
    onDelete: (entity: TCalculatedValue) => void;
    onClick?: () => void;
}

const CalculatedValueItem = ({
    calculatedValue,
    deleteLoading,
    onClick,
    onDelete,
}: ICalculatedValueItem) => {
    const { onSubmitDelete, onEdit } = useCalculatedValueItem(calculatedValue, onDelete);

    return (
        <CardWithActions
            onClick={onClick}
            onSubmitDelete={onSubmitDelete}
            deleteLoading={deleteLoading}
            onEdit={onEdit}
        >
            <TextWithLabel
                placeholder={"Имя переменной"}
                label={"Наименование"}
                text={calculatedValue.name}
            />
            <TextWithLabel
                placeholder={"Тип переменной"}
                label={"Тип"}
                text={calculatedValue.type}
            />
        </CardWithActions>
    );
};

export default CalculatedValueItem;
