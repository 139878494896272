import { LoadingOutlined } from "@ant-design/icons";
import { TMActions } from "@features/Production/TechMapFeatures/TMActions";
import { TMNecessaryItems } from "@features/Production/TechMapFeatures/TMNecessaryItems";
import { Empty, Spin } from "antd";
import { useTMOperationProccess } from "../model";
import "./TMOperationProccess.scss";
interface ITMOperationProccess {}

const TMOperationProccess = ({}: ITMOperationProccess) => {
    const { loading, operation, printerErr } = useTMOperationProccess();
    return (
        <div
            className="tmo-process"
            style={{
                display: "flex",
                flexDirection: "column",
                overflowY: "auto",
            }}
        >
            {loading || !operation ? (
                <div
                    style={{
                        display: "flex",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    {loading ? (
                        <Spin size="large" indicator={<LoadingOutlined />} />
                    ) : (
                        <Empty description={"Нет запущенный операций"} />
                    )}
                </div>
            ) : (
                <>
                    <TMNecessaryItems />
                    {printerErr && (
                        <div>
                            <span style={{ color: "red" }}>
                                Необходимо подключить принтер!
                            </span>
                        </div>
                    )}
                    <div className="tmo-process__actions">
                        <TMActions />
                    </div>
                </>
            )}
        </div>
    );
};

export default TMOperationProccess;
