// Новые блоки добавляются в children
export const actionsAllowChildren: TBizProcActionType[] = [
    "IfElseAction",
    "AgreementAction",
    "ApproveBranchAction",
    "RejectBranchAction",
    "IfElseActionBranch",
    "InitialAction",
    "ParallelAction",
    "ParallelBranchAction",
];
// Не делать кнопку ДОБАВИТЬ после экшена
export const actionNotAllowAddAfter: TBizProcActionType[] = ["AgreementAction"];
// Имеет внутри себя детей расположенных в строку
export const bizProcRowChild: TBizProcActionType[] = [
    "IfElseAction",
    "AgreementAction",
    "ParallelAction",
];
