import { Flex, List, Typography } from "antd";
import { motion } from "framer-motion";
import { useState } from "react";
import "./ListWithAnimations.scss";
import { TListItemAddonNodes } from "../ListTypes";
import { useWindowResize } from "@shared/lib/UseWindowResize";

interface IListWithAnimations<T> {
    listData: T[];
    listItemAddonNodes?: (item: any) => TListItemAddonNodes;
    onClickListItem: (item: T) => void;
    listItemMetaAvatarNode?: (item: T) => React.ReactNode;
    listItemMetaTitleText: (item: T, index: number) => string;
    listItemMetaDescriptionNode?: (item: T) => React.ReactNode;
}

const variants = {
    open: (index: number) => ({ opacity: 1, x: -40 * (index + 1) }),
    closed: { opacity: 0, x: -32 },
};

const ListWithAnimations = <T,>({
    listData,
    listItemAddonNodes,
    onClickListItem,
    listItemMetaAvatarNode,
    listItemMetaTitleText,
    listItemMetaDescriptionNode,
}: IListWithAnimations<T>) => {
    const [hoverItemIndex, setHoverItemIndex] = useState<number | null>(null);

    const blockingAnimationDisappearanceWidth = 1200;

    const [isOverBlockingAnimationDisappearanceWidth] = useWindowResize([
        blockingAnimationDisappearanceWidth,
    ]);
    return (
        <List
            className="list-with-animation"
            dataSource={listData}
            renderItem={(item, index) => {
                const isHovered = index === hoverItemIndex;
                return (
                    <Flex
                        onMouseEnter={() => {
                            setHoverItemIndex(index);
                        }}
                        onMouseLeave={() => {
                            setHoverItemIndex(null);
                        }}
                    >
                        <List.Item
                            onClick={() => onClickListItem(item)}
                            className="list-with-animation__item"
                        >
                            <List.Item.Meta
                                avatar={
                                    listItemMetaAvatarNode
                                        ? listItemMetaAvatarNode(item)
                                        : undefined
                                }
                                title={
                                    <Typography.Title level={5} style={{ margin: 0 }}>
                                        {listItemMetaTitleText(item, index)}
                                    </Typography.Title>
                                }
                                description={
                                    listItemMetaDescriptionNode ? (
                                        <Typography.Paragraph
                                            style={{ marginBottom: 0 }}
                                            ellipsis={{ rows: 1, expandable: false }}
                                        >
                                            {listItemMetaDescriptionNode(item)}
                                        </Typography.Paragraph>
                                    ) : undefined
                                }
                            />
                            <Flex style={{ marginRight: 0 }}>
                                {listItemAddonNodes &&
                                    listItemAddonNodes(item)?.map((listNode, index) => {
                                        return (
                                            <motion.div
                                                key={index}
                                                variants={variants}
                                                initial={{
                                                    width: 0,
                                                    opacity: 0,
                                                    x: -24,
                                                }}
                                                animate={
                                                    isOverBlockingAnimationDisappearanceWidth
                                                        ? isHovered
                                                            ? "open"
                                                            : "closed"
                                                        : "open"
                                                }
                                                custom={index}
                                                exit={{ width: 0, opacity: 0 }}
                                                transition={{ duration: 0.3 }}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    listNode.handler(item);
                                                }}
                                                className={`list-with-animation__item-addon`}
                                            >
                                                {listNode.node}
                                            </motion.div>
                                        );
                                    })}
                            </Flex>
                        </List.Item>
                    </Flex>
                );
            }}
        />
    );
};

export default ListWithAnimations;
