import { entityRowModel } from "@entities/Portal/EntityRow";
import { userModel } from "@entities/Portal/User";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export const useEntityRowsKanbanWS = (
    entityId: number,
    entityRowsByEntityField: { [key: string]: any },
    choosenEntityField: TEntityField | null,
    setEntityRowsByEntityField: React.Dispatch<
        React.SetStateAction<{
            [key: string]: any;
        }>
    >
) => {
    const user = useSelector(userModel.selectUser);

    const onCreate = (entityRow: any) => {
        if (!choosenEntityField) return;
        setEntityRowsByEntityField((prevEntityRowsByEntityField) => {
            const newEntityRowsByEntityField = JSON.parse(
                JSON.stringify(prevEntityRowsByEntityField)
            );
            if (
                newEntityRowsByEntityField[entityRow[choosenEntityField.key] ?? ""] !==
                undefined
            ) {
                newEntityRowsByEntityField[entityRow[choosenEntityField.key] ?? ""].data =
                    [
                        ...newEntityRowsByEntityField[
                            entityRow[choosenEntityField.key] ?? ""
                        ].data,
                        { ...entityRow, is_invisible_for_pagination: true },
                    ].sort((a, b) => b.id - a.id);
            } else {
                newEntityRowsByEntityField[entityRow[choosenEntityField.key] ?? ""] = {
                    count: 1,
                    data: [entityRow],
                    is_ended: true,
                };
            }
            return newEntityRowsByEntityField;
        });
    };

    const onUpdate = (entityRow: any) => {
        if (!choosenEntityField) return;
        setEntityRowsByEntityField((prevEntityRowsByEntityField) => {
            const newEntityRowsByEntityField = JSON.parse(
                JSON.stringify(prevEntityRowsByEntityField)
            );
            Object.keys(newEntityRowsByEntityField).forEach((key) => {
                const foundedElement = newEntityRowsByEntityField[key].data.find(
                    (item: any) => item.id === entityRow.id
                );
                if (!!foundedElement) {
                    newEntityRowsByEntityField[key].data = newEntityRowsByEntityField[
                        key
                    ].data.filter((item: any) => item.id !== foundedElement.id);
                }
            });
            if (
                newEntityRowsByEntityField[entityRow[choosenEntityField.key] ?? ""] ===
                undefined
            ) {
                newEntityRowsByEntityField[entityRow[choosenEntityField.key] ?? ""] = {
                    count: 1,
                    data: [entityRow],
                    is_ended: true,
                };
            } else {
                newEntityRowsByEntityField[entityRow[choosenEntityField.key] ?? ""].data =
                    [
                        ...newEntityRowsByEntityField[
                            entityRow[choosenEntityField.key] ?? ""
                        ].data,
                        { ...entityRow, is_invisible_for_pagination: true },
                    ].sort((a, b) => b.id - a.id);
            }
            return newEntityRowsByEntityField;
        });
    };

    const onDelete = (entityRowId: number) => {
        if (!choosenEntityField) return;
        setEntityRowsByEntityField((prevEntityRowsByEntityField) => {
            const newEntityRowsByEntityField: {
                [key: string]: {
                    count: number;
                    data: Record<string, any>[];
                    is_ended: boolean;
                };
            } = JSON.parse(JSON.stringify(prevEntityRowsByEntityField));

            const entityRows = Object.entries(newEntityRowsByEntityField)
                .map(([key, value]) => value.data)
                .flat();
            const deletedEntityRow = entityRows.find(({ id }) => id === entityRowId);
            if (
                deletedEntityRow &&
                newEntityRowsByEntityField[deletedEntityRow?.[choosenEntityField.key]] !==
                    undefined
            ) {
                newEntityRowsByEntityField[
                    deletedEntityRow[choosenEntityField.key] ?? ""
                ].data = newEntityRowsByEntityField[
                    deletedEntityRow[choosenEntityField.key] ?? ""
                ].data
                    .filter((item: any) => item.id !== deletedEntityRow.id)
                    .sort((a: any, b: any) => b.id - a.id);
            }
            return newEntityRowsByEntityField;
        });
    };

    entityRowModel.useEntityRowsWS(entityId, user, onCreate, onUpdate, onDelete);
};
