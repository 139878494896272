import { Tag } from "antd";
import { ReactNode } from "react";
import { getBizProcStatusColor, getBizProcStatusName } from "../../../BizProc/lib";
import "./UserActionListItem.scss";

interface IUserActionListItem {
    action: TBizProcAction;
    footer?: ReactNode;
}

const UserActionListItem = ({ action, footer }: IUserActionListItem) => {
    const header = action.collected_parameters.header;
    //const header = "Процесс согласования включает в себя ряд шагов, которые помогают достичь единства мнений и создать единую стратегию действий. Во-первых, необходимо определить цели и задачи, которые нужно достичь. Это поможет участникам ясно представить, чего они ожидают от согласования и какие результаты они хотят получить.";
    //const body = "Согласование – важный аспект успешной работы в любой сфере деятельности. Оно позволяет достичь взаимопонимания, согласованности и согласия между участниками процесса, а также избежать конфликтов и недоразумений";
    const body = action.collected_parameters.body;

    return (
        <div className="user-action">
            <div className="user-action__header">
                <div className="user-action__header__name">{header}</div>
                <Tag
                    className="user-action__header__status"
                    color={getBizProcStatusColor(action.status)}
                >
                    {getBizProcStatusName(action.status)}
                </Tag>
            </div>
            <div className="user-action__desc">
                <div className="user-action__desc__body">{body}</div>
            </div>
            <div className="agree-item__additional">{footer}</div>
        </div>
    );
};

export default UserActionListItem;
