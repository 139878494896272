import ActionCard from "./ActionCard";

interface IAgreementAction {
    onDeleteItem?: () => void;
    templateItem?: TBizProcTemplateAction;
}

const AgreementAction = ({ onDeleteItem, templateItem }: IAgreementAction) => {
    return (
        <ActionCard
            templateItem={templateItem}
            borderColorLeft="red"
            borderColorRight="green"
            onDeleteItem={onDeleteItem}
            title={templateItem?.name ?? "Согласование"}
        ></ActionCard>
    );
};

export default AgreementAction;
