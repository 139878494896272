import { FormInputItem } from "@entities/Portal/Datatype";
import { FormItem } from "@shared/ui/Form";
import { SkeletonFormBuilder } from "@shared/ui/Form/ui/FormBuilder";
import { labelSkeletonWidth, valueSkeletonWidth } from "./constants";
import "./CreateEditEntityRowForm.scss";
import { Empty, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { userModel } from "@entities/Portal/User";
import { InfoCircleOutlined } from "@ant-design/icons";

interface ICreateEditEntityRowForm {
    width?: string | number;
    error: any;
    isLoading: boolean;
    fields: (TBizProcTemplateActionField & TEntityFieldWithValue)[] | undefined;
    onlyReadMode: boolean;
    onChangeFormFields: (value: any, inputId: string | number | undefined) => void;
    entityRowId: number | undefined | null;
}

const CreateEditEntityRowForm = ({
    width,
    error,
    isLoading,
    fields,
    onlyReadMode,
    onChangeFormFields,
    entityRowId,
}: ICreateEditEntityRowForm) => {
    const windowWidth = window.innerWidth;
    const isAdmin = useSelector(userModel.selectUserIsAdmin);

    return (
        <div
            className="create-edit-entity-row__form"
            style={{
                width: width
                    ? width
                    : windowWidth > 900
                      ? entityRowId
                          ? "50%"
                          : 480
                      : "100%",
                paddingTop: error ? 26 : undefined,
            }}
        >
            {error ? (
                <Empty description="Ошибка загрузки полей" />
            ) : isLoading ? (
                <SkeletonFormBuilder
                    labelSkeletonWidth={labelSkeletonWidth}
                    valueSkeletonWidth={valueSkeletonWidth}
                />
            ) : (
                <>
                    {fields &&
                        fields?.map(
                            (
                                field: TBizProcTemplateActionField &
                                    TEntityFieldWithValue,
                                index: number
                            ) => {
                                return (
                                    <FormItem
                                        key={index}
                                        labelStyle={
                                            field.is_readonly
                                                ? {
                                                      color: "#8f8f8f",
                                                      fontWeight: 400,
                                                  }
                                                : undefined
                                        }
                                        label={
                                            <>
                                                {isAdmin &&
                                                    (!!field.is_readonly ||
                                                        !!field.is_unwritable) && (
                                                        <Tooltip
                                                            title="Заполнение доступно только администратору"
                                                            color="var(--info)"
                                                        >
                                                            <InfoCircleOutlined />
                                                        </Tooltip>
                                                    )}{" "}
                                                {field.name}
                                            </>
                                        }
                                        required={Boolean(field.is_required)}
                                    >
                                        <FormInputItem
                                            field={field}
                                            fieldsArray={fields}
                                            inputId={field.unique_id}
                                            additionalParameters={{
                                                ...(field.additional_parameters ?? {}),
                                                isCard: true,
                                            }}
                                            datatype={field.datatype as unknown as string}
                                            value={field.value}
                                            onlyRead={
                                                isAdmin && !onlyReadMode
                                                    ? false
                                                    : onlyReadMode ||
                                                      Boolean(field.is_readonly) ||
                                                      (field.variable_type === "entity" &&
                                                          field.is_unwritable)
                                            }
                                            onChange={onChangeFormFields}
                                        />
                                    </FormItem>
                                );
                            }
                        )}
                </>
            )}
        </div>
    );
};

export default CreateEditEntityRowForm;
