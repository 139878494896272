import { bizProcTemplateApi } from "@entities/Portal/BizProcTemplate";
import { Button, Flex, Input, Select } from "antd";
import { ChangeEvent, useState } from "react";

interface IViewPatternItemCreateChildBizProcButton {
    entityFields: TEntityField[];
    onCreate: (
        label: string,
        type: TViewPatternItemType,
        type_value: string | number | null
    ) => void;
}

const ViewPatternItemCreateChildBizProcButton = ({
    entityFields,
    onCreate,
}: IViewPatternItemCreateChildBizProcButton) => {
    const [entityFieldKey, setEntityFieldKey] = useState<string | null>(null);
    const [label, setLabel] = useState<string>("");
    const [bizProcTemplates, setBizProcTemplates] = useState<TBizProcTemplateItem[]>([]);
    const [bizProcTemplate, setBizProcTemplate] = useState<TBizProcTemplateItem | null>(
        null
    );
    const [error, setError] = useState({
        bizProc: false,
        field: false,
    });

    const options = entityFields.map((field) => ({
        value: field.key,
        label: field.name,
        field,
    }));
    const bizProcTemplatesOptions = bizProcTemplates.map((template) => ({
        value: template.id,
        label: template.name,
        template,
    }));
    const getBizProcTempaltes = (entityUuid: string) => {
        bizProcTemplateApi
            .getBizProcEntityTemplatesByUuid(entityUuid)
            .then((response) => {
                const templates = response.data.data;
                setBizProcTemplates(templates);
            });
    };
    const onChangeLabel = (event: ChangeEvent<HTMLInputElement>) => {
        setLabel(event.target.value);
    };
    const onChangeEntityField = (value: string | null, option: any) => {
        setError({
            ...error,
            field: false,
        });

        setEntityFieldKey(value);
        setBizProcTemplate(null);
        setBizProcTemplates([]);
        getBizProcTempaltes(option.field.related_entity_uuid);
    };
    const onChangeTemplate = (value: number | null, option: any) => {
        setBizProcTemplate(option.template);
        setError({
            ...error,
            bizProc: false,
        });
    };
    const onClick = () => {
        if (entityFieldKey == null || bizProcTemplate == null) {
            setError({
                bizProc: bizProcTemplate == null,
                field: entityFieldKey == null,
            });
            return;
        }
        setEntityFieldKey(null);
        setLabel("");
        setBizProcTemplate(null);
        onCreate(
            label,
            "start_child_biz_proc",
            JSON.stringify({
                entityFieldKey,
                bizProcTemplateId: bizProcTemplate.id,
                bizProcTemplateName: bizProcTemplate.name,
            })
        );
    };
    return (
        <Flex wrap="wrap" gap={8} style={{ display: "flex" }}>
            <Input
                placeholder="Имя кнопки"
                style={{ flex: "1 1 120px" }}
                value={label}
                onChange={onChangeLabel}
            />
            <Select
                status={error.field ? "error" : undefined}
                onMouseEnter={(e) => e?.stopPropagation()}
                value={entityFieldKey}
                onChange={onChangeEntityField}
                options={options}
                style={{ flex: "1 1 120px" }}
            />
            <Select
                status={error.bizProc ? "error" : undefined}
                onMouseEnter={(e) => e?.stopPropagation()}
                value={bizProcTemplate?.id ?? null}
                onChange={onChangeTemplate}
                options={bizProcTemplatesOptions}
                style={{ flex: "1 1 120px" }}
            />
            <Button onClick={onClick}>Создать</Button>
        </Flex>
    );
};

export default ViewPatternItemCreateChildBizProcButton;
