import { checkEntityRowEqual } from "@entities/Portal/Datatype/lib";
import { useEffect, useState } from "react";

interface IUseEditEntity {
    open: boolean;

    initialValue: any | null;
    onSubmitValue: (value: any) => void;
    onClose: () => void;
    initialFields: TBizProcFormField[];
}

export const useEditEntity = ({
    open,
    initialValue,
    initialFields,
    onClose,
    onSubmitValue,
}: IUseEditEntity) => {
    const [isEqual, setIsEqual] = useState(true);
    const [value, setValue] = useState<any>({});
    const [isOpen, setIsOpen] = useState(false);
    const [alertIsOpen, setAlertIsOpen] = useState(false);
    useEffect(() => {
        setIsOpen(open);
        if (open) {
            setIsEqual(true);
            setValue(initialValue ?? {});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const onSubmit = () => {
        if (value) onSubmitValue(value);
    };
    const onChangeFormFields = (fields: TBizProcFormField[], id?: string) => {
        const newValue = fields.reduce((prev, current) => {
            return {
                ...prev,
                id: value.id,
                [current.key]: current.value,
            };
        }, {});
        setValue({
            ...newValue,
            tmp_id: initialValue.tmp_id,
        });
        const isEqual = checkEntityRowEqual(initialValue, newValue, initialFields);
        setIsEqual(isEqual);
    };
    const fields = (initialFields as any)
        ?.toSorted((a: TEntityField, b: TEntityField) => {
            return a.order! - b.order!;
        })
        .map((field: TEntityField) => {
            return {
                ...field,
                value: value?.[field.key],
            };
        });

    const onCloseDrawer = () => {
        if (isEqual) onClose();
        else {
            setAlertIsOpen(true);
        }
    };
    return {
        isEqual,
        isOpen,
        fields,
        alertIsOpen,
        setAlertIsOpen,
        onCloseDrawer,
        onSubmit,
        onChangeFormFields,
    };
};
