import { entityRowConstants } from "@entities/Portal/EntityRow";

type TFilterValue = { key: string; value: any; condition: TComparisonType | null };

export const getCurrentFilters = (entityId: number) => {
    const storedFilters = localStorage.getItem(`${entityId}__saved-fields`);
    return storedFilters ? JSON.parse(storedFilters) : undefined;
};

export const saveCurrentFilters = (entityId: number, filterInputs: TFilterValue[]) => {
    localStorage.setItem(`${entityId}__saved-fields`, JSON.stringify(filterInputs));
};

export const getInitialFilters = (entityFields: TEntityField[]) => {
    return entityFields
        .filter(
            (field) => !entityRowConstants.nowAllowedFilterFields.includes(field.datatype)
        )
        .slice(0, 5)
        .map((field) => {
            return {
                value: null,
                condition: "=" as TComparisonType,
                key: field.key,
            };
        });
};
export const getFiltersWithAddedValues = (
    filters: TFilterValue[],
    filtersWithValues: TFilterValue[]
) => {
    return filters.map((currentFilter) => {
        const filter = filtersWithValues.find(
            (filter) => currentFilter.key === filter.key
        );
        if (filter) return filter;
        return currentFilter;
    });
};
export const getEntityFieldWithFilter = (
    currentFilterValues: TFilterValue[],
    entityFields: TEntityField[]
): (TBizProcFormField & { condition?: TComparisonType | null })[] => {
    const selectedFilters = currentFilterValues?.map((filter) => filter.key) ?? [];
    const filteredEntityField = entityFields.filter((entityField) =>
        selectedFilters.includes(entityField.key)
    );

    return filteredEntityField.map((field) => {
        const foundFilterItem = currentFilterValues.find(
            (item) => item.key === field.key
        );
        return {
            ...field,
            value: foundFilterItem?.value ?? null,
            condition: foundFilterItem?.condition ?? null,
            datatype: field.datatype,
            additional_parameters: {
                fields: [],
                entityFields: [],
            },
            is_required: false,
            is_readonly: false,
            unique_id: `${field.variable_type}-${field.key}`,
            variable_type: "entity",
        };
    });
};

export const getFormInputItemValue = (
    filterValueItem: TBizProcFormField & {
        condition?: any;
    },
    isDisabledCurrentCategorySelect: boolean
) =>
    filterValueItem.key === "category" && !isDisabledCurrentCategorySelect
        ? filterValueItem.condition === "in"
            ? []
            : null
        : filterValueItem?.condition === "null" ||
            filterValueItem?.condition === "not_null"
          ? null
          : filterValueItem?.value;

export const getFormInputItemFieldsArray = (
    filterValueItem: TBizProcFormField & {
        condition?: any;
    },
    isDisabledCurrentCategorySelect: boolean,
    currentCategory: number | null,
    visibleInputs: (TBizProcFormField & {
        condition?: any;
    })[],
    entityFields: TEntityField[]
) => {
    let resultVisibleInputs = visibleInputs;
    if (filterValueItem.key === "stage" && currentCategory) {
        resultVisibleInputs = getEntityFieldWithFilter(
            [{ key: "category", value: currentCategory, condition: "in" }],
            entityFields
        );
    }
    return !isDisabledCurrentCategorySelect && !!currentCategory
        ? resultVisibleInputs.map((item) =>
              item.datatype === "category"
                  ? {
                        ...item,
                        value: currentCategory,
                    }
                  : item
          )
        : visibleInputs;
};

export const getFormInputItemAdditionalParameters = (
    filterValueItem: TBizProcFormField & {
        condition?: any;
    }
) => ({
    ...(filterValueItem.additional_parameters ?? {}),
    isInFilterInput: true,
    isBetween: filterValueItem?.condition === "between",
    isIncludeFilter: filterValueItem?.condition === "in",
});
