import { useDispatch, useSelector } from "react-redux";
import { selectLoadBizProcAgreementsIds } from "./BizProcSelectors";
import { useEffect } from "react";
import { loadBizProcsAgreementsThunk } from "./BizProcThunk";

export const usePostLoadAgreements = (isMy: boolean) => {
    const loadBizProcAgreementsIds = useSelector(selectLoadBizProcAgreementsIds);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        const bizProcTemplateId = loadBizProcAgreementsIds?.[0];

        if (bizProcTemplateId) {
            dispatch(
                loadBizProcsAgreementsThunk({
                    isMy: isMy,
                    add: true,
                    bizProcTemplateId: bizProcTemplateId,
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadBizProcAgreementsIds?.[0]]);
};
