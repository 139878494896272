import { PAPER_SIZE } from "@shared/StickerPrinter/constants";
import { StickerPrinter } from "../StickerPrinter";
type TOrientationQR = "N";
type TModelQR = 1 | 2;
type TErrorCorrectionQR = "H" | "Q" | "M" | "L"; // Точность
type TMaskValue = 1 | 2 | 3 | 4 | 5 | 6 | 7;

const FONT_SIZE = 15;

export class Zebra extends StickerPrinter {
    private SERVICE = "e7810a71-73ae-499d-8c15-faa9aef0c3f2";
    private CHARACTERISTIC = "bef8d6c9-9c21-4c9e-b632-bd58c1009f9f";
    private characteristic: BluetoothRemoteGATTCharacteristic | null = null;
    isConnected = false;
    private DPI = 203;
    private stickerWidthPoints = 519;
    private stickerHeightPoints = 360;
    private onChangePrinterState: any = null;
    constructor(
        paperSize: PAPER_SIZE,
        onChangePrinterState: (param: keyof TPrinterState, value: any) => void
    ) {
        super(paperSize);
        this.onChangePrinterState = onChangePrinterState;
        this.stickerHeightPoints = this.getStickerHeight(paperSize);
        this.stickerWidthPoints = this.getStickerWidth(paperSize);
    }

    calcCenterWidth(width: number) {
        return Math.round((this.stickerWidthPoints - width) / 2);
    }
    calcCenterHeight(height: number) {
        return Math.round((this.stickerHeightPoints - height) / 2);
    }
    calcPoints(sizeMm: number) {
        return Math.round((sizeMm * this.DPI) / 25.4);
    }

    onChangeIsConnected(isConnected: boolean) {
        this.isConnected = isConnected;
        this.onChangePrinterState("isConnected", isConnected);
    }
    onChangeConnecting(connecting: boolean) {
        this.onChangePrinterState("connecting", connecting);
    }
    onChangePrinting(state: boolean) {
        this.onChangePrinterState("printing", state);
    }

    async connect(): Promise<any> {
        if (this.isConnected) return;
        this.onChangeConnecting(true);
        return new Promise(async (resolve, reject) => {
            try {
                // Запрашиваем доступ к Bluetooth-устройству
                const device = await navigator.bluetooth.requestDevice({
                    acceptAllDevices: true,
                    optionalServices: [this.SERVICE],
                });
                if (!device) return;
                if (!device.gatt) return;
                // Подключаемся к устройству
                const server = await device.gatt.connect();

                const service = await server.getPrimaryService(this.SERVICE); // Замените на UUID вашего принтера
                this.characteristic = await service.getCharacteristic(
                    this.CHARACTERISTIC
                ); // Замените на UUID характеристики

                this.onChangeIsConnected(true);
                resolve("ok");
            } catch (e) {
                reject(e);
            }
        })
            .catch((e) => {
                throw e;
            })
            .finally(() => {
                this.onChangeConnecting(false);
            });
    }

    getStickerWidth(paperSize: PAPER_SIZE) {
        switch (paperSize) {
            case PAPER_SIZE.PAPER_65x45:
                return 519;
            case PAPER_SIZE.PAPER_35x25:
                return 279;
            default:
                return 519;
        }
    }
    getStickerHeight(paperSize: PAPER_SIZE) {
        switch (paperSize) {
            case PAPER_SIZE.PAPER_65x45:
                return 360;
            case PAPER_SIZE.PAPER_35x25:
                return 200;
            default:
                return 360;
        }
    }
    paperSizeToQrSize(paperSize: PAPER_SIZE) {
        switch (paperSize) {
            case PAPER_SIZE.PAPER_65x45:
                return 10;
            case PAPER_SIZE.PAPER_35x25:
                return 5;
            default:
                return 3;
        }
    }
    paperSizeToBarCodeSize(paperSize: PAPER_SIZE) {
        switch (paperSize) {
            case PAPER_SIZE.PAPER_65x45:
                return 3;

            case PAPER_SIZE.PAPER_35x25:
                return 1;
            default:
                return 2;
        }
    }
    calcBarCodeWidth(lineWidth: number, count: number) {
        return (count * 11 + 34) * lineWidth;
    }
    paperSizeToBarCodeHeight(paperSize: PAPER_SIZE) {
        switch (paperSize) {
            case PAPER_SIZE.PAPER_65x45:
                return 100;
            case PAPER_SIZE.PAPER_35x25:
                return 50;
            default:
                return 50;
        }
    }

    async printQrCode(code: string): Promise<void> {
        const codeFactor = this.paperSizeToQrSize(this.paperSize);
        const codeSize = codeFactor * 21;
        const codeWidthPos = this.calcCenterWidth(codeSize);
        const codeHeightPos = this.calcCenterHeight(codeSize);

        const labelHeightPos = codeHeightPos + codeSize + 10;

        this.print(
            `^PW${this.stickerWidthPoints}
            ^FO${codeWidthPos},${codeHeightPos}
            ^A0N,1,1${this.getQrCodeCommand("N", 2, codeFactor, "L", 1)}
            ^FB${this.stickerWidthPoints},1,0,C,1
            ^FDQA,${code}^FS
            ^FO${0},${labelHeightPos}
            ^A0N,50,30
            ^FB${this.stickerWidthPoints},1,0,C,1
            ^FD${code}^FS 
            `
        );
    }
    async printBarCode(code: string): Promise<void> {
        const lineWidth = this.paperSizeToBarCodeSize(this.paperSize);
        const codeHeight = this.paperSizeToBarCodeHeight(this.paperSize);
        const codeWidth = this.calcBarCodeWidth(lineWidth, code.length); //

        const codeWidthPos = this.calcCenterWidth(codeWidth);
        const codeHeightPos = this.calcCenterHeight(codeHeight);
        this.print(
            `^PW${this.stickerWidthPoints}
            ^FO${codeWidthPos},${codeHeightPos}
            ^BY${lineWidth},2,${codeHeight}
            ^BC
            ^FD${code}^FS
            `
        );
    }

    getQrCodeCommand(
        orientation: TOrientationQR,
        model: TModelQR,
        magnificationFactor: TMagnificationFactorQR,
        errorCorrection: TErrorCorrectionQR,
        maskValue: TMaskValue
    ) {
        return `^BQ${orientation},${model},${magnificationFactor},${errorCorrection},${maskValue}`;
    }

    async print(text: string): Promise<void> {
        if (!this.characteristic) return;
        this.onChangePrinting(true);
        try {
            const printingData = `^XA${text}^XZ`;
            const bytes = new TextEncoder().encode(printingData); // Текст для печати
            await this.characteristic.writeValue(bytes);
        } catch (e) {}
        this.onChangePrinting(false);
    }
}
