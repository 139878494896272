import { techMapModel } from "@entities/Production/TechMap";
import { useTMQrBarActions } from "@features/Production/TechMapFeatures/TMActions";
import { useSelector } from "react-redux";

export const useTMOperationProccess = () => {
    const { loading, operation } = techMapModel.useLoadStartedOperation();
    const loadingTm = useSelector(techMapModel.selectGetStartedTmLoading);
    const printerIsConnected = useSelector(techMapModel.selectPrinterIsConnected);
    const needPrinter = Boolean(operation?.template_operation?.code_templates?.length);

    useTMQrBarActions();
    return {
        loading: loadingTm || loading,
        operation,
        printerErr: needPrinter && !printerIsConnected,
    };
};
