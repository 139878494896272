import { PlusOutlined } from "@ant-design/icons";
import { parseErrorMessageByKey } from "@shared/api/ParseResponse";
import { FormItem } from "@shared/ui/Form";
import { ErrorText } from "@shared/ui/Text";
import { Button, Checkbox, Flex, Input, Modal, Radio, Select } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { ReactNode } from "react";
import { variableParentsOptions } from "../constants";
import { useCreateUpdateVariable } from "../model/UseCreateUpdateVariable";
import VariableInputs from "./VariableInputs";
import { EntitySelect } from "@entities/Portal/Entity";

interface IVariableModifyModal {
    editingVariable: TVariable | null;
    datatypeDependingInputs?: <T>(props: {
        editingField: T;
        error?: TValidationError;
        setEditingField: (editingField: T) => void;
        onChangeIsMultiple?: (event: CheckboxChangeEvent) => void;
        additionalNodeForMultipleCheckbox?: React.ReactNode;
    }) => ReactNode;
    onSuccess?: (variable: TVariable, isCreating?: boolean) => void;
}

const VariableModifyModal = ({
    onSuccess,
    editingVariable,
    datatypeDependingInputs,
}: IVariableModifyModal) => {
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    const {
        isCalculatedValue,
        calculateOnce,
        onClose,
        onChangeName,
        onOk,
        onSelectParentType,
        onSelectParentId,
        onChangeType,
        setVariable,
        onChangeVariableValue,
        onSelectIsCalculated,
        onSelectIsPrecalculated,
        onChangeIsCalculatedValueCheckbox,
        onChangeIsMultiple,
        onAddValueItem,
        types,
        loading,
        options,
        error,
        isOpen,
        variable,
    } = useCreateUpdateVariable(editingVariable, onSuccess);

    return (
        <Modal
            title={`${variable?.is_creating ? "Создание" : "Редактирование"} переменной`}
            open={isOpen}
            onOk={onOk}
            confirmLoading={loading}
            onCancel={onClose}
            destroyOnClose
            footer={(originNode, { OkBtn, CancelBtn }) => {
                return (
                    <Flex justify="space-between" align="center">
                        <Button
                            disabled={!variable?.is_multiple || !isCalculatedValue}
                            type="link"
                            style={{
                                padding: 0,
                                margin: 0,
                                height: "fit-content",
                                minHeight: 0,
                            }}
                            icon={<PlusOutlined />}
                            onClick={onAddValueItem}
                        >
                            Добавить значение
                        </Button>
                        <div>{originNode}</div>
                    </Flex>
                );
            }}
            width={"600px"}
            style={{
                top: windowHeight <= 700 ? "10%" : undefined,
                padding: windowWidth <= 700 ? 0 : undefined,
            }}
        >
            <FormItem label="Тип">
                <>
                    <Select
                        style={{ width: "100%" }}
                        options={variableParentsOptions}
                        value={variable?.type}
                        onSelect={onSelectParentType}
                    />
                    {error.data?.["type"] && <ErrorText text={error.data?.["type"][0]} />}
                </>
            </FormItem>

            <FormItem label="Идентификатор родителя">
                <>
                    {/*TODO: проверить параметры, которые отправляются для каждого типа
                        entity_uuid, biz_proc_template_uuid, biz_proc_id
                        */}
                    {variable?.type !== "global" ? (
                        <>
                            {variable?.type === "entity" ? (
                                <EntitySelect
                                    multiple={false}
                                    disabled={false}
                                    value={
                                        variable.parent?.name ??
                                        variable?.parent_identifier_label
                                    }
                                    onChangeSelect={onSelectParentId}
                                />
                            ) : (
                                <Select
                                    style={{ width: "100%" }}
                                    options={options}
                                    value={
                                        variable?.parent?.name ??
                                        variable?.parent_identifier_label
                                    }
                                    placeholder={"Выберите идентификатор"}
                                    onChange={onSelectParentId}
                                />
                            )}
                        </>
                    ) : (
                        <p>Выбран глобальный тип родителя</p>
                    )}
                    {error.data?.["parent_identifier"] && (
                        <ErrorText text={error.data?.["parent_identifier"][0]} />
                    )}
                </>
            </FormItem>

            <FormItem label="Наименование">
                <>
                    <Input
                        name="name"
                        status={error.data?.["name"] ? "error" : undefined}
                        value={variable?.name}
                        onChange={onChangeName}
                        placeholder="Наименование"
                    />
                    <ErrorText absolute text={parseErrorMessageByKey(error, "name")} />
                </>
            </FormItem>
            <FormItem label="Ключ">
                <>
                    <Input
                        name="key"
                        status={error.data?.["key"] ? "error" : undefined}
                        value={variable?.key}
                        onChange={onChangeName}
                        placeholder="Ключ"
                    />
                    <ErrorText absolute text={parseErrorMessageByKey(error, "key")} />
                </>
            </FormItem>

            <FormItem label="Тип данных">
                <>
                    <Select
                        options={types}
                        value={variable?.datatype ?? null}
                        onChange={onChangeType}
                        style={{ width: "100%" }}
                    />
                    <ErrorText
                        absolute
                        text={parseErrorMessageByKey(error, "datatype")}
                    />
                </>
            </FormItem>

            {variable?.datatype && (
                <Flex vertical gap={8}>
                    <Flex justify="space-between" align="baseline" wrap="wrap">
                        {datatypeDependingInputs &&
                            datatypeDependingInputs({
                                error,
                                editingField: variable,
                                setEditingField: setVariable,
                                onChangeIsMultiple,
                            })}
                    </Flex>

                    <Checkbox
                        disabled={false}
                        checked={isCalculatedValue}
                        onChange={onChangeIsCalculatedValueCheckbox}
                    >
                        Вычислять значение по формуле
                    </Checkbox>
                    {isCalculatedValue && (
                        <>
                            <Flex style={{ paddingLeft: 16 }} gap={8} vertical>
                                <Radio
                                    onChange={onSelectIsPrecalculated}
                                    checked={calculateOnce}
                                >
                                    Вычислять единожды
                                </Radio>
                                <Radio
                                    onChange={onSelectIsCalculated}
                                    checked={!calculateOnce}
                                >
                                    Вычислять при каждом обращении
                                </Radio>
                            </Flex>
                        </>
                    )}
                    <VariableInputs
                        type={variable.type}
                        itemId={
                            (variable.type === "entity"
                                ? variable.parent?.uuid
                                : variable.parent?.id) ??
                            variable.parent_identifier ??
                            ""
                        }
                        isMultiple={!!variable.is_multiple}
                        isCalculatedValue={isCalculatedValue}
                        variable={variable}
                        onChangeVariableValue={onChangeVariableValue}
                    />
                </Flex>
            )}
        </Modal>
    );
};

export default VariableModifyModal;
