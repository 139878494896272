import { useEffect, useRef, useState } from "react";
import { pdfjs } from "react-pdf";
import { onZoomDisablingEvent } from "../lib";
import { useContainerWidthResize } from "./UseContainerWidthResize";

export const usePdfViewer = (linkToPdfFile: string) => {
    const fileName = linkToPdfFile
        .split("/")
        .find((section) => section.toLowerCase().includes(".pdf"));

    const [currentNumPage, setCurrentNumPage] = useState(1);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [numPages, setNumPages] = useState<number>();
    const [scale, setScale] = useState<number>(1);
    const containerRef = useRef<HTMLElement | null>(null);
    const { containerWidth } = useContainerWidthResize(containerRef.current, !isOpen);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<number>(0);
    const [error, setError] = useState<any>(null);
    useEffect(() => {
        onLoadStart();
        window.addEventListener("wheel", onZoomDisablingEvent, { passive: false });

        return () => {
            window.removeEventListener("wheel", onZoomDisablingEvent);
        };
    }, []);

    const onDocumentLoadSuccess = ({
        numPages: nextNumPages,
    }: pdfjs.PDFDocumentProxy): void => {
        setNumPages(nextNumPages);
        setIsLoading(false);
    };

    const onLoadStart = () => {
        setIsLoading(true);
    };

    const onLoadError = (error: any) => {
        setError(error);
        setIsLoading(false);
    };

    const openCloseMenuSidebar = () => setIsOpen(!isOpen);

    const rescale = (type: "increase" | "decrease" | undefined, value?: number) => {
        const maximumScaleValue = 3;
        const minimumScaleValue = 0.3;
        const minimumInputScaleValue = 0.1;

        if (value) {
            setScale(
                value <= minimumInputScaleValue
                    ? minimumInputScaleValue
                    : value >= maximumScaleValue
                      ? maximumScaleValue
                      : value
            );
            return;
        }

        return type === "increase"
            ? setScale((prev) => {
                  return parseFloat(prev.toFixed(2)) >= maximumScaleValue
                      ? maximumScaleValue
                      : parseFloat((prev + 0.1).toFixed(2));
              })
            : setScale((prev) => {
                  return parseFloat(prev.toFixed(0)) <= minimumScaleValue
                      ? minimumScaleValue
                      : parseFloat((prev - 0.1).toFixed(2));
              });
    };

    return {
        error,
        loaded,
        fileName,
        isLoading,
        isOpen,
        currentNumPage,
        containerWidth,
        numPages,
        scale,
        containerRef,
        setLoaded,
        onDocumentLoadSuccess,
        setCurrentNumPage,
        openCloseMenuSidebar,
        rescale,
        onLoadStart,
        onLoadError,
    };
};
