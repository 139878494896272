import { techMapTemplateModel } from "@entities/Production/TechMapTemplate";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export const useTechMapTmlEditor = (techMapTemplateId: number | null) => {
    const { techMapTemplate, loading } =
        techMapTemplateModel.useTechMapTemplateItemLoadorMock(techMapTemplateId);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const isCreating = techMapTemplateId === null;
    const [initialTMT, setInitialTMT] = useState(techMapTemplate);
    // ??
    const name = initialTMT?.name;
    useEffect(() => {
        if (!loading) {
            setInitialTMT(techMapTemplate);
        }
    }, [loading]);

    const onChange = useCallback(
        (key: keyof TTechMapTemplate, value: any) => {
            dispatch(
                techMapTemplateModel.updateTechMapTemplate({
                    [key]: value,
                    results:
                        key === "is_multiple" && value === false
                            ? techMapTemplate?.results.filter(
                                  (result) => result.type === "create"
                              )
                            : techMapTemplate?.results,
                })
            );
        },
        [techMapTemplate?.results]
    );
    const onChangeTmTResult = (
        results: (TTechMapTemplateResult | TTechMapTemplateResultCreating)[]
    ) => {
        dispatch(
            techMapTemplateModel.updateTechMapTemplate({
                results: results,
            })
        );
    };

    const onCancel = () => {
        if (isCreating) {
            navigate("/tech-map-editor");
        } else {
            dispatch(techMapTemplateModel.setTechMapTemplate(initialTMT));
        }
    };

    return {
        isCreating,
        name,
        techMapTemplate,
        loading,
        initialTMT,
        onChange,
        onChangeTmTResult,
        onCancel,
        setInitialTMT,
    };
};
