import { Button, Modal } from "antd";
import { useEntityRowsKanban } from "../model";
import "./EntityRowsKanban.scss";
import EntityRowsKanbanDesk from "./EntityRowsKanbanDesk";
import { CloseOutlined } from "@ant-design/icons";

interface IEntityRowsKanban {
    entityUuid: string;
    entityId: number;
    openCloseDrawerWithNavigate: (isOpenDrawer: boolean, entityRowId?: number) => void;
}
const EntityRowsKanban = ({
    entityUuid,
    entityId,
    openCloseDrawerWithNavigate,
}: IEntityRowsKanban) => {
    const {
        isOpenFullscreen,
        entityFields,
        entityFieldValues,
        choosenEntityField,
        choosenVisibleFieldsOptions,
        isLoading,
        isPaginationLoading,
        entityRowsByEntityField,
        setChoosenEntityFieldId,
        setChoosenVisibleFieldsOptionsKeys,
        onDragEndFunc,
        loadEntityRowsByPagination,
        onOpen,
        onClose,
    } = useEntityRowsKanban(entityId, entityUuid);

    return (
        <>
            <Modal
                destroyOnClose
                open={isOpenFullscreen}
                wrapClassName="entity-rows-kanban"
                onCancel={onClose}
                footer={<></>}
                styles={{ header: { position: "absolute" } }}
                title={<Button icon={<CloseOutlined />} onClick={onClose} type="text" />}
                closeIcon={false}
            >
                <EntityRowsKanbanDesk
                    entityId={entityId}
                    entityUuid={entityUuid}
                    entityFields={entityFields}
                    choosenEntityField={choosenEntityField}
                    choosenVisibleFieldsOptions={choosenVisibleFieldsOptions}
                    isLoading={isLoading}
                    heightOfKanban="92vh"
                    entityFieldValues={entityFieldValues}
                    entityRowsByEntityField={entityRowsByEntityField}
                    isPaginationLoading={isPaginationLoading}
                    setChoosenEntityFieldId={setChoosenEntityFieldId}
                    setChoosenVisibleFieldsOptionsKeys={
                        setChoosenVisibleFieldsOptionsKeys
                    }
                    onOpen={onOpen}
                    onDragEndFunc={onDragEndFunc}
                    loadEntityRowsByPagination={loadEntityRowsByPagination}
                    openCloseDrawerWithNavigate={openCloseDrawerWithNavigate}
                    isFullscreen={isOpenFullscreen}
                    onCloseFullscreen={onClose}
                />
            </Modal>
            <div className="entity-rows-kanban">
                <EntityRowsKanbanDesk
                    entityId={entityId}
                    entityUuid={entityUuid}
                    entityFields={entityFields}
                    choosenEntityField={choosenEntityField}
                    choosenVisibleFieldsOptions={choosenVisibleFieldsOptions}
                    isLoading={isLoading}
                    entityFieldValues={entityFieldValues}
                    entityRowsByEntityField={entityRowsByEntityField}
                    isPaginationLoading={isPaginationLoading}
                    setChoosenEntityFieldId={setChoosenEntityFieldId}
                    setChoosenVisibleFieldsOptionsKeys={
                        setChoosenVisibleFieldsOptionsKeys
                    }
                    onOpen={onOpen}
                    onDragEndFunc={onDragEndFunc}
                    loadEntityRowsByPagination={loadEntityRowsByPagination}
                    openCloseDrawerWithNavigate={openCloseDrawerWithNavigate}
                />
            </div>
        </>
    );
};

export default EntityRowsKanban;
