import { Select } from "antd";
import { useEntityFieldSelect } from "../model";

interface IEntityFieldSelect {
    entityId?: number;
    entityUuid?: string;
    fieldKey: string | null;
    fieldDatatypeTypes?: TFieldDatatype[];
    onChange: (fieldKey: string, entityField: TEntityField) => void;
}

const EntityFieldSelect = ({
    entityId,
    entityUuid,
    fieldKey,
    fieldDatatypeTypes,
    onChange,
}: IEntityFieldSelect) => {
    const { fieldsOptions, _onChange } = useEntityFieldSelect({
        entityId,
        entityUuid,
        fieldKey,
        fieldDatatypeTypes,
        onChange,
    });
    return (
        <Select
            style={{ width: "100%" }}
            value={fieldKey}
            // @ts-ignore
            onChange={_onChange}
            options={fieldsOptions}
            filterOption={(input: any, option) => {
                return option?.item?.name?.includes(input) === true;
            }}
        />
    );
};

export default EntityFieldSelect;
