export const getInitialEditingCreatingEntityRowForCapabilitiesRoles = (
    role: TUserRole
): TCapabilitiesRolesRow => ({
    entity_uuid: null,
    role_id: String(role.id),
    read: 0,
    create: 0,
    update: 0,
    delete: 0,
    read_fields: null,
    update_fields: null,
    create_fields: null,
    hidden_fields: null,
    assigned_fields: null,
    constraints: null,
    id: null,
});

export const getOptionsByType = (
    type: TCapType,
    options: { value: string; label: string }[]
) => {
    return [
        {
            value: "",
            label: type === "hidden_fields" ? "-" : "Все",
        },
        ...options,
    ];
};
