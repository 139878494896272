import { useDispatch, useSelector } from "react-redux";
import { selectCalculatedValues } from "./CalculatedValuesSelectors";
import { useEffect } from "react";
import { loadCalculatedValuesThunk } from "./CalculatedValuesThunk";

export const useLoadCalculatedValues = (preload?: boolean) => {
    const calculatedValues = useSelector(selectCalculatedValues);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (preload) {
            loadCalculatedValues();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preload]);

    const loadCalculatedValues = () => {
        dispatch(loadCalculatedValuesThunk());
    };
    return {
        calculatedValues,
        loadCalculatedValues,
    };
};
