import { SelectEntityRow } from "@entities/Portal/EntityRow";

interface ITMTResultItemEntityRow {
    entityUuid: string;
    entityRowId: number | null;
    onChangeEntityRowId: (value: number, entityRow: any) => void;
}

const TMTResultItemEntityRow = ({
    entityUuid,
    entityRowId,
    onChangeEntityRowId,
}: ITMTResultItemEntityRow) => {
    return (
        <SelectEntityRow
            byUuid
            entityInfo={entityUuid}
            value={entityRowId}
            onChange={onChangeEntityRowId}
        />
    );
};

export default TMTResultItemEntityRow;
