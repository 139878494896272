import { calculatedValueModel } from "@entities/Portal/CalculatedValues";
import { parseFormResponse } from "@shared/api/ParseResponse";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

export const useCreateUpdateCalculatedValue = (
    editingCalculatedValue: TCalculatedValue | null,
    onSuccess?: (calculatedValue: TCalculatedValue, isCreating?: boolean) => void
) => {
    const dispatch = useDispatch<AppDispatch>();
    const [isOpen, setIsOpen] = useState(false);
    const [calculatedValue, setCalculatedValue] = useState<TCalculatedValue | null>(null);
    const [error, setError] = useState<TValidationError>({});
    const [loading, setLoading] = useState(false);
    const onChangeFieldComparisonTypes = (
        value: number[],
        options: TComparisonOption | TComparisonOption[]
    ) => {
        if (calculatedValue)
            setCalculatedValue({
                ...calculatedValue,
                comparison_types: (Array.isArray(options) ? options : [options]).map(
                    (option) => option.item
                ),
            });
    };
    useEffect(() => {
        setCalculatedValue(editingCalculatedValue);
        if (editingCalculatedValue) {
            setIsOpen(true);
        } else {
            setIsOpen(false);
        }
    }, [editingCalculatedValue]);

    const onChangeCalculatedValueValue = (value: any) => {
        setCalculatedValue(value);
    };

    const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (calculatedValue)
            setCalculatedValue({
                ...calculatedValue,
                [event.target.name]: event.target.value,
            });
    };

    const onClose = () => {
        setIsOpen(false);
        setError({});
        dispatch(calculatedValueModel.setEditingCalculatedValue(null));
    };
    const onOk = () => {
        setError({});
        setLoading(true);
        if (calculatedValue) {
            dispatch(
                calculatedValue.is_creating
                    ? calculatedValueModel.createCalculatedValueThunk(calculatedValue)
                    : calculatedValueModel.updateCalculatedValueThunk(calculatedValue)
            )
                .then(parseFormResponse)
                .then((data: TResponse<TCalculatedValue>) => {
                    onSuccess && onSuccess(calculatedValue, calculatedValue.is_creating);
                    onClose();
                })
                .catch((error: TValidationError) => {
                    console.log("error", error);
                    setError(error);
                    notificationEmit({
                        error: error,
                        title: error.message ?? "Ошибка",
                        type: "error",
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    return {
        onClose,
        onChangeName,
        onOk,
        onChangeCalculatedValueValue,
        onChangeFieldComparisonTypes,
        error,
        loading,
        isOpen,
        calculatedValue,
    };
};
