import { loadEntityFieldsThunk } from "@entities/Portal/EntityField/model";
import { useFilter } from "@features/Portal/Filters";
import { FilterKit } from "@shared/ui/Filters";
import { useDispatch } from "react-redux";
import { getValidParams } from "../lib/getValidParams";
import "./EntityFieldsFIlters.scss";

interface IEntityFieldsFilters {
    entityId: number;
    entityUuid?: string;
}

const EntityFieldsFilters = ({ entityId, entityUuid }: IEntityFieldsFilters) => {
    const dispatch = useDispatch<AppDispatch>();

    const request = (values: any, reset: boolean) => {
        const conditions: any[] = getValidParams({
            ...values,
            entity_uuid: entityUuid,
            id: entityId,
        });
        const parameters: TMultipleCondition = {
            condition_type: "AND",
            conditions: conditions.length > 0 ? conditions : [],
        };
        if (!reset && parameters) {
            dispatch(
                loadEntityFieldsThunk({
                    entityId,
                    params:
                        parameters.conditions.length > 0 ? { filter: parameters } : {},
                })
            );
        } else {
            dispatch(loadEntityFieldsThunk({ entityId }));
        }
    };

    const initialState = {
        name: null,
    };

    const { onChange, onReset, values, onSubmit } = useFilter(initialState, request);

    return (
        <div className="entity-fields-filter">
            <FilterKit
                hideLabel={true}
                onSubmit={onSubmit}
                onChange={onChange}
                onReset={onReset}
                fields={[
                    {
                        type: "input",
                        label: "Наименование",
                        name: "name",
                        value: values.name,
                    },
                ]}
            />
        </div>
    );
};

export default EntityFieldsFilters;
