import { FlexContainer } from "@shared/ui/Containers/FlexContainer";

interface IDropdownGroup {
    group: TDropdownVariablesGroup;
    onSelectItem: (item: TDropdownItem) => void;
}

const DropdownGroup = ({ group, onSelectItem }: IDropdownGroup) => {
    return (
        <>
            {group.items.length > 0 ? (
                group.items.map((item) => {
                    const onClick = () => {
                        onSelectItem(item);
                    };
                    return (
                        <div
                            key={`${item.variable_type}-${item.id}`}
                            className="input-dropdown__item"
                            onClick={onClick}
                        >
                            {item.name}
                        </div>
                    );
                })
            ) : (
                <FlexContainer type="center">
                    <p>Нет доступных значений</p>
                </FlexContainer>
            )}
        </>
    );
};

export default DropdownGroup;
