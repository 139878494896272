import { useEffect, useState } from "react";
import { maxWidth, numRegexp } from "../constants";
import { calculateScrollTopByChoosenPage } from "../lib";

export const usePdfViewerHeader = (
    loaded: number,
    numPages: number | undefined,
    setCurrentNumPage: (item: number) => void,
    containerRef: React.MutableRefObject<HTMLElement | null>,
    containerWidth: number | undefined,
    scale: number,
    rescale: (type?: "decrease" | "increase", value?: number) => void
) => {
    const [isVisibleProgressBar, seIsVisibleProgressBar] = useState<boolean>(true);

    useEffect(() => {
        if (loaded === 100) {
            setTimeout(() => {
                seIsVisibleProgressBar(false);
            }, 700);
        }
    }, [loaded]);

    const onChangePageNumberInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (numPages && numRegexp.test(value)) {
            const resultPage = +value <= numPages ? +value : numPages;
            setCurrentNumPage(resultPage);
            containerRef?.current?.scrollTo({
                top: calculateScrollTopByChoosenPage(
                    resultPage,
                    containerWidth,
                    maxWidth,
                    scale
                ),
            });
        }
    };
    const onInputRescale = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.replace(/%/g, "");
        const number = numRegexp.test(value) ? +value.replace(/%/g, "") : 0;
        rescale(undefined, number / 100);
    };
    const onWheelRescale = (event: React.WheelEvent<HTMLInputElement>) => {
        if (event.deltaY < 0) {
            rescale("increase");
        } else {
            rescale("decrease");
        }
    };

    return {
        isVisibleProgressBar, 
        onChangePageNumberInput,
        onInputRescale,
        onWheelRescale,
    }
};
