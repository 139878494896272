import { openDrawer } from "@shared/ui/DrawerManager";
import "./TechMapRolesList.scss";
import { TECH_ROLES_TABLE } from "../constants";

interface ITechMapRolesList {
    roles: TUserRole[];
    label?: string;
}

const TechMapRolesList = ({ roles, label }: ITechMapRolesList) => {
    const onClickRole = (roleId: number) => {
        openDrawer({
            id: roleId,
            name: TECH_ROLES_TABLE,
            pattern: "/erp",
        });
    };

    return (
        <div className="roles-list display-inline">
            <span className="roles-list__label">{`${label ?? "Роли"}: `}</span>

            {roles.map((role, index, arr) => {
                return (
                    <div className="role-item" key={index}>
                        <span
                            className="role-item__name link"
                            onClick={(e) => {
                                e.stopPropagation();
                                onClickRole(role.id);
                            }}
                        >{`${role.name}`}</span>
                        {index < arr.length - 1 ? (
                            <span className="role-item__dot">,</span>
                        ) : undefined}
                    </div>
                );
            })}
        </div>
    );
};

export default TechMapRolesList;
