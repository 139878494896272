import { contrastColor } from "@shared/lib/colors";
import "./StagesEntityRow.scss";
import { Spin, Typography } from "antd";

interface IStageEntityRowItem {
    stage: TStage;
    specificColor?: string;
    loading?: boolean;
    onClickStage?: (stage: TStage) => void;
    borderAround?: boolean;
}

const StageEntityRowItem = ({
    stage,
    specificColor,
    loading,
    onClickStage,
    borderAround,
}: IStageEntityRowItem) => {
    const backgroundColor = specificColor ?? "#ffffff";
    const onClick = () => {
        onClickStage && onClickStage(stage);
    };
    const colorOfBorder = `${!specificColor ? stage.background_color : "#fff0"}`;
    const borderBottom = `1px solid ${colorOfBorder}`;
    const fullBorder = borderAround ? `1px solid ${colorOfBorder}` : undefined;
    return (
        <div
            className={`stage-entity-row`}
            style={{
                cursor: onClickStage ? "pointer" : undefined,
                userSelect: "none",
            }}
            onClick={onClickStage ? onClick : undefined}
        >
            <div
                style={{
                    backgroundColor,
                    ...(borderAround ? { border: fullBorder } : { borderBottom }),
                }}
                className="stage-entity-row__content"
            >
                <Typography.Text style={{ color: contrastColor(backgroundColor) }}>
                    {stage.label}
                    {loading && <Spin style={{ marginLeft: 5 }} />}
                </Typography.Text>
            </div>
            <div
                className="stage-entity-row__arrow"
                style={{
                    backgroundColor,
                    borderBottom: borderAround ? undefined : borderBottom,
                }}
            />
        </div>
    );
};

export default StageEntityRowItem;
