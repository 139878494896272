import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "./Office365Viewer.scss";
import "@cyntler/react-doc-viewer/dist/index.css";
import { Button, Flex } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { apiUrl } from "@shared/api/api";
import { getFileExtenstion } from "../FileUpload/lib";

const Office365Viewer = ({ linkToFile }: { linkToFile: string }) => {
    const fileExt = getFileExtenstion(linkToFile);
    const fileName = linkToFile
        .split("/")
        .map((str) => (str.toLowerCase() === fileExt ? fileExt : str))
        .reverse()[0];

    const docs = [
        {
            uri: `${process.env.REACT_APP_API_URL}/api/file?link=${linkToFile}&lang=ru-RU`,
            fileName,
        },
    ];

    return (
        <Flex className="Office-viewer">
            <Flex
                className="Office-viewer__header"
                style={{ height: 62 }}
                justify="space-between"
                wrap="wrap"
            >
                <Flex align="center">
                    <div className="Office-viewer__file-name" style={{}}>
                        {fileName}
                    </div>
                </Flex>
                <Flex align="center">
                    <Button
                        onClick={() =>
                            window.open(
                                `${process.env.REACT_APP_API_URL}/api/file?link=${linkToFile}`
                            )
                        }
                        style={{ color: "white" }}
                        icon={<DownloadOutlined />}
                        type="text"
                    />
                </Flex>
            </Flex>
            <Flex className="Office-viewer__container">
                <DocViewer
                    prefetchMethod="GET"
                    language="ru"
                    documents={docs}
                    config={{
                        header: {
                            disableHeader: true,
                        },
                    }}
                    style={{ height: "100%" }}
                    initialActiveDocument={{
                        uri: `${apiUrl}/api/file?link=${linkToFile}&lang=ru-RU`,
                        fileName,
                    }}
                    requestHeaders={{
                        "Access-Control-Request-Method": "GET",
                        Authorization: `Bearer ${localStorage.getItem("access_token")!}`,
                        "Accept-Language": "ru-Ru",
                    }}
                    pluginRenderers={DocViewerRenderers}
                />
                <div
                    style={{
                        background: "var(--dark-color)",
                        height: 25,
                        position: "absolute",
                        bottom: 0,
                        width: "100%",
                    }}
                />
            </Flex>
        </Flex>
    );
};

export default Office365Viewer;
