import { TMOperationsList } from "@entities/Production/TechMap";
import { techMapTemplateLib } from "@entities/Production/TechMapTemplate";
import { Popover } from "antd";

interface ITMTableOperations {
    tm: TTechMapStarted;
}

const TMTableOperations = ({ tm }: ITMTableOperations) => {
    const tmtOperations = techMapTemplateLib.getTemplateWithMapOperations(tm);
    return (
        <Popover
            content={
                <div>
                    <TMOperationsList
                        small
                        templateOperationsWithMapOperation={tmtOperations}
                        loading={false}
                    />
                </div>
            }
            title="Операции"
            trigger="hover"
        >
            <span
                style={{
                    padding: 5,
                    cursor: "pointer",
                }}
            >
                {tm.operations.length} / {tm.template.operations.length}
            </span>
        </Popover>
    );
};

export default TMTableOperations;
