import { aspectRatioOfPdfPage, defaultMarginBetweenPages } from "./constants";

export const calculateScrollTopByChoosenPage = (
    choosenPage: number,
    containerWidth: number | undefined,
    maxWidth: number,
    scale: number,
    withoutMargin?: boolean
) => {
    return (
        (choosenPage - 1) *
            ((containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth) *
                aspectRatioOfPdfPage) *
            scale +
        (withoutMargin ? 0 : defaultMarginBetweenPages * (choosenPage - 1))
    );
};

export const getConditionForScrollingBottom = (
    choosenPage: number,
    containerWidth: number | undefined,
    maxWidth: number,
    scale: number,
    withoutMargin?: boolean
) => {
    return (
        choosenPage *
            (containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth) *
            aspectRatioOfPdfPage *
            scale +
        (withoutMargin ? 0 : defaultMarginBetweenPages * (choosenPage - 1))
    );
};
export const onZoomDisablingEvent = (event: WheelEvent) => {
    if (event.ctrlKey) {
        event.preventDefault();
    }
};

export const getPercents = (number: number) => number * 100;
