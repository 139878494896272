export const selectEditingEntityRowsIds = (state: RootState): number[] =>
    state.capabilitiesRoles.editingCapabilitiesRolesIds;
export const selectUserRoles = (state: RootState): TUserRole[] =>
    state.capabilitiesRoles.userRoles;
export const selectCapabilitiesRolesLoading = (state: RootState): boolean =>
    state.capabilitiesRoles.capabilitiesRolesLoading;
export const selectUserRolesLoading = (state: RootState): boolean =>
    state.capabilitiesRoles.userRolesLoading;
export const selectCurrentPage = (state: RootState): number =>
    state.capabilitiesRoles.currentPage;
export const selectLastPage = (state: RootState): number =>
    state.capabilitiesRoles.lastPage;
export const selectInitialCapabilitiesRoles = (
    state: RootState
): TCapabilitiesRolesInitialValues => state.capabilitiesRoles.initialCapabilitiesRoles;
export const selectCapabilitiesRoles = (state: RootState): TCapabilitiesRolesTableData =>
    state.capabilitiesRoles.capabilitiesRoles;
