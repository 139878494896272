import { Select } from "antd";
import { useTMGroupSelect } from "../model";
import { techMapConstant } from "@entities/Production/TechMap";
import { FormItem } from "@shared/ui/Form";

interface ITMGroupSelect {}

const TMGroupSelect = ({}: ITMGroupSelect) => {
    const { groupLevels, onChange } = useTMGroupSelect();
    return (
        <FormItem
            style={{ width: "100%", maxWidth: 300, marginRight: 10 }}
            label="Группировка результата"
        >
            <Select
                style={{ width: "100%" }}
                mode="multiple"
                value={groupLevels}
                options={techMapConstant.groupOptions}
                onChange={onChange}
            />
        </FormItem>
    );
};

export default TMGroupSelect;
