import { userModel, UserShortProfile } from "@entities/Portal/User";
import { Flex } from "antd";
import { useSelector } from "react-redux";
import { useSidebar } from "../Sidebar/model";

interface IIFrameViewerDrawerHeader {
    onClose: () => void;
    name: string | undefined;
}

const IFrameViewerDrawerHeader = ({ name, onClose }: IIFrameViewerDrawerHeader) => {
    const { badges } = useSidebar(true);
    const user = useSelector(userModel.selectUser);
    return (
        <Flex
            style={{ padding: 0, width: "100%" }}
            justify="space-between"
            align="center"
        >
            <div
                style={{
                    margin: 0,
                    width: "fit-content",
                    fontSize: 28,
                    textWrap: "nowrap",
                }}
            >
                {name}
            </div>
            <UserShortProfile
                onNotificationBellClickAdditionalAction={onClose}
                user={user}
                badges={badges}
                showNotificationBell={true}
            />
        </Flex>
    );
};

export default IFrameViewerDrawerHeader;
