import { userModel } from "@entities/Portal/User";
import { techMapModel } from "@entities/Production/TechMap";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

export const useTMTableWs = () => {
    const dispatch = useDispatch();
    const userId = useSelector(userModel.selectUserId);

    const channel = `User.${userId}.TechnicalMap`;
    useEffect(() => {
        window.Echo.private(channel)
            .listen(".create", ({ technicalMap }: { technicalMap: TTechMapStarted }) => {
                dispatch(techMapModel.addTMPaginated(technicalMap));
            })
            .listen(".update", ({ technicalMap }: { technicalMap: TTechMapStarted }) => {
                if (technicalMap.status === "stopped")
                    dispatch(techMapModel.deleteTMPaginated(technicalMap));
                else dispatch(techMapModel.updateTMPaginated(technicalMap));
            })
            .listen(".delete", ({ technicalMap }: { technicalMap: TTechMapStarted }) => {
                dispatch(techMapModel.deleteTMPaginated(technicalMap));
            });

        return () => {
            window.Echo.leave(channel);
        };
    }, [channel]);
};
