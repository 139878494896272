export const deviceStateTag = {
    connected: {
        color: "success",
        label: "Подключено",
    },
    connecting: {
        color: "processing",
        label: "Подключается",
    },
    disconnected: {
        color: "error",
        label: "Нет подключения",
    },
};
export const deviceStatusTag = {
    idle: {
        color: "magenta",
        label: "Ожидание карты",
    },
    write_rfid: {
        color: "processing",
        label: "Запись карты",
    },
};
