import { estimationLib } from "@entities/Portal/Estimations";

export const getEntityRowsCategories = (entityRows: any[]) => {
    const categories: string[] = [];
    entityRows.forEach((row) => {
        if (row.category && !categories.includes(row.category)) {
            categories.push(row.category);
        }
    });

    return categories;
};

export const localStorageDataSaveOrDelete = (condition: boolean, entityId: number) => {
    condition
        ? localStorage.setItem(
              estimationLib.getAdditionalCalculationStorageKey(entityId),
              `${true}`
          )
        : localStorage.removeItem(
              estimationLib.getAdditionalCalculationStorageKey(entityId)
          );
};

export const getSpecialOptionsForStageSelect = (
    allCategories: TCategory[],
    stages: TStage[]
) => {
    const categoryUuids = allCategories.map(({ uuid }) => uuid);
    return stages
        .filter((stage) => categoryUuids.includes(stage.category_uuid))
        .map((stage) => {
            const parentCategoryOfStage = allCategories.find(
                (category) => category.uuid === stage.category_uuid
            )!;
            return {
                value: stage.id,
                label: stage!.label + `${` (${parentCategoryOfStage.label})`}`,
                option: { stage, category: parentCategoryOfStage },
            };
        });
};
