const accessToken = localStorage.getItem("access_token");

export const pagesListVariants = {
    open: {
        width: 162,
        transition: {
            duration: 0.15,
        },
    },
    closed: {
        width: 0,
        transition: {
            duration: 0.15,
        },
        overflow: "hidden",
    },
};
export const PDFoptions = {
    cMapUrl: "/cmaps/",
    httpHeaders: {
        authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/pdf",
    },
    enableXfa: true,
};
export const aspectRatioOfPdfPage = 1.25;
export const defaultMarginBetweenPages = 12;
export const numRegexp = /^-?\d*(\.\d*)?$/;
export const maxWidth = 800;
export const resizeObserverOptions = {};
export const animatedProgessVariants = {
    visible: {
        opacity: 1,
    },
    hidden: {
        opacity: 0,
        transition: {
            duration: 0.3,
        },
    },
};
