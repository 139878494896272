import { parseErrorMessageByKey } from "@shared/api/ParseResponse";
import { FlexContainer } from "@shared/ui/Containers/FlexContainer";
import { FormItem } from "@shared/ui/Form";
import { ErrorText } from "@shared/ui/Text";
import { Button, Checkbox, Divider, Input, Space } from "antd";
import { ReactNode } from "react";
import { useEntityFieldUpdatingForm } from "../model";
import CreateUpdateDefaultValue from "./CreateUpdateDefaultValue";
import "./EntityFieldUpdatingForm.scss";
interface IEntityFieldUpdatingForm {
    initialEntityField: TEntityField;
    onCancel?: () => void;
    onSuccessSave?: () => void;
    datatypeDependingInputs?: <T>(props: {
        editingField: T;
        error?: TValidationError;
        setEditingField: (editingField: T) => void;
    }) => ReactNode;
}

const EntityFieldUpdatingForm = ({
    initialEntityField,
    onCancel,
    onSuccessSave,
    datatypeDependingInputs,
}: IEntityFieldUpdatingForm) => {
    const {
        error,
        editingEntityField,
        loading,
        setEditingEntityField,
        onChangeView,
        onSave,
        onChangeInput,
        onChangeCalculation,
        onChangeCreateUpdateValue,
        onChangeIsCreateUpdateCheckbox,
        onChangeUnwritable,
        onChangeIsName,
        onChangeIsDesc,
    } = useEntityFieldUpdatingForm(initialEntityField, onSuccessSave);

    return (
        <div className="entity-field-updating-form">
            <FormItem label="Тип данных">
                <>
                    <Input
                        name="datatype"
                        disabled
                        value={editingEntityField?.datatype}
                    />
                    <div className="entity-field-updating-form__datatype-description">
                        {editingEntityField.field_datatype?.description}
                    </div>
                </>
            </FormItem>

            <FormItem label="Наименование">
                <>
                    <Input
                        name="name"
                        status={error.data?.["name"] ? "error" : undefined}
                        value={editingEntityField?.name}
                        onChange={onChangeInput}
                    />
                    <ErrorText text={parseErrorMessageByKey(error, "name")} />
                </>
            </FormItem>

            <FormItem label="Ключ">
                <>
                    <Input
                        name="key"
                        value={editingEntityField?.key}
                        status={error.data?.["key"] ? "error" : undefined}
                        onChange={onChangeInput}
                        disabled={editingEntityField.is_system}
                    />
                    <ErrorText text={parseErrorMessageByKey(error, "key")} />
                </>
            </FormItem>

            <div className="entity-field-updating-form__options">
                <FormItem>
                    <Checkbox
                        onChange={onChangeIsName}
                        checked={editingEntityField?.is_name}
                    >
                        Используется для заголовка (Имя)
                    </Checkbox>
                </FormItem>

                <FormItem>
                    <Checkbox
                        onChange={onChangeIsDesc}
                        checked={editingEntityField?.is_description}
                    >
                        Используется для описания
                    </Checkbox>
                </FormItem>
                <Divider style={{ margin: 8 }} />
                <FormItem>
                    <Checkbox onChange={onChangeView} checked={editingEntityField?.view}>
                        Используется для предпросмотра
                    </Checkbox>
                </FormItem>
                <FormItem>
                    <Checkbox
                        disabled={editingEntityField.is_system}
                        onChange={onChangeUnwritable}
                        checked={editingEntityField?.is_unwritable}
                    >
                        Только чтение
                    </Checkbox>
                </FormItem>
                {datatypeDependingInputs &&
                    datatypeDependingInputs({
                        error,
                        editingField: editingEntityField,
                        setEditingField: setEditingEntityField,
                    })}
                <Divider style={{ margin: 8 }} />
                <CreateUpdateDefaultValue
                    setEditingEntityField={setEditingEntityField}
                    editingEntityField={editingEntityField}
                    onChangeCalculation={onChangeCalculation}
                    onChangeCreateUpdateValue={onChangeCreateUpdateValue}
                    onChangeIsCreateUpdateCheckbox={onChangeIsCreateUpdateCheckbox}
                />
            </div>

            <FlexContainer type={"row-end"}>
                <Space>
                    {onCancel && <Button onClick={onCancel}>Отмена</Button>}
                    <Button loading={loading} type="primary" onClick={onSave}>
                        Сохранить
                    </Button>
                </Space>
            </FlexContainer>
        </div>
    );
};

export default EntityFieldUpdatingForm;
