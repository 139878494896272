import { estimationLib, estimationModel } from "@entities/Portal/Estimations";
import {
    EstimationValuesListItem,
    EstimationValuesListItemWithOptions,
} from "@features/Portal/EstimationFeatures/EstimationValues";
import { ErrorBoundary } from "@shared/ui/ErrorBoundary";
import { Empty, Flex, Spin } from "antd";

const AdditionalEstimationsList = ({ entityId }: { entityId: number }) => {
    const { additionalValues, loading, error } =
        estimationModel.useLoadAdditionalEstimation(entityId);

    if (error)
        return (
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "red",
                }}
            >
                Не удалось выполнить суммирование
            </div>
        );

    if (additionalValues && additionalValues?.length > 0)
        return (
            <ErrorBoundary additionalInfo={{ entityId, additionalValues }}>
                <Spin spinning={loading}>
                    <Flex style={{ width: "100%" }} wrap="wrap" gap={16}>
                        {additionalValues?.map((value, index) => {
                            if (
                                estimationLib.isEstimationValueTypeWithOptions(
                                    value.datatype
                                )
                            )
                                return (
                                    <EstimationValuesListItemWithOptions
                                        key={index}
                                        templateValue={value}
                                        entityId={entityId}
                                    />
                                );
                            return (
                                <EstimationValuesListItem
                                    key={index}
                                    value={value.value}
                                    name={value.name}
                                    datatype={value.datatype}
                                />
                            );
                        })}
                    </Flex>
                </Spin>
            </ErrorBoundary>
        );

    return (
        <>
            <Empty
                style={{ margin: 0 }}
                imageStyle={{ width: "100%", height: 30, marginBottom: 0 }}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
        </>
    );
};

export default AdditionalEstimationsList;
