import { v4 as uuid } from "uuid";

export const getTechMapTmplOperationMock = (
    tmtUuid: string | null,
    parentUuid: string | null
): TTechMapTemplateOperationCreating => {
    const operationUuid = uuid();
    return {
        uuid: operationUuid,
        technical_map_template_uuid: tmtUuid ?? "temp",
        name: "Операция",
        order: null,
        next_uuid: null,
        child_operations: [],
        parent_operation: null,
        parent_uuid: parentUuid,
        necessary_items: [],
    };
};

export const reorderOperations = (operations: TTechMapTemplateOperation[]) => {
    const initial = operations.find((item) => item.parent_uuid === null);
    if (!initial) return [];
    return [initial, ...reorderAdditionalOperations(operations, initial.uuid)];
};

const reorderAdditionalOperations = (
    operations: TTechMapTemplateOperation[],
    initUuid: string
) => {
    return operations.reduce((prev, current, index, operations) => {
        if (prev.length === 0) {
            const initialStage = operations.find((item) => item.parent_uuid === initUuid);
            if (!initialStage) return [];
            return [initialStage] as TTechMapTemplateOperation[];
        } else {
            const nextOperation = operations.find(
                (item) => item.parent_uuid === prev[prev.length - 1].uuid
            );
            if (nextOperation) {
                return [...prev, nextOperation];
            }
        }
        return prev;
    }, [] as TTechMapTemplateOperation[]);
};

export const getTemplateWithMapOperations = (startedTm: TTechMapStarted | null) => {
    if (!startedTm) return [];
    const templateOperations =
        startedTm?.template.operations ?? ([] as TTechMapTemplateOperation[]);
    const ordered = reorderOperations(templateOperations);
    return ordered.map((tmtOperation) => {
        const mapOperation = startedTm.operations.find(
            (operation) =>
                operation.technical_map_template_operation_uuid === tmtOperation.uuid
        );
        return {
            operation: mapOperation,
            templateOperation: tmtOperation,
        };
    });
};

export const getTechMapTemplateResultMock = (): TTechMapTemplateResultCreating => {
    return {
        type: "create",
        entity_row_id: null,
        entity_uuid: null,
        quantity: 1,
        creating: uuid(),
    };
};
