import { useState } from "react";

export const useTMActionsQtyModal = (onApprove: (count: number) => void) => {
    const [qty, setQty] = useState(1);

    const onChangeQty = (value: number) => {
        setQty(value);
    };

    const onOk = () => {
        onApprove(qty);
    };
    return { qty, onChangeQty, onOk };
};
