import { emitter } from "@shared/emitter";
import { useEffect, useRef, useState } from "react";
import { PAPER_SIZE, PRINTER_EMIT } from "../constants";
import { Zebra } from "../lib/Printers/Zebra";
import { StickerPrinter } from "../lib/StickerPrinter";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";

export const useStickerPrinter = (onChangeConnectedState?: (state: boolean) => void) => {
    const printer = useRef<StickerPrinter | null>();
    const [printerState, setPrinterState] = useState<TPrinterState>({
        isConnected: false,
        connecting: false,
        printing: false,
    });
    const [printingItems, setPrintingItems] = useState<TPrinterCodeItem[]>([]);

    const onChangePrinterState = (param: keyof TPrinterState, value: any) => {
        setPrinterState((prev) => ({
            ...prev,
            [param]: value,
        }));
    };
    const print = async (code: string, type: TPrinterCodeItemType) => {
        if (!printer.current) await connect();

        if (type === "qrCode") await printer.current!.printQrCode(code);
        if (type === "barCode") await printer.current!.printBarCode(code);
    };

    const connect = async () => {
        printer.current = new Zebra(PAPER_SIZE.PAPER_65x45, onChangePrinterState);
        try {
            await printer.current.connect();
        } catch (e) {
            notificationEmit({
                type: "error",
                title: "Не удалось подключить принтер!",
            });
        }
    };

    useEffect(() => {
        if (printingItems.length > 0 && !printerState.printing) {
            const item = printingItems[0];

            print(item.code, item.type);
            setPrintingItems((items) =>
                items.filter((item_) => item_.code !== item.code)
            );
        }
    }, [printingItems, printerState.printing]);
    useEffect(() => {
        onChangeConnectedState && onChangeConnectedState(printerState.isConnected);
    }, [printerState.isConnected]);
    const handleEvent = (code: string, type: TPrinterCodeItemType) => {
        setPrintingItems((prev) => [
            ...prev,
            {
                code,
                type,
            },
        ]);
    };
    useEffect(() => {
        emitter.on(PRINTER_EMIT, handleEvent);
        return () => {
            emitter.removeListener(PRINTER_EMIT, handleEvent);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        printerState,
        connect,
        print,
    };
};
