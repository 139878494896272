import { PauseCircleOutlined } from "@ant-design/icons";

interface IStartedTMBarPausedTitle {
    title: string;
}

const StartedTMBarPausedTitle = ({ title }: IStartedTMBarPausedTitle) => {
    return (
        <div>
            <PauseCircleOutlined style={{ marginRight: 5 }} />
            <span>{title}</span>
        </div>
    );
};

export default StartedTMBarPausedTitle;
