import { EntitySelect } from "@entities/Portal/Entity";
import { SelectEntityRow } from "@entities/Portal/EntityRow";

interface ITMTResultSelectOption {
    entityUuid: string | null;
    entityRowId: number | string | null;
    onlyDefaultReadRender?: boolean;
}

const TMTResultSelectOption = ({
    entityUuid,
    entityRowId,
    onlyDefaultReadRender,
}: ITMTResultSelectOption) => {
    if (entityUuid && entityRowId) {
        return (
            <SelectEntityRow
                onlyRead
                byUuid
                onlyDefaultReadRender={onlyDefaultReadRender}
                value={entityRowId}
                entityInfo={entityUuid}
                onChange={() => {}}
            />
        );
    }
    if (!entityUuid) <>Не удалось найти</>;
    return <EntitySelect disabled value={entityUuid} onChangeSelect={() => {}} />;
};

export default TMTResultSelectOption;
