import { Fragment, ReactNode } from "react";
import { useDrawerManager } from "../model";

interface IDrawerManager {
    patterns: TDrawerPattern[];
    drawer: (
        drawerItem: TDrawerManagerItemPrepared,
        closeDrawer: (item: TDrawerManagerItemPrepared) => void,
        level?: number
    ) => ReactNode;
}

const DrawerManager = ({ patterns, drawer }: IDrawerManager) => {
    const { openedDrawers, closeDrawer } = useDrawerManager(patterns);

    return (
        <>
            {openedDrawers.map((drawerItem, index, fullArr) => {
                const level = fullArr.filter((item) => item.isOpen).length - 1 - index;

                return (
                    <Fragment key={drawerItem.location}>
                        {drawer(drawerItem, closeDrawer, level)}
                    </Fragment>
                );
            })}
        </>
    );
};

export default DrawerManager;
