import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { getOperation } from "../../api";
import { setGetStartedOperationLoading, setStartedOperation } from "../TechMapSlice";

export const loadStartedOperation =
    (oId: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(setGetStartedOperationLoading(true));
        try {
            const response = await getOperation(oId);
            dispatch(setStartedOperation(response.data.data));
        } catch (err) {
            notificationEmit({
                type: "error",
                title: "Не удалось загрузить информацию о запущенной операции",
            });
        }
        dispatch(setGetStartedOperationLoading(false));
    };
