import { DeleteOutlined } from "@ant-design/icons";
import { InputWithVariables } from "@entities/Portal/BizProcTemplate";
import { FormInputItem } from "@entities/Portal/Datatype";
import { FormItem } from "@shared/ui/Form";
import { Button, Flex } from "antd";

interface IVariableInputs {
    type: TVariableType;
    isMultiple: boolean;
    isCalculatedValue: boolean;
    variable: TVariable;
    onChangeVariableValue: (value: any) => void;
    itemId: string | number;
}

const VariableInputs = ({
    type,
    isMultiple,
    isCalculatedValue,
    variable,
    onChangeVariableValue,
    itemId,
}: IVariableInputs) => {
    const onChangeValue = (newValue: any, index: number) => {
        isMultiple
            ? onChangeVariableValue(
                  (variable.value! as string[] | number[]).map(
                      (oldValue: any, index_: number) => {
                          return index === index_ ? newValue : oldValue;
                      }
                  )
              )
            : onChangeVariableValue(newValue);
    };

    const onDeleteValueItem = (index: number) => {
        onChangeVariableValue(
            (variable.value! as string[] | number[]).filter(
                (oldValue: any, index_: number) => index !== index_
            )
        );
    };
    return (
        <Flex style={{ flex: "1 1 90%" }} vertical gap={8}>
            {isCalculatedValue ? (
                (variable.value
                    ? Array.isArray(variable.value)
                        ? variable.value
                        : [variable.value]
                    : Array.from({ length: 1 })
                ).map((item: any, index: number) => (
                    <Flex align="center" key={index}>
                        <InputWithVariables
                            type={type}
                            itemId={itemId}
                            isTextArea={true}
                            onChange={(name, value) => onChangeValue(value, index)}
                            name="value"
                            value={item as string}
                            disabled={false}
                        />
                        {isMultiple && (
                            <Button
                                style={{
                                    minHeight: 0,
                                    height: "fit-content",
                                    padding: 0,
                                    margin: 0,
                                }}
                                type="link"
                                icon={<DeleteOutlined />}
                                danger
                                onClick={() => onDeleteValueItem(index)}
                            />
                        )}
                    </Flex>
                ))
            ) : (
                <FormItem label="Значение переменной">
                    <FormInputItem
                        onChange={(value) => onChangeVariableValue(value)}
                        value={variable.value}
                        datatype={variable.datatype}
                        onlyRead={false}
                        field={variable}
                    />
                </FormItem>
            )}
        </Flex>
    );
};

export default VariableInputs;
