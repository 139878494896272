import { ImportButton } from "@features/Portal/Action/Import";
import { EntityFieldItemList } from "@features/Portal/Entity/EntityFieldItemList";
import { EntityRowObserversList } from "@features/Portal/Entity/EntityRowObserversList";
import { FlexContainer } from "@shared/ui/Containers/FlexContainer";
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { useHeaderLayout } from "@shared/ui/Header";
import { CategoriesListWithBizProcTemplates } from "@widgets/Portal/CategoryWidgets/CategoriesListWithBizProcTemplates";
import { CategoriesListWithEditing } from "@widgets/Portal/CategoryWidgets/CategoriesListWithEditing";
import { CreateUpdateEntityForm } from "@widgets/Portal/Entity/CreateUpdateEntity";
import { UpdateCreateEntityField } from "@widgets/Portal/Entity/EntityFieldUpdating";
import { EntityFieldsFilters } from "@widgets/Portal/Filters/EntityFieldsFIlters";
import { Card, Flex, Space, Spin, Tabs, Typography } from "antd";
import "./EntityEditor.scss";
import { useEntityEditor } from "./model";
import { NavigateToEntityRows } from "@features/Portal/EntityRow/NavigationToEntityRows";

interface IEntityEditor {
    entityId: number;
}

const EntityEditor = ({ entityId }: IEntityEditor) => {
    const { entity, loading, datatypes, onTabClick, activeTab } =
        useEntityEditor(entityId);
    useHeaderLayout({
        title: (
            <>
                <span className="entity-name-in-header">{entity?.name}</span>
                {entity && (
                    <div style={{ display: "flex", flexWrap: "nowrap", marginTop: 4 }}>
                        <NavigateToEntityRows entityTableName={entity.table_name} />
                    </div>
                )}
            </>
        ),
    });

    if (loading)
        return (
            <Card>
                <FlexContainer>
                    <Spin />
                </FlexContainer>
            </Card>
        );
    return (
        <div className="entity-editor">
            <Tabs
                onTabClick={onTabClick}
                defaultActiveKey={activeTab ?? "entity-settings"}
                style={{ height: "100%" }}
                size="small"
                items={[
                    {
                        key: "entity-settings",
                        label: "Настройка сущности",
                        children: (
                            <Card bordered={false} className="tab-content-container">
                                <Typography.Title level={5} style={{ marginTop: 0 }}>
                                    Настройка сущности
                                </Typography.Title>
                                <CreateUpdateEntityForm
                                    entityId={entityId}
                                    editingEntity={entity}
                                />
                            </Card>
                        ),
                        style: { height: "100%" },
                    },
                    {
                        key: "field-settings",
                        label: "Настройка полей",
                        children: (
                            <Card bordered={false} className="tab-content-container">
                                <Typography.Title level={5} style={{ marginTop: 0 }}>
                                    Настройка полей
                                </Typography.Title>
                                <Flex
                                    gap={8}
                                    align="center"
                                    wrap="wrap-reverse"
                                    style={{ padding: "0 8px" }}
                                >
                                    <Flex style={{ flex: "1 1 400px" }}>
                                        <UpdateCreateEntityField
                                            datatypes={datatypes}
                                            entityId={entityId}
                                        />
                                    </Flex>
                                    <Flex style={{ flex: "1 1 fit-content" }}>
                                        {entityId && (
                                            <EntityFieldsFilters
                                                entityId={entityId}
                                                entityUuid={entity?.uuid}
                                            />
                                        )}
                                    </Flex>
                                </Flex>
                                <EntityFieldItemList entityId={entityId} view="list" />
                            </Card>
                        ),
                        style: { height: "100%" },
                    },
                    {
                        key: "process-settings",
                        label: "Настройка процессов",
                        children: (
                            <Card bordered={false} className="tab-content-container">
                                {entity && (
                                    <EntityRowObserversList entityUuid={entity?.uuid} />
                                )}
                            </Card>
                        ),
                        style: { height: "100%" },
                    },
                    {
                        key: "categories",
                        label: "Категории",
                        children: (
                            <Card bordered={false} className="tab-content-container">
                                <CategoriesListWithEditing entityId={entityId} />
                            </Card>
                        ),
                        style: { height: "100%" },
                    },
                    {
                        key: "templates",
                        label: "Шаблоны",
                        children: (
                            <Card className="tab-content-container">
                                <IndentContainer>
                                    <Space>
                                        <ImportButton />
                                    </Space>
                                </IndentContainer>

                                <CategoriesListWithBizProcTemplates entityId={entityId} />
                            </Card>
                        ),
                        style: { height: "100%" },
                    },
                ]}
            />
        </div>
    );
};

export default EntityEditor;
