import ActionCard from "./ActionCard";

interface IDocumentGenerateAction {
    onDeleteItem?: () => void;
    templateItem?: TBizProcTemplateAction;
}

const DocumentGenerateAction = ({
    onDeleteItem,
    templateItem,
}: IDocumentGenerateAction) => {
    return (
        <ActionCard
            templateItem={templateItem}
            borderColor="DarkOrange"
            onDeleteItem={onDeleteItem}
            title={templateItem?.name ?? "Генерация документа"}
        ></ActionCard>
    );
};

export default DocumentGenerateAction;
