import { CopyOutlined, FormOutlined, ScissorOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { useBizProcTemplateItemCopyPasteButtons } from "../model";

interface IBizProcTemplateItemCopyPasteButtons {
    type: "copy" | "paste";
    passParentKey?: boolean;
    templateItem: TBizProcTemplateAction;
    onDeleteTemplateItem?: (deletedTemplateItem: TBizProcTemplateAction) => void;
    pasteCopiedBlockOfActions?: (
        value: TBizProcTemplateAction,
        passParentKey?: boolean
    ) => void;
}

const BizProcTemplateItemCopyPasteButtons = ({
    type,
    templateItem,
    passParentKey,
    pasteCopiedBlockOfActions,
    onDeleteTemplateItem,
}: IBizProcTemplateItemCopyPasteButtons) => {
    const { isCopyingMovingMode, onPasteClick, onCopyClick, onCutClick } =
        useBizProcTemplateItemCopyPasteButtons(
            type,
            templateItem,
            pasteCopiedBlockOfActions,
            passParentKey,
            onDeleteTemplateItem
        );

    return (
        <div
            style={{
                position: "absolute",
                right: -30,
                display: "flex",
                flexDirection: "column",
                height: "100%",
                justifyContent: "center",
            }}
        >
            {isCopyingMovingMode && (
                <>
                    {type === "paste" ? (
                        <Tooltip title="Вставить">
                            <Button
                                className="template__item--action-button"
                                icon={<FormOutlined />}
                                type="text"
                                onClick={onPasteClick}
                            />
                        </Tooltip>
                    ) : (
                        <>
                            <Tooltip title="Скопировать">
                                <Button
                                    className="template__item--action-button"
                                    icon={<CopyOutlined />}
                                    type="text"
                                    onClick={onCopyClick}
                                />
                            </Tooltip>
                            <Tooltip title="Вырезать">
                                <Button
                                    className="template__item--action-button"
                                    icon={<ScissorOutlined />}
                                    type="text"
                                    onClick={onCutClick}
                                />
                            </Tooltip>
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default BizProcTemplateItemCopyPasteButtons;
