import { bizProcModel } from "@entities/Portal/BizProc";
import { UseBizProcCountersWS } from "@features/Portal/BizProc/BizProcWebsocket";
import { Flex, Layout, Menu } from "antd";

import { ToggleNavigationEditingModeButton } from "@features/Portal/NavigationRoute/ToggleNavigationEditingMode";
import { FlexContainer } from "@shared/ui/Containers/FlexContainer";
import { useSidebar } from "../model";
import "./Sidebar.scss";
import SidebarEditingMenu from "./SidebarEditingMenu";
import { apiUrl } from "@shared/api/api";
type SidebarProps = {
    setCollapsedMenu: (collapsed: boolean) => void;
    collapsed: boolean;
};

const Sidebar = ({ collapsed, setCollapsedMenu }: SidebarProps) => {
    const {
        menuItems,
        editingMode,
        logo,
        // currentMenuItem,
        // currentParentMenuItem,
        // changeCurrentParrentOpenKeys,
        // checkAccess,
        // onMenuClick,
    } = useSidebar(collapsed);
    const isMobile = window.innerWidth > 1150;
    bizProcModel.useLoadBizProcCounters(true);
    UseBizProcCountersWS();
    return (
        <>
            {isMobile && (
                <Layout.Sider
                    breakpoint="lg"
                    collapsedWidth={window.screen.width < 450 ? 0 : undefined}
                    width={isMobile ? 300 : 0}
                    collapsed={isMobile ? collapsed : false}
                    collapsible={isMobile ? true : false}
                    trigger={null}
                    onCollapse={() => {}}
                    className="sidebar"
                >
                    <Flex
                        vertical
                        className="sidebar-menu-gfdjhgdf"
                        style={{ height: "100%", overflow: "auto" }}
                    >
                        <div>
                            <FlexContainer>
                                <img
                                    src={`${apiUrl}/${logo}`}
                                    alt="logo"
                                    className="sidebar__heading"
                                />
                            </FlexContainer>
                        </div>

                        {!editingMode ? (
                            <Menu
                                theme="light"
                                style={{}}
                                // onOpenChange={(keys) => changeCurrentParrentOpenKeys(keys)}
                                //openKeys={currentParentMenuItem ? [...currentParentMenuItem] : undefined}
                                mode="inline"
                                items={menuItems}
                                //selectedKeys={[currentMenuItem ?? ""]}
                            />
                        ) : (
                            <SidebarEditingMenu menuItems={menuItems} />
                        )}
                        <ToggleNavigationEditingModeButton collapsed={collapsed} />
                    </Flex>
                </Layout.Sider>
            )}
        </>
    );
};

export default Sidebar;
