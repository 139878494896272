import {
    DownloadOutlined,
    LoadingOutlined,
    MenuOutlined,
    MinusOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import { Button, Flex, Input, Progress } from "antd";
import { motion } from "framer-motion";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { animatedProgessVariants } from "../constants";
import { usePdfViewerHeader } from "../model";

interface IPDFViewerHeader {
    error: any;
    loaded: number;
    link: string;
    isLoading: boolean;
    openCloseMenuSidebar: () => void;
    rescale: (type?: "decrease" | "increase", value?: number) => void;
    scale: number;
    fileName?: string | null;
    currentNumPage: number;
    numPages: number | undefined;
    setCurrentNumPage: (item: number) => void;
    containerRef: React.MutableRefObject<HTMLElement | null>;
    containerWidth: number | undefined;
}

const AnimatedProgess = motion(Progress);

const PDFViewerHeader = ({
    error,
    loaded,
    isLoading,
    link,
    openCloseMenuSidebar,
    rescale,
    scale,
    fileName,
    currentNumPage,
    numPages,
    setCurrentNumPage,
    containerRef,
    containerWidth,
}: IPDFViewerHeader) => {
    const {
        isVisibleProgressBar,
        onChangePageNumberInput,
        onInputRescale,
        onWheelRescale,
    } = usePdfViewerHeader(
        loaded,
        numPages,
        setCurrentNumPage,
        containerRef,
        containerWidth,
        scale,
        rescale
    );

    return (
        <Flex align="center" className="PDF__header" wrap="wrap" justify="space-between">
            <Flex className="PDF__header-left-side" align="center" justify="start">
                <Button
                    type="text"
                    className="PDF__scaling-button"
                    onClick={openCloseMenuSidebar}
                    disabled={error || isLoading}
                    icon={<MenuOutlined style={{ color: "white" }} />}
                />
                <Flex align="center">
                    <div className="PDF__file-name" style={{}}>
                        {fileName}
                    </div>
                </Flex>
            </Flex>
            <Flex className="PDF__header-center" wrap="wrap" justify="center">
                <Flex className="PDF__controller" align="center">
                    <Flex className="PDF__page-container" align="center">
                        <Flex align="center" justify="center" style={{ width: "100%" }}>
                            <Input
                                className="PDF__controller-input PDF__controller-input--page-input"
                                value={currentNumPage}
                                onChange={onChangePageNumberInput}
                            />
                            <span style={{ marginLeft: 4, marginRight: 8 }}>/</span>
                            <span style={{ width: 20 }}>
                                {isLoading ? (
                                    <LoadingOutlined
                                        spin
                                        style={{ fontSize: 14, width: 12 }}
                                    />
                                ) : (
                                    numPages ?? "00"
                                )}
                            </span>
                        </Flex>
                    </Flex>
                    <div className="PDF__vertical-separator" />
                    <Flex align="center" className="PDF__scaling">
                        <>
                            <Button
                                type="text"
                                className="PDF__scaling-button"
                                onClick={() => rescale("decrease")}
                                icon={<MinusOutlined />}
                            />

                            <Input
                                onWheel={onWheelRescale}
                                maxLength={4}
                                onChange={onInputRescale}
                                className="PDF__controller-input PDF__controller-input--scale-input"
                                value={`${Math.floor(scale * 100)}%`}
                                defaultValue={scale}
                            />
                            <Button
                                className="PDF__scaling-button"
                                onClick={() => rescale("increase")}
                                type="text"
                                icon={<PlusOutlined />}
                            />
                        </>
                    </Flex>
                </Flex>
            </Flex>
            <Flex className="PDF__header-right-side" wrap="wrap" justify="end">
                <Flex>
                    <Button
                        onClick={() =>
                            window.open(
                                `${process.env.REACT_APP_API_URL}/api/file?link=${link}`
                            )
                        }
                        style={{ color: "white" }}
                        icon={<DownloadOutlined />}
                        type="text"
                    />
                </Flex>
            </Flex>
            {!error && (
                <AnimatedProgess
                    initial={true}
                    variants={animatedProgessVariants}
                    animate={isVisibleProgressBar ? "visible" : "hidden"}
                    style={{
                        width: "100%",
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        padding: 0,
                        margin: 0,
                        lineHeight: "initial",
                    }}
                    type="line"
                    size="small"
                    strokeLinecap="butt"
                    percent={loaded}
                    showInfo={false}
                />
            )}
        </Flex>
    );
};

export default PDFViewerHeader;
