import { Skeleton } from "antd";
import TMOperationItem from "./TMOperationItem";
import "./TMOperationsList.scss";
import { ReactNode } from "react";

interface ITMOperationsList {
    templateOperationsWithMapOperation: TMapOperationWithTemplateO[];
    loading?: boolean;
    small?: boolean;
    operationPopoverNode?: (
        templateOperationsWithMapOperation: TMapOperationWithTemplateO
    ) => ReactNode;
}

const TMOperationsList = ({
    templateOperationsWithMapOperation,
    loading,
    small,
    operationPopoverNode,
}: ITMOperationsList) => {
    return (
        <div className="tm-operations-list__items">
            {loading ? (
                <Skeleton
                    active
                    rootClassName="tm-operation-skeleton"
                    title={false}
                    paragraph={{ rows: 1, width: ["100%"] }}
                />
            ) : (
                templateOperationsWithMapOperation.map((operationPair) => {
                    return (
                        <TMOperationItem
                            operationPopoverNode={operationPopoverNode?.(operationPair)}
                            key={operationPair.templateOperation.id}
                            small={small}
                            templateOperation={operationPair.templateOperation}
                            operation={operationPair.operation}
                        />
                    );
                })
            )}
        </div>
    );
};

export default TMOperationsList;
