import ActionCard from "./ActionCard";

interface INotificationAction {
    onDeleteItem?: () => void;
    templateItem?: TBizProcTemplateAction;
}

const NotificationAction = ({ onDeleteItem, templateItem }: INotificationAction) => {
    return (
        <ActionCard
            templateItem={templateItem}
            borderColor="#1677ff"
            onDeleteItem={onDeleteItem}
            title={templateItem?.name ?? "Уведомление"}
        ></ActionCard>

        // <CardWithHead
        //     noColor
        //     bordered
        //     title={<EditTemplateBar
        //         onDeleteItem={onDeleteItem}
        //         textColor="black"
        //         title={templateItem?.name ?? "Уведомление"} />}
        // />
    );
};

export default NotificationAction;
