import { techMapModel } from "@entities/Production/TechMap";
import { techMapTemplateLib } from "@entities/Production/TechMapTemplate";
import { useMemo } from "react";

interface IUseTMFullInfo {
    tmId: number;
}

export const useTMFullInfo = ({ tmId }: IUseTMFullInfo) => {
    const { techMap, loading } = techMapModel.useLoadFullTechMap({ tmId });
    const templateOperationsWithMapOperation = useMemo(() => {
        if (!techMap) return [];
        return techMapTemplateLib.getTemplateWithMapOperations(techMap);
    }, [techMap?.operations, techMap?.is_paused]);

    return {
        templateOperationsWithMapOperation,
        techMap,
        loading,
    };
};
