import { ChangeEvent, useRef, useState } from "react";

export const useTMTONecessaryItemForm = (
    item: TOperationNecessaryItem | TOperationNecessaryItemCreating,
    onSubmit: (item: TOperationNecessaryItem | TOperationNecessaryItemCreating) => void
) => {
    const [formItem, setFormItem] = useState(item);
    const [onUseActionsOpen, setOnUseActionsOpen] = useState(false);
    const [onUseActionsOpenDelaied, setOnUseActionsOpenDelaied] = useState(false);
    const delayRef = useRef();
    const toggleUseActionsOpenState = () => {
        const newState = !onUseActionsOpen;
        setOnUseActionsOpen(newState);
        if (delayRef.current) clearTimeout(delayRef.current);
        if (!newState) {
            setTimeout(() => {
                setOnUseActionsOpenDelaied(false);
            }, 150);
        } else {
            setOnUseActionsOpenDelaied(newState);
        }
    };
    const onChangeLabel = (event: ChangeEvent<HTMLInputElement>) => {
        setFormItem({
            ...formItem,
            label: event.target.value,
        });
    };
    const onChangeCount = (event: ChangeEvent<HTMLInputElement>) => {
        const value = Number(event.target.value);
        setFormItem({
            ...formItem,
            count:
                event.target.value === "" || value < 1 ? 1 : value > 1000 ? 1000 : value,
        });
    };
    const onChangeType = (type: string) => {
        if (onUseActionsOpen) {
            toggleUseActionsOpenState();
        }
        setFormItem({
            ...formItem,
            type: type as TOperationNecessaryItemType,
            related_entity_uuid: null,
            related_entity_row_id: null,
            is_scannable: true,
        });
    };
    const onChangeNecessaryType = (type: string) => {
        setFormItem({
            ...formItem,
            necessary_type: type as TOperationNecessaryItemNecessaryType,
        });
    };
    const onChangeEntity = (entityUuid: string | null) => {
        if (!entityUuid && onUseActionsOpen) {
            toggleUseActionsOpenState();
        }

        setFormItem({
            ...formItem,
            on_use_actions: [],
            related_entity_uuid: entityUuid,
            related_entity_row_id: null,
            is_scannable: true,
        });
    };
    const onChangeEntityRowId = (entityRowId: number | null) => {
        setFormItem({
            ...formItem,
            related_entity_row_id: entityRowId,
            is_scannable: false,
        });
    };
    const onChangeUseActions = (
        actions: (TONItemUseAction | TONItemUseActionCreating)[]
    ) => {
        setFormItem({
            ...formItem,
            on_use_actions: actions,
        });
    };

    const onToggleScannable = () => {
        setFormItem({
            ...formItem,
            is_scannable: !formItem.is_scannable,
        });
    };
    const onClickSubmit = () => {
        onSubmit(formItem);
    };
    return {
        formItem,
        onUseActionsOpen,
        onUseActionsOpenDelaied,
        toggleUseActionsOpenState,
        onChangeLabel,
        onChangeCount,
        onChangeType,
        onChangeNecessaryType,
        onChangeEntity,
        onChangeEntityRowId,
        onChangeUseActions,
        onClickSubmit,
        onToggleScannable,
    };
};
