import { getUnixTimestamp } from "@shared/lib/date";

export const getNewNavigationRoute = (): TNavigationRoute => {
    return {
        name: "Элемент",
        key: "key_" + getUnixTimestamp(),
        parent_key: null,
        type: "route",
        order: 1,
        route: "",
        open_type: "currentTab",
    };
};
