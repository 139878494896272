import { useSelector } from "react-redux";
import { selectGetLoading, selectViewPatterns } from "./ViewPatternSelectors";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loadViewPatternsThunk } from "./ViewPatternThunk";

export const useViewPatternsLoad = (entityId: number) => {
    const loading = useSelector(selectGetLoading);
    const viewPatterns = useSelector(selectViewPatterns);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(
            loadViewPatternsThunk({
                params: {
                    filter: {
                        value: entityId.toString(),
                        key: "entity_id",
                        condition: "=",
                    },
                },
            })
        );
    }, [entityId, dispatch]);

    return {
        viewPatterns,
        loading,
    };
};
