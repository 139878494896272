import {
    navigationRouteApi,
    navigationRouteModel,
} from "@entities/Portal/NavigationRoute";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNewNavigationRoute } from "../lib";
import { checkIsValidPortalUrl, checkIsValidExternalUrl } from "@shared/lib/isValidUrl";

export const useNavigationRouteEditUpdate = (
    navigationRoute?: TNavigationRoute | null,
    onSave?: () => void
) => {
    const routes = useSelector(navigationRouteModel.selectNavigationRoutes);
    const [editingNavigationRoute, setEditingNavigationRoute] =
        useState<TNavigationRoute>(
            navigationRoute ?? {
                ...getNewNavigationRoute(),
                order: routes.length,
            }
        );
    const firstRender = useRef<boolean>(true);
    const isValidUrl = firstRender.current
        ? true
        : editingNavigationRoute.type === "link"
          ? checkIsValidExternalUrl(editingNavigationRoute.route)
          : checkIsValidPortalUrl(editingNavigationRoute.route);

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        if (name === "route") firstRender.current = false;
        setEditingNavigationRoute({
            ...editingNavigationRoute,
            [name]: value,
        });
    };

    const onChangeType = (type: string) => {
        setEditingNavigationRoute({
            ...editingNavigationRoute,
            type: type as TNavigationRouteType,
        });
    };
    const onChangeBlank = (type: TNavigationOpenType) => {
        setEditingNavigationRoute({
            ...editingNavigationRoute,
            open_type: type,
        });
    };
    const onSubmit = () => {
        if (!isValidUrl) {
            notificationEmit({
                title: "Не удалось сохранить изменения",
                type: "error",
            });
            return;
        }
        setLoading(true);
        if (editingNavigationRoute.id) {
            navigationRouteApi
                .updateNavigationRoute(editingNavigationRoute)
                .then((response) => {
                    dispatch(navigationRouteModel.updateRoute(response.data.data));
                    onSave && onSave();
                })
                .catch((error) => {
                    notificationEmit({
                        error: error,
                        title: "Не удалось сохранить изменения",
                        type: "error",
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            navigationRouteApi
                .createNavigationRoute(editingNavigationRoute)
                .then((response) => {
                    dispatch(navigationRouteModel.createRoute(response.data.data));
                    onSave && onSave();
                })
                .catch((error) => {
                    notificationEmit({
                        error: error,
                        title: "Не удалось создать элемент",
                        type: "error",
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };
    return {
        isValidUrl,
        editingNavigationRoute,
        loading,
        onChangeInput,
        onChangeType,
        onSubmit,
        onChangeBlank,
    };
};
