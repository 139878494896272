import { openDrawer } from "@shared/ui/DrawerManager";
import { getFullValue, PaginatedSelectReadValue } from "@shared/ui/PaginatedSelect";
import { CSSProperties, useMemo } from "react";
import { EntityUsersCard } from "../EntityCard";
import { EntityDefaultCardList } from "../EntityCard/EntityDefaultCard";
import { entityRowConstants } from "@entities/Portal/EntityRow";
import { notificationEmit } from "@shared/ui/NotificationAndMessage/model/emits";

interface IEntitySelectCustomReadValue {
    value: any;
    valueKey?: string;
    readValueTextStyle?: CSSProperties;
    nameField?: TEntityField;
    descriptionFields?: TEntityField[];
    options: TPaginatedSelectOption[];
    renderEntity: string;
    isCard?: boolean;
}

const EntitySelectCustomReadValue = ({
    value,
    nameField,
    // valueKey,
    descriptionFields,
    isCard,
    options,
    renderEntity,
    readValueTextStyle,
}: IEntitySelectCustomReadValue) => {
    const fullValue = useMemo(() => {
        return getFullValue(value, options);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);
    const onClick = (value: number | string) => {
        if (!value) return;
        try {
            const id = Number(value);
            // valueKey && valueKey !== "id"
            //     ? fullValue?.find((item: any) => item[valueKey] === value)?.id
            //     : Number(value);
            if (id)
                openDrawer({
                    id: id,
                    name: renderEntity,
                    pattern: entityRowConstants.ERP_LOCATION_PATTERN,
                });
        } catch (e) {
            notificationEmit({
                type: "error",
                title: "Не удалось найти информацию",
            });
        }
    };
    const getCardRenderElement = (fullValue: any) => {
        switch (renderEntity) {
            case "users":
                return <EntityUsersCard users={fullValue} onClick={onClick} />;
            default:
                if (fullValue && (nameField || descriptionFields)) {
                    return (
                        <EntityDefaultCardList
                            nameField={nameField}
                            descriptionFields={descriptionFields}
                            entityRowsOrIds={fullValue}
                            onClick={onClick}
                        />
                    );
                }
                return null;
        }
    };

    if (isCard && fullValue) {
        const renderElement = getCardRenderElement(fullValue);
        if (renderElement) {
            return <div style={{ marginTop: 8 }}>{renderElement}</div>;
        }
    }

    return (
        <PaginatedSelectReadValue
            onClick={onClick}
            readValueTextStyle={readValueTextStyle}
            value={value}
            options={options}
        />
    );
};

export default EntitySelectCustomReadValue;
