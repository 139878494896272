import { store } from "@app/store/store";
import { userModel } from "@entities/Portal/User";
import { $api } from "@shared/api/api";

$api.interceptors.response.use(
    (config) => {
        if (config.data.code < 200 || config.data.code > 299) throw Error("Error");
        return config;
    },
    (error) => {
        if (error?.response?.status === 401) {
            store.dispatch(userModel.signOut());
        }

        throw error;
    }
);
