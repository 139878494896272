import { techMapModel } from "@entities/Production/TechMap";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";

interface ITMTableReject {
    techMap: TTechMapStarted;
    levelKey?: string | number;
}

const TMTableReject = ({ techMap, levelKey }: ITMTableReject) => {
    const approveLoading = useSelector((state: RootState) =>
        techMapModel.selectIsApproveLoading(state, techMap.id)
    );
    const rejectLoading = useSelector((state: RootState) =>
        techMapModel.selectIsRejectLoading(state, techMap.id)
    );
    const dispatch = useDispatch<AppDispatch>();

    const onReject = () => {
        dispatch(
            techMapModel.approveTmThunk({
                tmtId: techMap.id,
                decision: "reject",
                level: levelKey,
            })
        );
    };

    return (
        <Button
            style={{ margin: 0, padding: 0, minHeight: 10 }}
            type="link"
            danger
            size="small"
            loading={rejectLoading}
            disabled={approveLoading}
            onClick={onReject}
        >
            Отклонить
        </Button>
    );
};

export default TMTableReject;
