import { compareObjects } from "@shared/lib/object-helper";
import { capabilitiesTypes } from "./constants";

export const getBackgroundColorOfCell = (
    value: string | string[] | Record<string, any> | number | null,
    type: TCapType,
    oldValue: string | string[] | Record<string, any> | number | null
) => {
    if (
        type === "constraints" &&
        compareObjects(JSON.parse(value as string), JSON.parse(oldValue as string))
    )
        return undefined;
    if (value === oldValue) return undefined;
    else {
        const foundType = capabilitiesTypes.find((typeItem) => typeItem.key === type);

        return foundType ? foundType.color : "rgb(249, 240, 255)";
    }
};

export const getTooltipTitle = (
    value: string,
    additionalOptions: { label: string; value: string }[] | undefined
) => {
    const arrayValue = value ? value.split(",") : [];
    const visibleArrayValue =
        arrayValue.length > 2 ? [...arrayValue.slice(0, 2), "..."] : arrayValue;
    const showMore = arrayValue.length > 2;
    const tooltipTitle = arrayValue
        .map((valueItem) => {
            const foundItem = additionalOptions?.find(
                (option) => option.value === valueItem
            );
            return foundItem?.label ?? valueItem;
        })
        .filter((item): item is string => !!item)
        .join(",");

    return { showMore, tooltipTitle, visibleArrayValue };
};

export const findOptionsByInputValue = (input: any, option: any) => {
    return option?.label?.toLowerCase()?.includes(input.toLowerCase()) === true;
};

export const getValidValue = (value: string, emptyValue: string) => {
    return value && value !== "null"
        ? value.split(",").filter((item) => item !== emptyValue || item.startsWith("{"))
        : [emptyValue];
};

export const prepareValueInput = (value: string | string[], emptyValue: string) => {
    return value === ""
        ? [value]
        : value.includes("")
          ? ""
          : (value as string[]).filter(
                (item) => item !== emptyValue && !item.startsWith("{")
            );
};
