import { techMapConstant } from "@entities/Production/TechMap";
import TMTableStatus from "../TableItems/TMTableStatus";

export const getGroupedTableColumns = (groupLevel: string) => {
    const title =
        techMapConstant.groupOptions.find((option) => option.value === groupLevel)
            ?.label ?? "Наименование";
    const columns = [
        {
            title: title,
            dataIndex: "name",
            key: "name",
            render: (value: string) => {
                if (groupLevel.includes("status"))
                    return <TMTableStatus status={value as TTechMapStatus} />;
                return value;
            },
        },
        {
            title: "Количество",
            dataIndex: "count",
            key: "count",
            render: (value: number) => {
                return value;
            },
        },
    ];

    return columns;
};
